import React, { useEffect, useState } from 'react';
import { ShapeTypes } from '@bynder-studio/render-core';
import { Form, Input } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { IconBorderRadius } from '@bynder/icons';
import { useInputBlur } from 'packages/hooks/useInputBlur';
import editorAutocorrect from 'packages/pages/editor/EditorAutocorrectRules';

type BorderRadiusProps = {
    disabled: boolean;
    selectedElement: any;
    creativeModel: any;
};

const BorderRadius = ({ selectedElement, creativeModel }: BorderRadiusProps) => {
    const { translate } = useTranslate();
    const { onFocus, unsubscribeListener } = useInputBlur();
    const { id, locked, shapeType, borderRadius } = selectedElement;
    const [selectedValue, setSelectedBorderRadius] = useState(borderRadius);

    const applyChanges = (val: number) => {
        const correctRadius = editorAutocorrect('element_border_radius', val);
        setSelectedBorderRadius(correctRadius);

        creativeModel.updateElement(id, { borderRadius: correctRadius });
    };

    const handleOnBlur = () => {
        unsubscribeListener();
        applyChanges(Number(selectedValue));
    };

    const onKeyDown = (event) => {
        switch (event.key) {
            case 'Enter':
                applyChanges(Number(selectedValue));
                break;
            case 'ArrowUp':
            case 'ArrowDown':
                event.preventDefault();
                const numberToAdd = event.key === 'ArrowDown' ? -1 : 1;
                const factor = event.shiftKey ? 10 : 1;
                applyChanges(Number(selectedValue) + numberToAdd * factor);
                break;
        }
    };

    useEffect(() => {
        setSelectedBorderRadius(selectedElement.borderRadius);
    }, [selectedElement.borderRadius, id]);

    return shapeType === ShapeTypes.RECTANGLE ? (
        <Form.Row>
            <Form.Group>
                <Form.Label>{translate('editor.sidebar.shots.shape.border.corner_radius.label')}</Form.Label>
                <Input
                    name={translate('editor.sidebar.shots.shape.border.corner_radius.label')}
                    aria-label={translate('editor.sidebar.shots.shape.border.corner_radius.label')}
                    type="number"
                    value={selectedValue.toString()}
                    onFocus={onFocus}
                    onBlur={handleOnBlur}
                    onChange={setSelectedBorderRadius}
                    onKeyDown={onKeyDown}
                    isDisabled={locked}
                    icon={<IconBorderRadius />}
                    suffix="px"
                    data-testid="shots_shape_border__radius"
                />
            </Form.Group>
            <Form.Group>
                <></>
            </Form.Group>
        </Form.Row>
    ) : null;
};

export default BorderRadius;
