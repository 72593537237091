import { FontFamily, getStructuredText } from '@bynder-studio/structured-text';
import { SpecificationParser } from './SpecificationParser';
import { Dimension } from '../Models/Shared/Dimension';
import { ElementTypes } from '../Enums/ElementTypes';
import { TextElement, type TextElementParams } from '../Models/Elements/TextElement';
import { VideoElement, VideoElementParams } from '../Models/Elements/VideoElement';
import { ImageElement, ImageElementParams } from '../Models/Elements/ImageElement';
import { ShapeElement } from '../Models/Elements/ShapeElement';
import { GroupElement } from '../Models/Elements/GroupElement';
import { BackgroundColor } from '../Models/Properties/BackgroundColor';
import type { Template, TemplatePage, TemplateElement } from '../Renderers/BaseRenderer/IBaseRenderer';
import type { PosterFrameParams } from '../Models/Properties/PosterFrame';
import type { AudioElementParams } from '../Models/Elements/AudioElement';
import type { BaseVisualElementParams } from '../Models/Elements/BaseVisualElement';

export class WebSpecificationParser extends SpecificationParser {
    getBaseElementParams(rawEl: TemplateElement): BaseVisualElementParams {
        return {
            ...super.getBaseElementParams(rawEl),
            scale: rawEl.properties.scale || 1,
            allowToggleVisibility: rawEl.properties.allowToggleVisibility || false,
            lockUniScaling: false,
        };
    }

    getTextElementParams(rawEl: TemplateElement): TextElementParams {
        return {
            ...super.getTextElementParams(rawEl),
            limitTextToBounds: rawEl.properties.limitTextToBounds || false,
        };
    }

    getImageElementParams(rawEl: TemplateElement): ImageElementParams {
        return {
            ...super.getImageElementParams(rawEl),
            src: rawEl.properties.value?.thumbnail,
            srcId: rawEl.properties.value?.value,
            srcType: rawEl.properties.value?.type,
            fileName: rawEl.properties.fileName || '',
            naturalDimension: {
                width: rawEl.properties.naturalWidth,
                height: rawEl.properties.naturalHeight,
            },
            virtualData: {
                bynderCollectionId: rawEl.properties.value?.bynderCollectionId || null,
                allowPersonalUpload: rawEl.properties.allowPersonalUpload ?? true,
            },
        };
    }

    getVideoElementParams(rawEl: TemplateElement): VideoElementParams {
        return {
            ...super.getVideoElementParams(rawEl),
            src: rawEl.properties.value?.videoPreviewUrl,
            srcId: rawEl.properties.value?.value,
            srcType: rawEl.properties.value?.type,
            fileName: rawEl.properties.fileName || '',
            naturalDimension: {
                width: rawEl.properties.naturalWidth,
                height: rawEl.properties.naturalHeight,
            },
            virtualData: {
                bynderCollectionId: rawEl.properties.value?.bynderCollectionId || null,
                allowPersonalUpload: rawEl.properties.allowPersonalUpload ?? true,
            },
        };
    }

    getGlobalAudioParams(rawEl: TemplateElement): AudioElementParams {
        if (!rawEl || !rawEl.properties) {
            return {
                id: Date.now() - 999 ** 2,
                name: ElementTypes.GLOBAL_AUDIO,
                locked: false,
                startFrame: 0,
                duration: null,
                gain: 0,
                fadeIn: 0,
                fadeOut: 0,
            };
        }

        return {
            id: rawEl.id,
            name: rawEl.name,
            locked: rawEl.properties.locked,
            startFrame: rawEl.properties.startFrame || 0,
            duration: rawEl.properties.duration || null,
            src: rawEl.properties.value?.url || '',
            srcId: rawEl.properties.value?.value,
            srcType: rawEl.properties.value?.type,
            offsetTime: rawEl.properties.value?.offsetTime || 0,
            fileName: rawEl.properties.fileName || '',
            gain: rawEl.properties.gain || 0,
            fadeIn: rawEl.properties.fadeIn || 0,
            fadeOut: rawEl.properties.fadeOut || 0,
        };
    }

    getPosterFrameParams = (rawEl: TemplateElement): PosterFrameParams => {
        if (!rawEl || !rawEl.properties) {
            return {
                id: Date.now() - 888 ** 2,
                name: 'DEFAULT_POSTER_FRAME_NAME',
                locked: false,
                frame: 0,
            };
        }

        return {
            id: rawEl.id,
            name: rawEl.name,
            locked: rawEl.properties.locked,
            frame: rawEl.properties.value,
        };
    };

    parsePages = ({ pages }: Template) => {
        const getDisplayOrder = (page: TemplatePage) => page.displayOrder;

        return pages.sort((pageA, pageB) => getDisplayOrder(pageA) - getDisplayOrder(pageB));
    };

    parseDimensions({ pages }: Template, pageIndex: number): Dimension {
        if (!pages || !pages.length) {
            throw new Error('SIZES_MISSING');
        }

        const page = pages[pageIndex];

        if (!page) {
            throw new Error('SIZE_MISSING');
        }

        const { width, height } = page;

        if (!width || !height) {
            throw new Error('DIMENSIONS_MISSING');
        }

        return new Dimension(width, height);
    }

    parseBackgroundColor({ elements }: TemplatePage): BackgroundColor {
        const backgroundColorSpec: any = Object.values(elements).find(
            (el: any) => el.type === ElementTypes.BACKGROUND_COLOR,
        );

        return new BackgroundColor(this.getBackgroundColorParams(backgroundColorSpec));
    }

    parseElements({ elements: rawElements }: Pick<TemplatePage, 'elements'>, fontFamilies: FontFamily[]): any[] {
        if (rawElements) {
            const elements = [];
            const rawElementsList = Object.values(rawElements).filter((rawEl) =>
                this.ELEMENTS_TYPES_TO_PARSE.includes(rawEl.type),
            );

            rawElementsList
                .sort((a, b) => (a.properties.renderOrder > b.properties.renderOrder ? 1 : -1))
                .forEach((rawEl) => {
                    switch (rawEl.type) {
                        case ElementTypes.VIDEO: {
                            elements.push(new VideoElement(this.getVideoElementParams(rawEl)));
                            break;
                        }

                        case ElementTypes.IMAGE: {
                            elements.push(new ImageElement(this.getImageElementParams(rawEl)));
                            break;
                        }

                        case ElementTypes.SHAPE: {
                            elements.push(new ShapeElement(this.getShapeElementParams(rawEl)));
                            break;
                        }

                        case ElementTypes.TEXT: {
                            const textElParams: any = this.getTextElementParams(rawEl);
                            const defaultProps = SpecificationParser.getTextDefaultProps(rawEl);
                            textElParams.formattedText = getStructuredText(textElParams.text, defaultProps);
                            elements.push(new TextElement(textElParams));
                            break;
                        }

                        case ElementTypes.GROUP: {
                            const groupElementParams = {
                                ...this.getBaseElementParams(rawEl),
                                children: this.parseElements({ elements: rawEl.children }, fontFamilies),
                            };
                            const groupElement = new GroupElement(groupElementParams);
                            elements.push(groupElement);
                            break;
                        }
                    }
                });

            return elements;
        }
    }
}
