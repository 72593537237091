import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Buffer } from '@bynder/design-system';
import { type IMultiPageVideoModel } from '@bynder-studio/render-core';
import { PagePreviewProvider } from '~/contexts/PagePreviewContext';
import ShotVideoDesign from 'packages/pages/design/sidebar/shots/ShotVideoDesign';
import ShotImageDesign from 'packages/pages/design/sidebar/shots/ShotImageDesign';
import PagePreview from 'packages/pages/design/components/PagePreview/index';
import useDesign from 'packages/pages/design/hooks/useDesign';
import useVariations from 'packages/pages/design/hooks/useVariations';
import usePageHotkeys from 'packages/pages/usePageHotKeys';
import useForceUpdate from '~/hooks/useForceUpdate';
import { useCanvasBorder } from 'packages/hooks/useCanvasBorder';
import { pendingDesignSavingRequest } from '~/store/creatives/creatives.actions';
import CreativeTypes from '~/helpers/CreativeTypes';
import { DesignTab } from 'packages/pages/design/sidebar/types';
import PreviewHeader from '../previewHeader/PreviewHeader';
import Sidebar from '../sidebar/Sidebar';
import ConfiguratorTimeline from '../timeline';
import { getVisibleElements } from './utils';
import {
    DesignBuffer,
    DesignCanvas,
    DesignCanvasBorder,
    DesignCanvasPaddingWrapper,
    DesignCanvasWrapper,
    DesignContainer,
    DesignMain,
    DesignSidebar,
    DesignWrapper,
} from './design.styled';

export const Design = () => {
    const dispatch = useDispatch();
    const forceUpdate = useForceUpdate();
    const {
        isUpdatedByVariation,
        updatedPlaybackDuration,
        creativeModel,
        initCanvasRenderer,
        template,
        canvasRenderer,
        creativeType,
        setPausePlayback,
        setPlayPlayback,
        isPlaying,
        creativeModelFactory,
        setCurrentPageIndex,
    } = useDesign();
    const { variations = [], currentVariation, saveVariation, variationSaveInProgressMap } = useVariations();
    const currentPageIndex = useMemo(() => creativeModel.getCurrentPageIndex(), [creativeModel?.index]);
    const canvasWrapperRef = useRef(null);
    const borderElementRef = useRef<HTMLDivElement | null>(null);
    useCanvasBorder(canvasRenderer, borderElementRef);
    const [selectedShotIndex, setSelectedShotIndex] = useState(0);
    const [isToolbarDisabled, setIsToolbarDisabled] = useState(false);
    // todo: Shots should be stateless hook and share between all editors
    const [shots, setShots] = useState(template?.pages[currentPageIndex].shots || []);
    const [selectedShot, setSelectedShot] = useState(shots[0]);
    const [isLoading, setIsLoading] = useState(!isUpdatedByVariation);
    const [designTab, setDesignTab] = useState(DesignTab.SCENE);

    useEffect(() => {
        setIsLoading(!isUpdatedByVariation || variations.length === 0);
    }, [isUpdatedByVariation, variations]);

    const getVideoData = useCallback((): { duration: number; frameRate: number } => {
        return {
            duration:
                (creativeModel as IMultiPageVideoModel)?.getPlaybackDuration?.()?.getDuration?.() ||
                template.pages[currentPageIndex].duration,
            frameRate: template.pages[currentPageIndex].frameRate,
        };
    }, [creativeModel, currentPageIndex, template]);

    const visibleElements = useMemo(
        () => getVisibleElements(template.pages, currentPageIndex),
        [template, creativeModel, currentPageIndex],
    );

    const showPagePreviews = useMemo(() => template.pages.length > 1, [template]);

    const handleShotsReplaced = useCallback(() => {
        if (creativeType === 'IMAGE') {
            return;
        }

        const updatedShots = creativeModel?.getShots();

        setShots(updatedShots);
        setSelectedShot(updatedShots[selectedShotIndex]);
    }, [creativeModel, selectedShotIndex, creativeType]);

    const selectShot = useCallback(
        (index: number) => {
            if (index === shots.length || !shots[index]) {
                return;
            }

            canvasRenderer.setCurrentFrame(shots[index].startFrame);

            setSelectedShotIndex(index);
            setSelectedShot(shots[index]);
        },
        [shots, canvasRenderer],
    );

    const toggleToolbarAccess = useCallback((disable) => {
        setIsToolbarDisabled(disable);
    }, []);

    const toNextShot = useCallback(() => {
        if (selectedShotIndex >= shots.length - 1) {
            return;
        }

        if (canvasRenderer.getIsPlaying()) {
            setPausePlayback();
            selectShot(selectedShotIndex + 1);
            setPlayPlayback();
        } else {
            selectShot(selectedShotIndex + 1);
        }
    }, [canvasRenderer, setPausePlayback, setPlayPlayback, selectedShotIndex, selectShot, shots.length]);

    const toPrevShot = useCallback(() => {
        if (canvasRenderer.getIsPlaying()) {
            setPausePlayback();
            selectShot(Math.max(selectedShotIndex - 1, 0));
            setPlayPlayback();
        } else {
            selectShot(Math.max(selectedShotIndex - 1, 0));
        }
    }, [canvasRenderer, setPlayPlayback, selectedShotIndex, selectShot, setPausePlayback]);

    const saveOnLeave = useCallback(() => {
        const currentVariationOBJ = variations?.find((v) => v.id === currentVariation);

        if (!currentVariationOBJ) {
            return;
        }

        const { id, hasUnsavedChanges } = currentVariationOBJ;
        const inProgress = id in variationSaveInProgressMap.current;

        if (inProgress) {
            return;
        }

        if (hasUnsavedChanges) {
            dispatch(pendingDesignSavingRequest(true));
            saveVariation(id);
        } else {
            dispatch(pendingDesignSavingRequest(false));
        }
    }, [variations, currentVariation, saveVariation]);

    useEffect(() => {
        setShots(template?.pages[currentPageIndex].shots || []);
    }, [template, creativeModel]);

    useEffect(() => {
        if (!creativeModel) {
            return;
        }

        creativeModel.on('shotsReplaced', handleShotsReplaced);
        creativeModel.on('currentPageChange', handleShotsReplaced);

        return () => {
            creativeModel.off('shotsReplaced', handleShotsReplaced);
            creativeModel.off('currentPageChange', handleShotsReplaced);
        };
    }, [creativeModel, selectedShotIndex, updatedPlaybackDuration]);

    useEffect(() => {
        if (canvasWrapperRef.current) {
            initCanvasRenderer(canvasWrapperRef.current);
        }
    }, [initCanvasRenderer]);

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
                if (isPlaying) {
                    setPausePlayback();
                }

                saveOnLeave();
            }
        };

        window.addEventListener('beforeunload', saveOnLeave);
        document.addEventListener('visibilitychange', handleVisibilityChange); // in case of refresh or close tab

        return () => {
            // dispatch(pendingDesignSavingRequest(true)); remove after QA
            saveOnLeave(); // in case of navigation to another page (our back button)
            window.removeEventListener('beforeunload', saveOnLeave);
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [variations, saveOnLeave]);

    const handleDimensionResize = useCallback(() => {
        if (document.fullscreenElement) {
            return;
        }

        if (canvasRenderer) {
            canvasRenderer.calculateScaleRatio();
            canvasRenderer.redraw();
        }
    }, [canvasRenderer]);

    const handlePageChangePrevious = useCallback(() => {
        const prevPageIndex = currentPageIndex - 1 >= 0 ? currentPageIndex - 1 : currentPageIndex;
        setCurrentPageIndex(prevPageIndex);
        forceUpdate();
    }, [currentPageIndex]);

    const handlePageChangeNext = useCallback(() => {
        const totalPages = creativeModel?.getModels()?.length;

        if (!totalPages) {
            return;
        }

        const nextPage = currentPageIndex + 1 < totalPages ? currentPageIndex + 1 : currentPageIndex;
        setCurrentPageIndex(nextPage);
        forceUpdate();
    }, [creativeModel, currentPageIndex]);

    usePageHotkeys({
        handlePageChangePrevious,
        handlePageChangeNext,
    });

    useEffect(() => {
        window.addEventListener('resize', handleDimensionResize);

        return () => {
            window.removeEventListener('resize', handleDimensionResize);
        };
    }, [canvasRenderer, handleDimensionResize]);

    return (
        <DesignWrapper>
            <DesignMain direction="column" justifyContent="center">
                <PreviewHeader />
                <DesignContainer className="instant--preview-container" justifyContent="space-between" wrap="wrap">
                    <DesignCanvasWrapper creativetype={creativeType}>
                        {showPagePreviews && (
                            <PagePreviewProvider>
                                <PagePreview />
                            </PagePreviewProvider>
                        )}
                        <DesignCanvasPaddingWrapper showpagepreviews={showPagePreviews.toString()}>
                            <DesignCanvas ref={canvasWrapperRef} className="instant--preview-canvas-wrapper">
                                <DesignCanvasBorder isloading={isLoading.toString()} ref={borderElementRef}>
                                    {!isUpdatedByVariation && (
                                        <DesignBuffer>
                                            <Buffer />
                                        </DesignBuffer>
                                    )}
                                </DesignCanvasBorder>
                            </DesignCanvas>
                        </DesignCanvasPaddingWrapper>
                    </DesignCanvasWrapper>
                    {creativeType === CreativeTypes.Video && (
                        <ConfiguratorTimeline
                            designType="CREATIVE"
                            shots={shots}
                            selectedShotIndex={selectedShotIndex}
                            selectShot={selectShot}
                            toNextShot={toNextShot}
                            toPrevShot={toPrevShot}
                            toggleToolbarAccess={toggleToolbarAccess}
                            canvasRenderer={canvasRenderer}
                            setPausePlayback={setPausePlayback}
                            setPlayPlayback={setPlayPlayback}
                            creativeModelFactory={creativeModelFactory}
                            creativeModel={creativeModel}
                            setDesignTab={setDesignTab}
                            {...getVideoData()}
                        />
                    )}
                </DesignContainer>
            </DesignMain>
            <DesignSidebar>
                <Sidebar designTab={designTab} setDesignTab={setDesignTab}>
                    {creativeType === 'VIDEO' ? (
                        <ShotVideoDesign
                            template={template}
                            currentPageIndex={currentPageIndex}
                            elements={visibleElements}
                            shots={shots}
                            selectedShot={selectedShot}
                            selectedShotIndex={selectedShotIndex}
                            isToolbarDisabled={isToolbarDisabled}
                            isLoading={isLoading}
                        />
                    ) : (
                        <ShotImageDesign
                            elements={visibleElements}
                            isToolbarDisabled={isToolbarDisabled}
                            isLoading={isLoading}
                        />
                    )}
                </Sidebar>
            </DesignSidebar>
        </DesignWrapper>
    );
};
