import React, { Suspense, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { lazyPrefetching } from 'packages/helpers/helpers';
import { useLocation } from 'react-router-dom';
import usePrevious from 'packages/hooks/usePrevious';
import DesignsContainer from 'packages/pages/designs';
import SharedWithMeContainer from 'packages/pages/sharedWithMe';
import permissions from '../../configs/permissions';
import AuthorizationHelper from '../../helpers/AuthorizationHelper';
import features from '../../configs/features';
import { SimplePlayer } from '~/common/editor/contentSelection/PortionSelector';
import { VideoPlayer, AudioPlayer } from '~/common/player/Player';
import CreativeEditorContentLoader from '~/pages/app/creative/editor/CreativeEditorContentLoader';
import GenerateContentLoader from './creative/editor/GenerateContentLoader';
import DesignEditor from './creative/editor/DesignEditor';
import Generate from './creative/editor/Generate';
import TemplateEditor from './creative/editor/TemplateEditor';

const ProjectsContainer = lazyPrefetching(() =>
    import(
        /* webpackChunkName: "projects" */
        /* webpackPrefetch: true */
        'packages/pages/projects'
    ),
);

const ProjectDetailContainer = lazyPrefetching(() =>
    import(
        /* webpackChunkName: "project-detail" */
        /* webpackPrefetch: true */
        'packages/pages/projectDetail'
    ),
);

const BrandColorsContainer = lazyPrefetching(() =>
    import(
        /* webpackChunkName: "brand-colors" */
        /* webpackPrefetch: true */
        'packages/pages/brandColors'
    ),
);

const BrandPalettesContainer = lazyPrefetching(() =>
    import(
        /* webpackChunkName: "brand-palette" */
        /* webpackPrefetch: true */
        'packages/pages/brandPalettes'
    ),
);

const CategoriesContainer = lazyPrefetching(() =>
    import(
        /* webpackChunkName: "categories" */
        /* webpackPrefetch: true */
        'packages/pages/categories'
    ),
);

const CategoryDetailsContainer = lazyPrefetching(() =>
    import(
        /* webpackChunkName: "category-details" */
        /* webpackPrefetch: true */
        'packages/pages/categoryDetails'
    ),
);

const UploadsContainer = lazyPrefetching(() =>
    import(
        /* webpackChunkName: "uploads" */
        /* webpackPrefetch: true */
        'packages/pages/uploads'
    ),
);

const UploadCollectionsContainer = lazyPrefetching(() =>
    import(
        /* webpackChunkName: "uploadCollections" */
        /* webpackPrefetch: true */
        'packages/pages/uploadCollections'
    ),
);

const CollectionUploadsContainer = lazyPrefetching(() =>
    import(
        /* webpackChunkName: "uploadCollectionDetails" */
        /* webpackPrefetch: true */
        'packages/pages/uploadCollectionDetails'
    ),
);

const NotFound = lazyPrefetching(() =>
    import(
        /* webpackChunkName: "notFoundPage" */
        /* webpackPrefetch: true */
        'packages/pages/errors/not-found'
    ),
);

const NoAccess = lazyPrefetching(() =>
    import(
        /* webpackChunkName: "noAccessPage" */
        /* webpackPrefetch: true */
        'packages/pages/errors/no-access'
    ),
);

const Body = (props) => {
    const [animate, setAnimate] = React.useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const prevLocation = usePrevious(location);

    const doAnimate = () => {
        setAnimate(false);

        setTimeout(() => {
            setAnimate(true);
        }, 100);
    };

    useEffect(() => {
        doAnimate();
        ProjectsContainer.prefetch();
        ProjectDetailContainer.prefetch();
        CategoriesContainer.prefetch();
        CategoryDetailsContainer.prefetch();
        BrandColorsContainer.prefetch();
        BrandPalettesContainer.prefetch();
        UploadsContainer.prefetch();
        UploadCollectionsContainer.prefetch();
        CollectionUploadsContainer.prefetch();
        SimplePlayer.prefetch();
        VideoPlayer.prefetch();
        AudioPlayer.prefetch();
    }, []);

    useEffect(() => {
        if (prevLocation && prevLocation.pathname !== location.pathname) {
            doAnimate();
        }
        if (location.pathname === '/') {
            navigate('/designs');
        }
    }, [location]);

    return (
        <div
            className={`h-100 ${animate ? 'transition-opacity' : ''}`}
            style={{
                opacity: animate ? 1 : 0,
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Suspense fallback={null}>
                <Routes>
                    <Route exact path="/designs" element={<DesignsContainer />} />
                    {AuthorizationHelper.hasPermission(permissions.CREATIVES_SPECIFICATIONS.READ) && (
                        <Route>
                            <Route path="/designs/new/edit" element={<CreativeEditorContentLoader />} />
                            <Route path="/designs/new" element={<GenerateContentLoader />} />
                            <Route path="/designs/:creativeId/edit" element={<DesignEditor />} />
                            <Route path="/designs/:creativeId" element={<Generate />} />
                        </Route>
                    )}
                    <Route path="/shared" element={<SharedWithMeContainer />} />
                    <Route path="/projects" exact element={<ProjectsContainer />} />
                    <Route path="/projects/:projectId" element={<ProjectDetailContainer />} />
                    {AuthorizationHelper.hasPermission(permissions.ASSETS.READ) &&
                        AuthorizationHelper.isFeatureAvailable(features.INTERNAL_ASSET) && (
                            <Route>
                                <Route path="/uploads" element={<UploadsContainer />} />
                                <Route path="/collections" exact element={<UploadCollectionsContainer />} />
                                <Route path="/collections/:collectionId" element={<CollectionUploadsContainer />} />
                            </Route>
                        )}
                    <Route path="/brand-colors" exact element={<BrandPalettesContainer />} />
                    <Route path="/brand-colors/:paletteId" element={<BrandColorsContainer />} />
                    <Route path="/categories" exact element={<CategoriesContainer />} />
                    <Route path="/categories/:categoryId" element={<CategoryDetailsContainer />} />
                    {AuthorizationHelper.hasPermission(permissions.TEMPLATES.WRITE) && (
                        <Route path="/templates/:creativeId/edit" element={<TemplateEditor />} />
                    )}
                    <Route path="/not-found" element={<NotFound />} />
                    <Route path="/no-access" element={<NoAccess />} />
                    {/* <Redirect path="/templates" to="/categories" />
                    <Redirect path="/templates/:creativeId/" to="/categories" />
                    <Redirect path="/" to={AuthorizationHelper.getFallbackUrl()} /> */}
                    {/* <Redirect to="/not-found" /> uncomment if you want more strict url routing */}
                </Routes>
            </Suspense>
        </div>
    );
};

function mapStateToProps(state, ownProps) {
    const { loggingIn, companies, currentCompany } = state.user;

    return {
        loggingIn,
        companies,
        currentCompany,
        ...ownProps,
    };
}

export default connect(mapStateToProps)(Body);
