import { useCallback, useRef, useSyncExternalStore } from 'react';
import { IAudioControl } from '@bynder-studio/render-core';
import { useAudioShortcuts } from 'packages/hooks/useAudioShortcuts';

export default function usePlaybackAudio({ control }: { control?: IAudioControl }) {
    const listenersRef = useRef(new Set<() => void>());

    const subscribe = useCallback(
        (listener: () => void) => {
            listenersRef.current.add(listener);

            return () => {
                listenersRef.current.delete(listener);
            };
        },
        [listenersRef],
    );

    const onChange = useCallback(() => listenersRef.current.forEach((listener) => listener()), [listenersRef]);

    const getMutedStatus = useCallback(() => control?.getMutedStatus() || 0, [control]);
    const setMutedStatus = useCallback(
        (isMuted: boolean) => {
            if (!control) {
                return;
            }

            control?.setMutedStatus(isMuted);
            onChange();
        },
        [control, onChange],
    );

    const getLockedStatus = useCallback(() => control?.locked || false, [control]);

    const toggleMutedStatus = useCallback(() => {
        if (!control) {
            return;
        }

        control?.setMutedStatus(!control?.getMutedStatus());
        onChange();
    }, [control, onChange]);

    useAudioShortcuts(toggleMutedStatus);

    const getVolume = useCallback(() => control?.volume || 0, [control]);
    const setVolume = useCallback(
        (volume: number) => {
            control?.setVolume(volume);
            onChange();
        },
        [control, setMutedStatus, onChange],
    );

    return {
        volume: useSyncExternalStore(subscribe, getVolume),
        isMuted: useSyncExternalStore(subscribe, getMutedStatus),
        locked: useSyncExternalStore(subscribe, getLockedStatus),
        setVolume,
        setMutedStatus,
        toggleMutedStatus,
    };
}
