import React, { Suspense, useState } from 'react';
import { Divider, Form } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import useAccessRights from 'packages/hooks/useAccessRights';
import useEditor from '~/hooks/useEditor';
import useEditorSelectedElement from '~/hooks/useEditorSelectedElement';
import useForceUpdate from '~/hooks/useForceUpdate';
import features from '~/configs/features';
import AuthorizationHelper from '~/helpers/AuthorizationHelper';
import { SizeFormRow } from '../index.styled';
import { InspectorHeader, InspectorContent, Section, FormSectionContent } from '../../index.styled';
import SettingsTabs from '../Tabs';
import ElementAlignment from '../ElementAlignment';
import Width from '../Size/Width';
import LockToggler from '../Size/LockToggle';
import Height from '../Size/Height';
import HorizontalPosition from '../Position/Horizontal';
import VerticalPosition from '../Position/Vertical';
import Rotation from '../Rotation';
import Opacity from '../Opacity';
import Duration from '../Duration';
import Masking from '../Masking';
import Shadow from '../Shadow';
import CropMode from '../CropMode';
import BrowseVideo from './BrowseVideo';
import { BlendMode } from '../BlendMode';
import AssetSource from '../AssetSource';
import Audio from './Audio';
import Animations from '../../Animations';
import PersonalUploads from '../PersonalUploads';
import AllowToggleVisibility from '../AllowToggleVisibility';
import ContentProperty from '../ContentProperty';

const Video = () => {
    const { translate } = useTranslate();
    const { isPlatformAllowed } = useAccessRights();
    const { creativeModel, creativeType, manipulationRenderer, frameRate } = useEditor();
    const { selectedElement, selectedElements, selectedElementDeferred } = useEditorSelectedElement();
    const forceUpdate = useForceUpdate();

    const [cropModeOpened, setCropModeOpened] = useState(false);
    const toggleCropMode = () => setCropModeOpened((state) => !state);

    const isInternalAssetsAvailable = AuthorizationHelper.isFeatureAvailable(features.INTERNAL_ASSET);

    const tabs = [
        { value: 'video', label: translate('editor.sidebar.shots.video.tabs.text') },
        { value: 'video_animation', label: translate('editor.sidebar.shots.animation.tabs.text') },
    ];
    const [activeTab, setTab] = useState(tabs[0].value);

    return (
        <>
            {creativeType === 'VIDEO' && (
                <InspectorHeader aria-labelledby="shots-video-tabs-settings">
                    <SettingsTabs tabs={tabs} activeTab={activeTab} onChange={(value) => setTab(value)} />
                </InspectorHeader>
            )}
            <InspectorContent withTabs={creativeType === 'VIDEO'}>
                {activeTab === tabs[0].value && (
                    <>
                        <Section aria-labelledby="shots-alignment-settings">
                            <ElementAlignment creativeModel={creativeModel} selectedElements={selectedElements} />
                        </Section>
                        <Divider />
                        <Section aria-labelledby="shots-size-settings">
                            <FormSectionContent onSubmit={preventSubmit}>
                                <SizeFormRow>
                                    <Width
                                        creativeModel={creativeModel}
                                        selectedElement={selectedElement}
                                        creativeType={creativeType}
                                        isLinked={false}
                                    />
                                    <LockToggler selectedElement={selectedElement} forceUpdate={forceUpdate} />
                                    <Height
                                        creativeModel={creativeModel}
                                        selectedElement={selectedElement}
                                        creativeType={creativeType}
                                        isLinked={false}
                                    />
                                </SizeFormRow>
                                <Form.Row>
                                    <HorizontalPosition
                                        creativeModel={creativeModel}
                                        selectedElement={selectedElement}
                                        isLinked={false}
                                    />
                                    <VerticalPosition
                                        creativeModel={creativeModel}
                                        selectedElement={selectedElement}
                                        isLinked={false}
                                    />
                                </Form.Row>
                                <Form.Row>
                                    <Rotation
                                        creativeModel={creativeModel}
                                        selectedElement={selectedElement}
                                        disabled={false}
                                    />
                                    <Opacity
                                        creativeModel={creativeModel}
                                        selectedElement={selectedElement}
                                        disabled={false}
                                    />
                                </Form.Row>
                            </FormSectionContent>
                        </Section>
                        <Divider />
                        {isPlatformAllowed([features.CONTENT_PROPERTIES]) && (
                            <>
                                <Section aria-labelledby="shots-text-content-property">
                                    <Suspense>
                                        <ContentProperty
                                            creativeModel={creativeModel}
                                            selectedElement={selectedElementDeferred}
                                        />
                                    </Suspense>
                                </Section>
                                <Divider />
                            </>
                        )}
                        <Section aria-labelledby="shots-video-asset-settings">
                            <FormSectionContent onSubmit={preventSubmit}>
                                <BrowseVideo
                                    id="shots-video-asset-settings"
                                    creativeModel={creativeModel}
                                    selectedElement={selectedElement}
                                    frameRate={frameRate}
                                    disabled={false}
                                />
                                <CropMode opened={cropModeOpened} onToggle={toggleCropMode} disabled={false} />
                                <Divider />
                                <Duration
                                    disabled={false}
                                    selectedElement={selectedElement}
                                    creativeModel={creativeModel}
                                    frameRate={frameRate}
                                />
                                <>
                                    <Divider />
                                    <Audio selectedElement={selectedElement} creativeModel={creativeModel} />
                                </>
                            </FormSectionContent>
                        </Section>
                        <Divider />
                        <Section aria-labelledby="shots-video-asset-source">
                            <FormSectionContent onSubmit={preventSubmit}>
                                <Form.Group>
                                    <AssetSource />
                                </Form.Group>
                                {isInternalAssetsAvailable && (
                                    <>
                                        <Divider />
                                        <Form.Group>
                                            <PersonalUploads />
                                        </Form.Group>
                                    </>
                                )}
                            </FormSectionContent>
                        </Section>
                        <Divider />
                        <Section aria-labelledby="shots-video-visibility">
                            <FormSectionContent onSubmit={preventSubmit}>
                                <Form.Group>
                                    <Form.Label>{translate('editor.element.visibility_control')}</Form.Label>
                                    <AllowToggleVisibility
                                        selectedElement={selectedElement}
                                        creativeModel={creativeModel}
                                    />
                                </Form.Group>
                            </FormSectionContent>
                        </Section>
                        <Divider />
                        <Section aria-labelledby="shots-video-masking-settings">
                            <FormSectionContent onSubmit={preventSubmit}>
                                <Form.Group>
                                    <Form.Label>{translate('editor.element.mask')}</Form.Label>
                                    <Masking
                                        element={selectedElement}
                                        creativeModel={creativeModel}
                                        manipulationRenderer={manipulationRenderer}
                                        creativeType={creativeType}
                                        disabled={false}
                                    />
                                </Form.Group>
                            </FormSectionContent>
                        </Section>
                        <Divider />
                        <Section aria-labelledby="shots-video-shadow">
                            <FormSectionContent onSubmit={preventSubmit}>
                                <Form.Group>
                                    <Form.Label>{translate('editor.sidebar.shots.text.shadow.label')}</Form.Label>
                                    <Shadow
                                        disabled={false}
                                        selectedElement={selectedElement}
                                        creativeModel={creativeModel}
                                    />
                                </Form.Group>
                                <Divider />
                                <Form.Group>
                                    <BlendMode disabled={false} />
                                </Form.Group>
                            </FormSectionContent>
                        </Section>
                    </>
                )}
                {activeTab === tabs[1].value && <Animations element={selectedElement} disabled={false} />}
            </InspectorContent>
        </>
    );
};

export default Video;

function preventSubmit(e: React.FormEvent) {
    e.preventDefault();
}
