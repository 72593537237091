import React, { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EmptyState } from '@bynder/design-system';
import { IconVideoTemplate } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import { getCategoriesState, getPendingCategories } from 'packages/store/categories/categories.selectors';
import { getTemplates } from 'packages/store/templates/templates.selectors';
import { Grid } from 'packages/ds/Grid/Grid';
import { CollectionSubType, CollectionType } from 'packages/store/collections/types';
import { initCategories } from 'packages/store/categories/categories.actions';
import useQueryParams from 'packages/hooks/useQueryParams';
import filtersStorage from 'packages/hooks/filtersStorage';
import useAccessRights from 'packages/hooks/useAccessRights';
import useCreativeThumbnailsUpdates from 'packages/hooks/useCreativeThumbnailsUpdates';
import { Template } from 'packages/store/templates/types';
import { INITIALIZE_TEMPLATES } from '~/store/types';
import { fetchTemplates, getTemplate, selectTemplate, initializeTemplates } from '~/store/templates/templates.actions';
import { selectedTemplateSelector } from '~/store/templates/templates.selectors';
import features from '~/configs/features';
import roles from '~/configs/roles';
import Filters from 'packages/pages/components/filters';
import useFluidWidth from 'packages/pages/components/grid/useFluidWidth';
import CategoryCard from 'packages/pages/components/categories/CategoryCard';
import { OwnershipFilterType } from 'packages/pages/components/filters/components/Ownership/types';
import { MediaFilterType } from 'packages/pages/components/filters/components/MediaType/types';
import { PageSectionGrid, PageSectionHeader, PageSectionSection } from 'packages/pages/components/Categories.styled';
import useFilters from 'packages/pages/components/categories/useFilters';
import { removeEmptyProps, TEMPLATES_PAGE_SIZE, useScrollImproved } from 'packages/pages/utils';
import EmptyFilteredState, { isFilteredList } from 'packages/pages/components/empty/EmptyFilteredState';
import useTemplateRenameModal from 'packages/pages/components/templates/useTemplateRenameModal';
import useTemplateMoveModal from 'packages/pages/components/templates/useTemplateMoveModal';
import useTemplateDeleteModal from 'packages/pages/components/templates/useTemplateDeleteModal';
import { deselectBlankType, setPage } from 'packages/store/newDesign/newDesign.actions';
import { deselectGallery, initializeGallery } from '~/store/gallery/gallery.actions';
import { HiddenActions } from 'packages/pages/components/templates/TemplateContextMenu/types';
import TemplateGrid from 'packages/pages/components/templates/TemplateGrid';
import useTemplateShareModal from 'packages/pages/shareModal/useTemplateShareModal';
import { DesignSubPages } from '../../components/Sidebar/types';
import { ModalPageBody } from '../../components/DesignCreateModal.styled';

const HIDDEN_MENU_ACTIONS: HiddenActions = ['removeFromCategory', 'setMoveModalTemplate'];

const Categories = () => {
    const { translate } = useTranslate();
    const dispatch = useDispatch();
    const location = window.location;
    const { gridContainer: categoriesGridContainer } = useFluidWidth();
    const { gridContainer: templatesGridContainer } = useFluidWidth();
    const {
        categories,
        isFetching: isCategoriesFetching,
        fetchOptions: categoriesFetchOptions,
    } = useSelector(getCategoriesState);
    const pendingThumbnailIds = useSelector(getPendingCategories);
    const {
        templates,
        loadingData: isTemplatesFetching,
        fetchOptions: templatesFetchOptions,
        totalItems: templatesTotalItems,
    } = useSelector(getTemplates);

    const { isPlatformAllowed, isAccountRoleAssigned } = useAccessRights();

    const type: CollectionType = 'CREATIVE';
    const subType: CollectionSubType = 'TEMPLATE';

    const {
        getQueryParam,
        getAllQueryParams,
        getAllQueryParamsRaw,
        restoreQueryParams,
        setQueryParam,
        resetQueryParams,
    } = useQueryParams();
    const { onSearch, onOwnershipSelect, onMediaTypeSelect } = useFilters();
    const { getFiltersFromStorage, setFiltersToStorage } = filtersStorage();
    const { setShareModalTemplate, renderModalTemplateShare } = useTemplateShareModal({});

    const { setRenameModalTemplate, renderModalTemplateRename } = useTemplateRenameModal();
    const { setMoveModalTemplate, renderModalTemplateMove } = useTemplateMoveModal({ isOverview: true });
    const { setDeleteModalTemplate, renderModalTemplateDelete } = useTemplateDeleteModal();
    const selectedTemplate = useSelector(selectedTemplateSelector);

    const isTrialAccount =
        isPlatformAllowed([features.TRIAL_ENABLED]) && isAccountRoleAssigned(roles.video_brand_studio.video.manage);

    const isTemplateSelected = (template: Template) => {
        return selectedTemplate?.id === template.id;
    };

    let isSearch = !!getQueryParam('search')?.length;

    useCreativeThumbnailsUpdates(pendingThumbnailIds);

    // first load
    useLayoutEffect(() => {
        dispatch(initializeTemplates());
        dispatch(initializeGallery());
        dispatch(deselectBlankType());

        const storedQueries = getFiltersFromStorage('newDesign:categories');

        // removing template id from stored query params to avoid pre-selection of template
        const storedFilteredQueries =
            storedQueries?.length &&
            storedQueries
                .split('&')
                .reduce((acc, query) => {
                    if (!query.includes('template')) {
                        acc.push(query);
                    }

                    return acc;
                }, [])
                .join('&');

        if (storedFilteredQueries) {
            restoreQueryParams(storedFilteredQueries);
        }

        const querySearch = getQueryParam('search');

        if (querySearch) {
            isSearch = true;
        }

        const queriedSorting = getQueryParam('sortedBy');
        const queriedOrder = getQueryParam('sortOrder');
        const queriedOwnership = getQueryParam('ownership');
        const queriedType = getQueryParam('typeTemplate')?.toUpperCase();

        const queriedParams = removeEmptyProps({
            search: querySearch,
            sort: queriedSorting && queriedOrder && [queriedSorting, queriedOrder],
            ownershipSource: queriedOwnership?.toUpperCase(),
        });

        dispatch(
            initCategories({
                type,
                subType,
                ownershipSource: isTrialAccount
                    ? OwnershipFilterType.OWNED_BY_OTHERS
                    : OwnershipFilterType.OWNED_BY_ANYONE,
                includeEmptyCollection: !isAccountRoleAssigned(roles.video_brand_studio.video.manage),
                ...queriedParams,
            }),
        );

        if (location.pathname === '/categories' || location.search.includes('page=categories')) {
            dispatch(
                fetchTemplates({
                    newOptions: {
                        ...templatesFetchOptions,
                        ...queriedParams,
                        creativeType: queriedType === MediaFilterType.ANY ? undefined : queriedType,
                        ownershipSource: isTrialAccount
                            ? OwnershipFilterType.OWNED_BY_OTHERS
                            : queriedParams.ownershipSource || OwnershipFilterType.OWNED_BY_ANYONE,
                        collectionId: undefined,
                        assignedToCollection: isSearch ? undefined : false,
                    },
                    setSelected: false,
                    fetchFromStart: true,
                }),
            );
        }

        return () => {
            if (
                location.pathname.includes('categories') ||
                (location.search.includes('page=category_details') && location.search.includes('create=true'))
            ) {
                dispatch({ type: INITIALIZE_TEMPLATES });
            }
        };
    }, []);

    useEffect(() => {
        const queriedTemplateId = getQueryParam('template');

        if (queriedTemplateId) {
            dispatch(getTemplate(queriedTemplateId));
        }
    }, []);

    useEffect(() => {
        if (location.pathname.includes('categories') && !location.pathname.includes('categories/')) {
            setFiltersToStorage('categories', getAllQueryParams());
        }

        if (
            location.search.includes('page=categories') &&
            location.search.includes('create=true') &&
            location.search.includes('categoryId=ALL')
        ) {
            setFiltersToStorage('newDesign:categories', getAllQueryParams());
        }
    }, [getAllQueryParams()]);

    const onTemplatesScroll = () => {
        const notLoading = !isTemplatesFetching;
        const notLastPage = templates.length < templatesTotalItems;

        if (notLoading && notLastPage) {
            const newOptions = { ...templatesFetchOptions };
            newOptions.size = TEMPLATES_PAGE_SIZE;
            newOptions.page = Math.trunc(templates.length / newOptions.size);

            dispatch(fetchTemplates({ newOptions }));
        }
    };

    const onTemplateSelect = (template: Template) => {
        dispatch(selectTemplate(template));
        dispatch(deselectBlankType(true));
        dispatch(deselectGallery());
    };

    const menuActions = {
        setRenameModalTemplate,
        setDeleteModalTemplate,
        setMoveModalTemplate,
        setShareModalTemplate,
        showPreview: (t: Template) => {
            dispatch(selectTemplate(t));
            setQueryParam('template', t.id.toString());
            dispatch(setPage(DesignSubPages.TEMPLATE_PREVIEW));
        },
    };

    const isCategoriesEmpty = !categories.length && !isCategoriesFetching;
    const isTemplatesEmpty = !templates.length && !isTemplatesFetching;
    const isBothListsEmpty = isCategoriesEmpty && isTemplatesEmpty;
    const isFiltered = isFilteredList(getAllQueryParamsRaw());

    const renderTemplatesEmpty = () =>
        isFiltered ? (
            <EmptyFilteredState
                storageKey="categories"
                onResetFilters={() => {
                    dispatch(
                        initCategories({
                            type,
                            subType,
                            search: '',
                            creativeType: undefined,
                            includeEmptyCollection: !isAccountRoleAssigned(roles.video_brand_studio.video.manage),
                        }),
                    );
                    dispatch(
                        fetchTemplates({
                            newOptions: {
                                ...templatesFetchOptions,
                                search: '',
                                creativeType: undefined,
                            },
                            setSelected: false,
                            fetchFromStart: true,
                        }),
                    );
                }}
            />
        ) : (
            <EmptyState
                icon={<IconVideoTemplate />}
                title={translate('pages.categories.category.empty.templates.title')}
                text={translate('pages.categories.category.empty.templates.info')}
            />
        );

    const renderCategoriesGrid = () =>
        categoriesGridContainer.current?.clientWidth && (
            <Grid>
                {categories.map((category) => (
                    <CategoryCard
                        key={category.id}
                        category={category}
                        to={`?create=true&page=categories&categoryId=${category.id}`}
                        onClick={() => {
                            dispatch(setPage(DesignSubPages.CATEGORY_DETAILS));
                        }}
                        includeSharedIndicator={!isAccountRoleAssigned(roles.video_brand_studio.video.manage)}
                    />
                ))}
            </Grid>
        );

    useScrollImproved(categories, templatesGridContainer, '#categories_body');

    useEffect(() => {
        resetQueryParams(['template']);
    }, []);

    return (
        <div id="scroll-wrapper">
            <Filters data-testid="categories overview : filters">
                <Filters.FiltersLeftBlock>
                    <Filters.Search onSearch={onSearch} />
                    {(!isAccountRoleAssigned(roles.video_brand_studio.video.manage) ||
                        isPlatformAllowed([features.IMAGES_ENABLED, features.VIDEOS_ENABLED])) && (
                        <Filters.FiltersDivider />
                    )}
                    {!isAccountRoleAssigned(roles.video_brand_studio.video.manage) && (
                        <Filters.Ownership sortBy={templatesFetchOptions.ownershipSource} onSort={onOwnershipSelect} />
                    )}
                    {isPlatformAllowed([features.IMAGES_ENABLED, features.VIDEOS_ENABLED]) && (
                        <Filters.MediaType value={templatesFetchOptions.creativeType} onSelect={onMediaTypeSelect} />
                    )}
                </Filters.FiltersLeftBlock>
            </Filters>
            <ModalPageBody id="categories_body" data-testid="categories overview : body">
                {isBothListsEmpty && isFiltered && (
                    <>
                        {(categories.length && !isCategoriesFetching) || isCategoriesFetching ? (
                            <PageSectionHeader>
                                {translate('modal.design.create.uncategorized_templates.title')}
                            </PageSectionHeader>
                        ) : null}
                        <PageSectionGrid
                            ref={templatesGridContainer}
                            data-testid="categories overview : templates grid"
                        >
                            <EmptyFilteredState
                                storageKey="new_design_categories"
                                onResetFilters={() => {
                                    dispatch(
                                        initCategories({
                                            ...categoriesFetchOptions,
                                            type,
                                            subType,
                                            search: '',
                                            creativeType: undefined,
                                        }),
                                    );
                                    dispatch(
                                        fetchTemplates({
                                            newOptions: {
                                                ...templatesFetchOptions,
                                                search: '',
                                                creativeType: undefined,
                                            },
                                            setSelected: false,
                                            fetchFromStart: true,
                                        }),
                                    );
                                }}
                            />
                        </PageSectionGrid>
                    </>
                )}
                {isBothListsEmpty && !isFiltered && (
                    <PageSectionGrid ref={templatesGridContainer} data-testid="categories overview : templates grid">
                        {renderTemplatesEmpty()}
                    </PageSectionGrid>
                )}
                {!isBothListsEmpty && !isCategoriesEmpty && (
                    <>
                        {(templates.length && !isTemplatesFetching) || isTemplatesFetching ? (
                            <PageSectionHeader>
                                {translate('pages.categories.categories_overview.title')}
                            </PageSectionHeader>
                        ) : null}
                        <PageSectionGrid
                            ref={categoriesGridContainer}
                            data-testid="categories overview : categories grid"
                        >
                            {renderCategoriesGrid()}
                        </PageSectionGrid>
                        <PageSectionSection />
                    </>
                )}
                {!isBothListsEmpty && !isTemplatesEmpty && (
                    <>
                        {(categories.length && !isCategoriesFetching) || isCategoriesFetching ? (
                            <PageSectionHeader>
                                {translate('pages.categories.uncategorized_templates.title')}
                            </PageSectionHeader>
                        ) : null}
                        <PageSectionGrid
                            ref={templatesGridContainer}
                            data-testid="categories overview : templates grid"
                        >
                            {templatesGridContainer.current?.clientWidth && (
                                <TemplateGrid
                                    templates={templates}
                                    menuActions={menuActions}
                                    hiddenMenuActions={HIDDEN_MENU_ACTIONS}
                                    isTemplateSelected={isTemplateSelected}
                                    onSelect={onTemplateSelect}
                                    onCardClick={onTemplateSelect}
                                    isLoading={isTemplatesFetching}
                                    totalItems={templatesTotalItems}
                                    loadMore={onTemplatesScroll}
                                    includeSharedIndicator={
                                        !isAccountRoleAssigned(roles.video_brand_studio.video.manage)
                                    }
                                />
                            )}
                        </PageSectionGrid>
                    </>
                )}
                {!isBothListsEmpty && isTemplatesEmpty && isCategoriesEmpty && renderTemplatesEmpty()}
            </ModalPageBody>
            {renderModalTemplateRename()}
            {renderModalTemplateMove()}
            {renderModalTemplateDelete()}
            {renderModalTemplateShare()}
        </div>
    );
};

export default Categories;
