import React, { FC, useCallback, useMemo } from 'react';
import {
    AudioElement,
    ElementTypes,
    type MultiPageVideoModel,
    type UpdateGlobalPropertyOptions,
} from '@bynder-studio/render-core';
import useDesign from 'packages/pages/design/hooks/useDesign';
import useVariations from 'packages/pages/design/hooks/useVariations';
import GlobalAudio from 'packages/pages/editor/RightSideMenu/Globals/GlobalAudio';

type ShotAudioProps = {
    track: 1 | 2;
};

const ShotAudio: FC<ShotAudioProps> = ({ track }) => {
    const { creativeModel, frameRate } = useDesign<MultiPageVideoModel>();
    const { updateVariationGlobalProperty } = useVariations();

    const globalAudio = useMemo(
        () =>
            track === 1
                ? creativeModel?.getCurrentModel()?.getGlobalAudioTrack1()
                : creativeModel?.getCurrentModel()?.getGlobalAudioTrack2(),
        [creativeModel, track],
    );

    const onAudioChange = useCallback(
        (params: Partial<AudioElement>, _: UpdateGlobalPropertyOptions) => {
            if (!creativeModel) {
                return;
            }

            updateVariationGlobalProperty(ElementTypes.GLOBAL_AUDIO, globalAudio.id, params);
        },
        [creativeModel, updateVariationGlobalProperty, globalAudio.id],
    );

    return (
        <GlobalAudio
            creativeModel={creativeModel}
            frameRate={frameRate}
            disabled={globalAudio?.locked || false}
            track={track}
            onAudioChange={onAudioChange}
            configuratorMode
        />
    );
};

export default ShotAudio;
