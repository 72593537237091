import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Flex, ModalBase, notify } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import Sidebar from 'packages/pages/components/AssetsPickerModal/components/AssetsPickerSidebar';
import UploadButton from 'packages/pages/components/Uploader/UploadButton';
import AssetsPicker from 'packages/pages/components/AssetsPickerModal/components/AssetsPicker';
import { customerIdSelector } from 'packages/store/user/user.selectors';
import useActions from 'packages/pages/components/AssetsPickerModal/components/useActions';
import {
    FullHeightWrapper,
    StyledModalBasedContent,
} from 'packages/pages/components/AssetsPickerModal/components/index.styled';
import {
    AssetGroup,
    CONTENT_MODAL_STEPS,
    ContentSelectorModalProps,
} from 'packages/pages/editor/ContentPickerModal/types';
import { subscribeAssetsStatusOptions, useSocketSubscribe } from 'packages/socket';
import { getBynderAccessToken } from 'packages/store/platform/platform.selectors';
import { catchResponseError } from 'packages/helpers/helpers';
import { useValidationModal } from 'packages/pages/editor/ContentPickerModal/useValidationModal';
import PortionSelectorLoader from '~/common/editor/contentSelection/PortionSelectorLoader';
import PortionSelector from '~/common/editor/contentSelection/PortionSelector';
import { framesToMilliseconds, framesToMillisecondsForSeek, millisecondToFrames } from '~/common/editor/editorHelper';
import { checkAssetAccessible } from '~/helpers/helpers';
import AssetsService from '~/services/AssetsService';
import { typesMap } from '~/common/editor/contentSelection/helper';
import AuthorizationHelper from '~/helpers/AuthorizationHelper';
import features from '~/configs/features';
import { AssetStatus } from 'packages/types/assets';
import modalContainer from 'packages/common/modalContainer';
import useDesign from 'packages/pages/design/hooks/useDesign';
import { extractVerifiableData, getDerivativeType, getGeneratedDerivativeType, validateFile } from './utils';

const assetFieldSelection = `
  name
  url
  originalUrl
  width
  height
  fileSize
  derivatives {
    thumbnail
    webImage
  }
  ... on Video {
    previewUrls
  }
  extensions
  isArchived
  isWatermarked
  isLimitedUse
`;

type SaveErrorType = 'generic' | 'extension' | 'size' | 'resolution' | 'watermarked' | 'limited';

const DISPLAY_ERRORS: { [K in SaveErrorType]: { title: string; description?: string } } = {
    generic: {
        title: 'modal.assetsPicker.error.title.generic',
    },
    extension: {
        title: 'modal.assetsPicker.error.title.extension',
    },
    size: {
        title: 'modal.assetsPicker.error.title.size',
        description: 'modal.assetsPicker.error.description.size',
    },
    resolution: {
        title: 'modal.assetsPicker.error.title.resolution',
        description: 'modal.assetsPicker.error.description.resolution',
    },
    watermarked: {
        title: 'modal.assetsPicker.error.title.watermarked',
    },
    limited: {
        title: 'modal.assetsPicker.error.title.limited',
    },
};

const ContentPickerModal = (props: ContentSelectorModalProps) => {
    const { selectedObject } = props;
    const { template, creativeModel } = useDesign();
    const { translate } = useTranslate();
    const bynderAccessToken = useSelector(getBynderAccessToken);
    const customerId = useSelector(customerIdSelector);
    const [processProgress, setProcessProgress] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const [isFetchingAsset, setFetchingAsset] = useState(false);
    const [selectedAsset, setSelectedAsset] = useState<any>(null);
    const [offsetTime, setOffsetTime] = useState(0);
    const [isTrimMode, setIsTrimMode] = useState(props.modalStep === CONTENT_MODAL_STEPS.TRIM);
    const [duration, setDuration] = useState<number>(
        selectedObject?.element?.duration || selectedObject?.duration || 0,
    );

    const isDynamicLengthEnabled = selectedObject?.element?.useDynamicLength;

    const type = props.type.toLowerCase();
    const isDATFeatureEnabled = AuthorizationHelper.isFeatureAvailable(features.UCV_DERIVATIVES) && type === 'image';

    const {
        assetType,
        wrapperRef,
        collectionId,
        selectedLocalAsset,
        allowInternalAsset,
        handleDamClick,
        setSelectedCollection,
        onLocalAssetClick,
        handleBackClick,
    } = useActions({
        ...props,
        preSelectedAsset: selectedAsset,
    });

    const { renderValidationModal, toggleValidationModal } = useValidationModal();

    const listenWsAssetStatus = useSocketSubscribe(
        subscribeAssetsStatusOptions({
            customerId,
            assetIds: selectedAsset ? [selectedAsset.id] : [],
            onMessage: ({ items }, unsubscribe) => {
                if (items.length === 0) {
                    unsubscribe();

                    return;
                }

                const updatedAsset = items.find((item) => item.id === selectedAsset.id);

                if (!updatedAsset) {
                    return;
                }

                if (updatedAsset.status === AssetStatus.PROCESSED || updatedAsset.status === AssetStatus.FAILED) {
                    setSelectedAsset(updatedAsset);
                    unsubscribe();

                    return;
                }

                const progress = updatedAsset.progress || 0;

                if (progress > processProgress) {
                    setProcessProgress(progress);
                }
            },
        }),
    );

    useEffect(() => {
        const elementId = selectedObject?.element?.srcId;
        let offsetTime = 0;

        if (props.modalStep === CONTENT_MODAL_STEPS.TRIM && elementId && (type === 'video' || type === 'audio')) {
            offsetTime = selectedObject.element?.offsetTime ?? selectedObject.offsetTime;
        }

        if (elementId) {
            getAsset(elementId);
        }

        setOffsetTime(offsetTime);
    }, []);

    const getAsset = (assetId: number) => {
        setFetchingAsset(true);
        AssetsService.getAsset(assetId, '1920x1920')
            .then(({ asset, status }) => {
                if (status === 200) {
                    setSelectedAsset(asset);
                }
            })
            .catch(catchResponseError)
            .finally(() => {
                setFetchingAsset(false);
            });
    };

    const displayError = useCallback(
        (error: any) => {
            switch (error.displayMode) {
                case 'modal': {
                    toggleValidationModal(true, error.type);

                    return;
                }

                case 'notify': {
                    const { title, description } = DISPLAY_ERRORS[error.type];

                    notify({
                        title: translate(title),
                        description: description ? translate(description) : undefined,
                        isPersistent: false,
                        variant: 'error',
                    });

                    return;
                }

                default:
                    return null;
            }
        },
        [toggleValidationModal, translate],
    );

    const addAssetToArtboard = useCallback(
        (asset: any) => {
            const assetDuration = millisecondToFrames(asset.duration, frameRate);

            props.confirmSelection({
                ...typesMap.get(type).makeObj(asset),
                offsetTime,
                duration: assetDuration > duration ? duration : assetDuration,
            });
        },
        [props.confirmSelection, duration, offsetTime, type],
    );

    const onSelectAsset = useCallback(
        (rawAsset: any) => {
            setOffsetTime(0);
            setLoading(false);
            setSelectedAsset(rawAsset);

            if (type === 'image') {
                addAssetToArtboard(rawAsset);
            } else {
                setFetchingAsset(!rawAsset.duration);

                if (!isEditorPage && isDynamicLengthEnabled) {
                    const videoElementsTree = (elements) =>
                        elements.reduce((acc, el) => {
                            if (el.type === 'GROUP') {
                                return videoElementsTree(el.children);
                            }

                            return el.type === 'VIDEO' ? (acc = [...acc, el]) : acc;
                        }, [] as any);

                    const templateVideoElements = videoElementsTree(
                        template?.pages[creativeModel?.getCurrentPageIndex()].elements,
                    );

                    const templateVideoElement = templateVideoElements.find(
                        (el) => el.id === selectedObject.element.id,
                    );

                    const templateFrameRate = template.pages[creativeModel?.getCurrentPageIndex()].frameRate;

                    const templateElementDuration = templateVideoElement.properties.duration;

                    // Unify raw asset duration using template frame rate
                    const rawAssetNormalizedDuration = (rawAsset.duration / 1000) * templateFrameRate;

                    if (rawAssetNormalizedDuration < templateElementDuration) {
                        notify({
                            title: translate('modal.assetsPicker.error.title.dynamic-scene'),
                            description: translate('modal.assetsPicker.error.description.dynamic-scene', {
                                duration: templateElementDuration / templateFrameRate,
                            }),
                            isPersistent: false,
                            variant: 'error',
                        });

                        return;
                    }
                }

                setIsTrimMode(true);

                if (!rawAsset.duration) {
                    setFetchingAsset(false);
                    listenWsAssetStatus();
                }
            }
        },
        [addAssetToArtboard, listenWsAssetStatus, type],
    );

    const saveExternalAsset = useCallback(
        async ([asset], { selectedFile }) => {
            if (!asset) {
                return;
            }

            // TODO: Seems like an old code, check with BE
            const isOriginal = selectedFile?.isFakeOriginal;
            const derivativeType =
                isDATFeatureEnabled && !isOriginal ? getDerivativeType(asset, selectedFile?.url) : '';
            const verifiableFileData = extractVerifiableData(asset, derivativeType, selectedFile?.url);
            const validation = validateFile(
                verifiableFileData,
                !AuthorizationHelper.isFeatureAvailable(features.UCV_LIMITED),
            );

            if (validation.status === 'error') {
                displayError(validation);

                return;
            }

            if (isDATFeatureEnabled && !selectedFile) {
                displayError({ status: 'error', type: 'generic', displayMode: 'modal' });

                return;
            }

            setLoading(true);

            const thumbnails = ['image', 'video'].includes(type) ? '320x320,1920x1920' : '';
            const options: any = {
                customerId,
                assetType: type.toUpperCase(),
                sourceType: 'EXTERNAL',
                externalId: asset.id,
                bynderToken: bynderAccessToken,
                isWatermarked: asset.isWatermarked,
            };

            if (isDATFeatureEnabled && !isOriginal) {
                options.derivativeType = getGeneratedDerivativeType(derivativeType);
                options.derivativeUrl = selectedFile.url;
                options.naturalWidth = selectedFile.width;
                options.naturalHeight = selectedFile.height;
            }

            const isPlayableType = type === 'video' || type === 'audio';
            const urlToLoad = isPlayableType ? asset.originalUrl || asset?.previewUrls?.[0] : null;

            if (isPlayableType && urlToLoad) {
                const [isAccessible, metadata] = await checkAssetAccessible(urlToLoad, type);

                if (isAccessible) {
                    const { width, height, duration } = metadata;

                    if (type === 'video') {
                        options.naturalWidth = width;
                        options.naturalHeight = height;
                    }

                    options.duration = duration;
                }
            }

            try {
                const {
                    path: { errors, assets: result },
                } = await AssetsService.saveAssets(options, { thumbnails });

                setLoading(false);

                if (errors.length) {
                    displayError({ status: 'error', type: 'generic', displayMode: 'notify' });
                } else {
                    onSelectAsset(result[0]);
                }
            } catch (error) {
                displayError({ status: 'error', type: 'generic', displayMode: 'notify' });
                catchResponseError(error);
            }
        },
        [isDATFeatureEnabled, type, customerId, bynderAccessToken, displayError, onSelectAsset],
    );

    const onSelectVideoSegment = (frame: number, newDuration: number | null) => {
        setOffsetTime(framesToMillisecondsForSeek(frame, frameRate));

        if (newDuration) {
            setDuration(newDuration);
        }
    };

    const assetSourceData = useMemo(
        () => (selectedAsset ? typesMap.get(type).makeObj(selectedAsset) : null),
        [selectedAsset],
    );

    const { show, frameRate, resizableRange, isEditorPage } = props;
    const isPersonalUploadsAllowed = isEditorPage || (selectedObject.element?.virtualData?.allowPersonalUpload ?? true);
    const isPreviewAccessible = type === 'image' ? true : !isLoading && !!assetSourceData?.duration;

    const compactViewProps = useMemo(
        () => ({
            language: 'en_US',
            mode: isDATFeatureEnabled ? 'SingleSelectFile' : 'SingleSelect',
            assetFilter: props.collectionId
                ? {
                      collectionId: props.collectionId,
                      assetType_in: [`${type.toUpperCase()}`],
                      showToolbar: true,
                  }
                : undefined,
            hideSwitch: props.collectionId,
            selectedAssets: [selectedAsset?.externalId],
            assetTypes: [type.toUpperCase()],
            isContainerMode: true,
            assetFieldSelection,
            onSuccess: saveExternalAsset,
        }),
        [isDATFeatureEnabled, props.collectionId, saveExternalAsset, selectedAsset?.externalId, type],
    );

    const renderLoading = () => (
        <Flex justifyContent="center" alignItems="center">
            <Button isLoading variant="clean" title="" />
        </Flex>
    );

    return (
        <ModalBase
            container={modalContainer}
            size="fullscreen"
            isOpen={show}
            onClose={() => {
                props.toggleModal(false);
            }}
        >
            <ModalBase.Header
                title={isTrimMode ? `Trim ${type}` : `Select ${type}`}
                additionalActions={
                    isPersonalUploadsAllowed && assetType === AssetGroup.LOCAL && !isTrimMode ? (
                        <UploadButton iconType="upload" assetTypes={[type]} collectionId={collectionId} />
                    ) : undefined
                }
                backButton={
                    isTrimMode
                        ? {
                              title: '',
                              onClick: () => {
                                  setIsTrimMode(false);
                                  handleBackClick();
                              },
                          }
                        : undefined
                }
            />
            {!isTrimMode && allowInternalAsset && isPersonalUploadsAllowed && (
                <ModalBase.Sidebar>
                    <Sidebar
                        assetType={assetType}
                        type={type}
                        selectedCollectionId={collectionId}
                        setSelectedCollection={setSelectedCollection}
                        handleDamClick={handleDamClick}
                    />
                </ModalBase.Sidebar>
            )}

            <ModalBase.Content>
                {!isTrimMode ? (
                    <StyledModalBasedContent isLocal={assetType === AssetGroup.LOCAL} ref={wrapperRef}>
                        {isLoading ? (
                            renderLoading()
                        ) : (
                            <AssetsPicker
                                type={type}
                                selectedItem={selectedLocalAsset}
                                assetType={assetType}
                                collectionId={collectionId}
                                filterTypes={[type.toUpperCase()]}
                                compactViewProps={compactViewProps}
                                isPersonalUploadsAllowed={isPersonalUploadsAllowed}
                                onSelectLocal={onLocalAssetClick}
                            />
                        )}
                    </StyledModalBasedContent>
                ) : (
                    <FullHeightWrapper ref={wrapperRef}>
                        {isFetchingAsset && renderLoading()}
                        {!isFetchingAsset && !isPreviewAccessible && (
                            <PortionSelectorLoader type={type} processProgress={processProgress} />
                        )}
                        {!isFetchingAsset && isPreviewAccessible && (
                            <PortionSelector
                                type={type}
                                frameRate={frameRate}
                                url={assetSourceData?.url}
                                duration={assetSourceData?.duration}
                                elementId={selectedObject?.element?.id}
                                elementDuration={framesToMilliseconds(duration, frameRate)}
                                offsetTime={offsetTime}
                                handleOnChange={onSelectVideoSegment}
                                resizableRange={resizableRange}
                                isEditorPage={isEditorPage}
                            />
                        )}
                    </FullHeightWrapper>
                )}
            </ModalBase.Content>

            {((assetType === AssetGroup.LOCAL && selectedLocalAsset) || isTrimMode) && (
                <ModalBase.Footer
                    actionPrimary={null}
                    actionSecondary={
                        <Button
                            variant="primary"
                            isDisabled={isTrimMode ? !isPreviewAccessible : false}
                            onClick={() => {
                                if (isTrimMode) {
                                    addAssetToArtboard(selectedAsset);

                                    return;
                                }

                                onSelectAsset(selectedLocalAsset);
                            }}
                        >
                            {isTrimMode
                                ? translate('modal.assetsPicker.button.trim', { type })
                                : translate('modal.assetsPicker.button.add_asset')}
                        </Button>
                    }
                />
            )}
            {renderValidationModal()}
        </ModalBase>
    );
};

export default memo(ContentPickerModal);
