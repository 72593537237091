import React, { useCallback, useEffect, useRef } from 'react';
import useForceUpdate from '~/hooks/useForceUpdate';
import usePlaybackShortcuts from 'packages/hooks/usePlaybackShortcuts';
import { useShotsShortcuts } from 'packages/hooks/useShotsShortcuts';
import { ZoomControl } from 'packages/common/ZoomControl';
import { DesignSceneProvider } from '../DesignSceneContext';
import TimelineShotContainer from './TimelineShotContainer';
import TimelineHeader from './TimelineHeader';
import { TimelineContainer, ZoomControlWrapper } from './Timeline.styled';
import { DesignTab } from 'packages/pages/design/sidebar/types';

type Props = {
    designType: 'TEMPLATE' | 'CREATIVE';
    shots: any[];
    selectedShotIndex: number;
    selectShot: (frameIndex: number, frame?: number) => void;
    toNextShot: () => void;
    toPrevShot: () => void;
    toggleToolbarAccess: (state: boolean) => void;
    canvasRenderer: any;
    duration: number;
    frameRate: number;
    setPausePlayback: () => void;
    setPlayPlayback: () => void;
    creativeModelFactory: any;
    creativeModel: any;
    setDesignTab: (tab: DesignTab) => void;
};

const ConfiguratorTimeline = ({
    designType,
    shots,
    selectedShotIndex,
    selectShot,
    toNextShot,
    toPrevShot,
    toggleToolbarAccess,
    canvasRenderer,
    duration,
    frameRate,
    setPausePlayback,
    setPlayPlayback,
    creativeModelFactory,
    creativeModel,
    className,
    setDesignTab,
    hideZoomControl = false,
}: Props) => {
    const forceUpdate = useForceUpdate();
    const timelineWrapperRef = useRef<HTMLDivElement | null>(null);
    const durationRef = useRef(0);
    const frame = useRef(0);
    useShotsShortcuts({ toPrevShot, toNextShot });
    usePlaybackShortcuts({
        renderer: canvasRenderer,
        editorType: 'CC',
        duration: durationRef.current,
    });

    const isPlaying = canvasRenderer?.getIsPlaying() || false;

    const selectFrame = useCallback(
        (frameIndex) => {
            frame.current = frameIndex;

            if (isPlaying) {
                setPausePlayback();
                canvasRenderer.setCurrentFrame(frameIndex);
                setPlayPlayback();
            } else {
                canvasRenderer.setCurrentFrame(frameIndex);
            }

            forceUpdate();
        },
        [canvasRenderer, forceUpdate, isPlaying],
    );

    const isLastFrameCurrent = frame.current === duration - 1;

    const togglePlayback = useCallback(() => {
        if (!isPlaying) {
            if (isLastFrameCurrent) {
                selectShot(0);
            }

            canvasRenderer.startPlayback();
        } else {
            canvasRenderer.pausePlayback();
        }
    }, [canvasRenderer, isPlaying, isLastFrameCurrent]);

    useEffect(() => {
        if (selectedShotIndex >= shots.length) {
            selectShot(shots.length - 1);
        }
    }, [shots.length, selectedShotIndex]);

    const handleZoomControlChange = useCallback(({ left, width, zoom }) => {
        if (!timelineWrapperRef.current) {
            return;
        }

        const maxLeftPercent = 100 - width;
        const l = ((zoom - 100) / maxLeftPercent) * left || 0;

        timelineWrapperRef.current.style.width = `${zoom}%`;
        timelineWrapperRef.current.style.left = `-${l}%`;
    }, []);

    useEffect(() => {
        if (!canvasRenderer || !durationRef.current) {
            return;
        }

        const newDuration = canvasRenderer.getPlaybackDuration().getDuration();
        const oldDuration = durationRef.current;
        const frameIndex = Math.round(canvasRenderer.getCurrentFrame() * (newDuration / oldDuration));
        durationRef.current = newDuration;

        if (frame.current !== frameIndex) {
            selectFrame(frameIndex);
        }
    }, [canvasRenderer?.getPlaybackDuration()?.getDuration()]);

    useEffect(() => {
        if (!canvasRenderer) {
            return;
        }

        canvasRenderer.eventEmitter.on('playing', () => {
            toggleToolbarAccess(true);
            forceUpdate();
        });

        canvasRenderer.eventEmitter.on('ended', () => {
            toggleToolbarAccess(false);
            forceUpdate();
        });

        canvasRenderer.eventEmitter.on('paused', () => {
            toggleToolbarAccess(false);
            forceUpdate();
        });

        canvasRenderer.eventEmitter.on('frameUpdate', (frameIndex) => {
            frame.current = frameIndex;
            forceUpdate();
        });

        durationRef.current = canvasRenderer.getPlaybackDuration().getDuration();
    }, [canvasRenderer, forceUpdate]);

    return (
        <TimelineContainer className={`d-flex ${className}`}>
            <TimelineHeader
                designType={designType}
                creativeModel={creativeModel}
                isPlaying={isPlaying}
                togglePlayback={togglePlayback}
                currentFrame={frame.current}
                selectedShotIndex={selectedShotIndex}
                shots={shots}
                duration={duration}
                frameRate={frameRate}
                toNextShot={toNextShot}
                toPrevShot={toPrevShot}
                setDesignTab={setDesignTab}
            />
            <DesignSceneProvider creativeModelFactory={creativeModelFactory} creativeModel={creativeModel}>
                <TimelineShotContainer
                    isPlaying={isPlaying}
                    currentFrame={frame.current}
                    selectFrame={selectFrame}
                    shots={shots}
                    selectedShotIndex={selectedShotIndex}
                    selectShot={selectShot}
                    timelineWrapperRef={timelineWrapperRef}
                />
            </DesignSceneProvider>
            {!hideZoomControl && (
                <ZoomControlWrapper>
                    <ZoomControl onChange={handleZoomControlChange} duration={duration} />
                </ZoomControlWrapper>
            )}
        </TimelineContainer>
    );
};

export default React.memo(ConfiguratorTimeline);
