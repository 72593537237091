import { useCallback, useSyncExternalStore } from 'react';
import { IMultiPageImageModel, IMultiPageVideoModel } from '@bynder-studio/render-core';

const noop: () => void = () => null;

export default function useCurrentPage(
    creativeModel: IMultiPageVideoModel | IMultiPageImageModel | null | undefined,
    onChange = noop,
) {
    const subscribe = useCallback(
        (listener: () => void) => {
            const cb = () => {
                listener();
                onChange();
            };

            creativeModel?.on('currentPageChange', cb);

            return () => creativeModel?.off('currentPageChange', cb);
        },
        [creativeModel, onChange],
    );

    const pageIdxSelector = useCallback(() => creativeModel?.getCurrentPageIndex() || 0, [creativeModel]);
    const pageIdSelector = useCallback(() => creativeModel?.getCurrentPageId() || '', [creativeModel]);

    const pageIdx = useSyncExternalStore(subscribe, pageIdxSelector);
    const pageId = useSyncExternalStore(subscribe, pageIdSelector);

    const selectPageByIdx = useCallback(
        (idx: number) => {
            creativeModel?.setCurrentPageIndex(idx);
        },
        [creativeModel],
    );

    return { pageIdx, pageId, selectPageByIdx };
}
