import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getReviewState } from 'packages/store/review/review.selectors';
import useAccessRights from 'packages/hooks/useAccessRights';
import useDesign from 'packages/pages/design/hooks/useDesign';
import { ReviewStatus } from 'packages/store/review/types';
import { ActionStatus, Entity, DesignAction, TemplateAction } from 'packages/hooks/useAccessRights/types';
import { getCreative } from 'packages/store/creatives/creatives.selectors';
import features from '~/configs/features';

export const useReviewStatus = () => {
    const { template } = useDesign();
    const reviewState = useSelector(getReviewState);
    const currentCreative = useSelector(getCreative);
    const { isEntityActionAllowed, isPlatformAllowed } = useAccessRights();

    const grantedPermissions = useMemo(
        () => reviewState?.entityGrantedPermissions || currentCreative?.grantedPermissions,
        [reviewState?.entityGrantedPermissions, currentCreative?.grantedPermissions],
    );

    const status = useMemo(
        () => reviewState?.status || template?.approvalState?.status || null,
        [reviewState?.status, template?.approvalState?.status],
    );

    const isReviewer = useMemo(
        () =>
            isPlatformAllowed([features.APPROVALS_ENABLED]) &&
            isEntityActionAllowed(DesignAction.ADD_REVIEW, Entity.DESIGN, grantedPermissions) === ActionStatus.ALLOWED,
        [isPlatformAllowed, isEntityActionAllowed, grantedPermissions],
    );

    const isInReviewMode = status === ReviewStatus.IN_REVIEW;

    const isEditsRequestedMode = status === ReviewStatus.EDITS_REQUESTED;

    const isInApprovedMode = status === ReviewStatus.APPROVED;

    const isCollaborator = useMemo(
        () =>
            isEntityActionAllowed(TemplateAction.REQUEST_REVIEW, Entity.TEMPLATE, grantedPermissions) ===
                ActionStatus.ALLOWED ||
            isEntityActionAllowed(DesignAction.REQUEST_REVIEW, Entity.DESIGN, grantedPermissions) ===
                ActionStatus.ALLOWED,
        [grantedPermissions],
    );

    const isReviewerAndInReview = useMemo(() => isInReviewMode && isReviewer, [status, isReviewer]);

    const isDisabledByReview = useMemo(
        () =>
            (status === ReviewStatus.IN_REVIEW && !isReviewer) ||
            (status === ReviewStatus.EDITS_REQUESTED && !isCollaborator) ||
            status === ReviewStatus.APPROVED,
        [status, isReviewer, isCollaborator],
    );

    return {
        status,
        isReviewer,
        isCollaborator,
        isInReviewMode,
        isInApprovedMode,
        isDisabledByReview,
        isEditsRequestedMode,
        isReviewerAndInReview,
    };
};
