import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Divider, Form, List, Select, Thumbnail } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { IconAdjust, IconArrowDown, IconArrowUp, IconVolumeUp } from '@bynder/icons';
import { ImageElement, VideoElement } from '@bynder-studio/render-core';
import features from '~/configs/features';
import useVariations from 'packages/pages/design/hooks/useVariations';
import BrowseButton from 'packages/pages/editor/RightSideMenu/FormComponents/BrowseButton';
import Scale from 'packages/pages/design/components/Scale';
import HorizontalOffset from 'packages/pages/design/components/HorizontalOffset';
import VerticalOffset from 'packages/pages/design/components/VerticalOffset';
import HorizontalAlignment from 'packages/pages/design/components/HorizontalAlignment';
import VerticalAlignment from 'packages/pages/design/components/VerticalAlignment';
import useElement from 'packages/pages/design/hooks/useElement';
import useDesign from 'packages/pages/design/hooks/useDesign';
import useAudioHook from 'packages/hooks/useElementAudio';
import useAccessRights from 'packages/hooks/useAccessRights';
import { flattenTree } from '~/common/editor/helpers/elementtree';
import { elementIsVideo, getElementPropertiesFromSource, getFreeCropModeParams } from '~/common/editor/helpers';
import { ContentType } from 'packages/pages/editor/RightSideMenu/FormComponents/BrowseButton/types';
import { AudioFeature } from 'packages/pages/editor/RightSideMenu/FormComponents/AudioFeature';

const cropModeOptions = [
    { value: 'CONTAIN', label: 'Contain' },
    { value: 'COVER', label: 'Cover' },
    { value: 'COVER_WIDTH', label: 'Width' },
    { value: 'COVER_HEIGHT', label: 'Height' },
    { value: 'FREE', label: 'Free' },
];

type Props = {
    id: number;
    disabled: boolean;
    type: string;
    isLoading: boolean;
};

const ImageVideoElement = ({ id, disabled, type, isLoading }: Props) => {
    const { translate } = useTranslate();
    const { isPlatformAllowed } = useAccessRights();
    const { frameRate, creativeType, recalculateShots, creativeModel, template } = useDesign();
    const { key, element, updateElement, syncContentProperty } = useElement<VideoElement | ImageElement>(id);
    const { updateVariationElement, removeDeletedAsset } = useVariations();
    const [cropMode, setCropMode] = useState(element?.contentTransform?.type || 'CONTAIN');
    const [isExpanded, setIsExpanded] = useState(false);

    const currentPageIndex = useMemo(() => creativeModel.getCurrentPageIndex(), [creativeModel?.index]);

    const updateFn = useCallback(
        (params) => {
            element?.contentPropertyId ? syncContentProperty(params) : updateElement(params);
        },
        [element?.contentPropertyId, syncContentProperty, updateElement],
    );

    const { useAudio, fileName, gain, fadeIn, fadeOut, setUseAudio, setGain, setFadeInOut } = useAudioHook({
        audio: element as VideoElement,
        onAudioChange: updateFn,
    });

    const handleChangeCropMode = useCallback(
        (newValue) => {
            if (!element) {
                return;
            }

            setCropMode(newValue);
            const param = {
                contentTransform: {
                    ...element.contentTransform,
                    type: newValue,
                    ...(newValue === 'FREE' ? getFreeCropModeParams(element) : {}),
                },
            };
            updateElement(param);
        },
        [element?.contentTransform, element, updateElement],
    );

    const isDynamicLengthAvailable = useMemo(() => element?.useDynamicLength, [element]);

    const handleSelectSource = useCallback(
        (source) => {
            const fieldsToChange = getElementPropertiesFromSource(element, creativeType, source);
            const { duration } = fieldsToChange;
            delete fieldsToChange.duration;

            updateFn(fieldsToChange);

            if (duration && isDynamicLengthAvailable) {
                recalculateShots([{ elementId: element.id, duration }]).then((elements) => {
                    if (!elements.length) {
                        return;
                    }

                    const models = creativeModel.getModels();
                    const modelsMetaData = creativeModel.getModelsMetaData();

                    models.forEach((model, i) => {
                        model.updateElements(elements);
                        const sizeId = modelsMetaData[i]?.id;
                        elements.forEach((item) => {
                            updateVariationElement(sizeId, item.id, item);
                        });
                    });
                });
            }

            removeDeletedAsset(id);
        },
        [
            updateFn,
            element,
            updateElement,
            syncContentProperty,
            creativeType,
            creativeModel,
            updateVariationElement,
            removeDeletedAsset,
        ],
    );

    const collectionId = useMemo(() => {
        const desiredElement: any = Object.values(flattenTree(template.pages[currentPageIndex].elements)).find(
            (el: any) => el.id === id,
        );

        if (!desiredElement) {
            return null;
        }

        return desiredElement.properties.value?.bynderCollectionId;
    }, [template.pages, currentPageIndex, id]);

    const isVideoElement = useMemo(() => elementIsVideo(type), [type]);

    useEffect(() => {
        setCropMode(element?.contentTransform?.type || 'CONTAIN');
    }, [key, element?.contentTransform]);

    return (
        <>
            {!isVideoElement && (
                <Form.Group>
                    <BrowseButton
                        id={id.toString()}
                        htmlFor={id.toString()}
                        label={translate('design.sidebar.scene.image.asset.label')}
                        placeholder={translate('design.sidebar.scene.image.input.placeholder')}
                        type={type as ContentType}
                        frameRate={frameRate}
                        value={fileName}
                        selectedObject={{ element }}
                        disabled={disabled || isLoading}
                        loading={isLoading}
                        resizableRange
                        previewURL={element?.src || ''}
                        collectionId={collectionId}
                        locked={false}
                        isAlpha={false}
                        isEditorPage={false}
                        testId="design_scene_image_asset"
                        handleSelectSource={handleSelectSource}
                    />
                </Form.Group>
            )}
            {isVideoElement && element instanceof VideoElement && (
                <Form.Group>
                    <BrowseButton
                        id={id.toString()}
                        htmlFor={id.toString()}
                        label={translate('design.sidebar.scene.video.asset.label')}
                        placeholder={translate('design.sidebar.scene.video.input.placeholder')}
                        type={type as ContentType}
                        frameRate={frameRate}
                        value={fileName}
                        selectedObject={{ element }}
                        disabled={disabled || isLoading}
                        locked={false}
                        loading={isLoading}
                        resizableRange={isDynamicLengthAvailable}
                        previewURL={element?.src || ''}
                        isAlpha={element?.isAlpha}
                        collectionId={collectionId}
                        isEditorPage={false}
                        testId="design_scene_video_asset"
                        handleSelectSource={handleSelectSource}
                    />
                </Form.Group>
            )}
            <List.Item
                thumbnail={<Thumbnail icon={<IconAdjust />} />}
                rightElements={isExpanded ? <IconArrowUp /> : <IconArrowDown />}
                onClick={() => setIsExpanded(!isExpanded)}
            >
                {translate('design.sidebar.scene.options.expand.label', { type: type.toLowerCase() })}
            </List.Item>
            {isExpanded && (
                <Box paddingBlock="3">
                    <Form.Group>
                        <Form.Label id="scene_crop_mode">
                            {translate('design.sidebar.scene.options.crop-mode.label')}
                        </Form.Label>
                        <Select
                            value={cropMode}
                            onChange={handleChangeCropMode}
                            label={cropModeOptions.find((i) => i.value === cropMode)?.label}
                            aria-labelledby="scene_crop_mode"
                            isDisabled={disabled}
                        >
                            {cropModeOptions.map((item) => (
                                <Select.Item value={item.value} key={item.value}>
                                    {item.label}
                                </Select.Item>
                            ))}
                        </Select>
                    </Form.Group>
                    {cropMode === 'FREE' ? (
                        <>
                            <Scale id={id} disabled={disabled} />
                            <HorizontalOffset id={id} disabled={disabled} />
                            <VerticalOffset id={id} disabled={disabled} />
                        </>
                    ) : (
                        <>
                            <HorizontalAlignment id={id} disabled={disabled} />
                            <VerticalAlignment id={id} disabled={disabled} />
                        </>
                    )}
                </Box>
            )}
            {isVideoElement && isPlatformAllowed([features.MULTI_TRACK_AUDIO]) && (
                <>
                    <Divider />
                    <Form.Group>
                        <List.Item
                            thumbnail={<Thumbnail icon={<IconVolumeUp />} />}
                            checkedVariant="switch"
                            onClick={() => {
                                setUseAudio(!useAudio);
                            }}
                            isChecked={useAudio}
                            isDisabled={disabled}
                        >
                            {translate('design.sidebar.scene.options.crop-mode.use-audio.label')}
                        </List.Item>
                        {useAudio && (
                            <AudioFeature
                                gain={gain}
                                fadeIn={fadeIn}
                                fadeOut={fadeOut}
                                onFadeInChange={setFadeInOut('fadeIn')}
                                onFadeOutChange={setFadeInOut('fadeOut')}
                                onGainChange={setGain}
                            />
                        )}
                    </Form.Group>
                </>
            )}
        </>
    );
};

export default memo(ImageVideoElement);
