export const isImageOrVideo = (type) => ['image', 'video'].includes(type.toLowerCase());
export const elementIsVideo = (type) => ['video'].includes(type.toLowerCase());
export const elementIsImage = (type) => ['image'].includes(type.toLowerCase());

export const getElementPropertiesFromSource = (element, mediaType, source) => {
    const { width: naturalWidth, height: naturalHeight } = element.naturalDimension;
    const { type: cropMode, scale, horizontalOffset, verticalOffset } = element.contentTransform;
    let newScale;
    const {
        name: fileName,
        offsetTime,
        id: srcId,
        type: srcType = 'ASSET_MANAGER',
        src,
        duration,
        width,
        height,
        videoPreviewUrl,
        isAlpha,
        externalId,
    } = source;

    if (cropMode === 'FREE') {
        newScale = Number(
            (naturalWidth > naturalHeight ? (naturalWidth / width) * scale : (naturalHeight / height) * scale).toFixed(
                2,
            ),
        );
        newScale = newScale > 2 ? 2 : newScale <= 0 ? 0 : newScale;
    }

    const fieldsToChange = {
        fileName,
        offsetTime,
        src: videoPreviewUrl || src,
        srcId,
        srcType,
        externalId,
        naturalDimension: {
            width,
            height,
        },
    };

    if (mediaType === 'VIDEO') {
        fieldsToChange.duration = duration;
        fieldsToChange.isAlpha = isAlpha;
    }

    if (cropMode === 'FREE') {
        fieldsToChange.contentTransform = {
            type: 'FREE',
            scale: newScale,
            horizontalOffset: horizontalOffset - Math.round((width * newScale - naturalWidth * scale) / 2),
            verticalOffset: verticalOffset - Math.round((height * newScale - naturalHeight * scale) / 2),
        };
    }

    return fieldsToChange;
};

export const getFreeCropModeParams = (element) => {
    const { naturalDimension, dimension } = element;
    const { width: naturalWidth, height: naturalHeight } = naturalDimension;
    const { width, height } = dimension;
    const scale = Number(Math.min(width / naturalWidth, height / naturalHeight, 1).toFixed(2));
    const horizontalOffset = Math.round(width / 2 - (naturalWidth * scale) / 2);
    const verticalOffset = Math.round(height / 2 - (naturalHeight * scale) / 2);

    return {
        scale,
        horizontalOffset,
        verticalOffset,
    };
};

export const getScaleValueFromElement = (element) => {
    const { naturalDimension, dimension, contentTransform } = element;
    const { width: naturalWidth, height: naturalHeight } = naturalDimension;
    const { width, height } = dimension;
    const { scale = Number(Math.min(width / naturalWidth, height / naturalHeight, 1).toFixed(2)) } =
        contentTransform || {};

    return scale;
};

export const getHorizontalOffsetValueFromElement = (element) => {
    const { naturalDimension, dimension, contentTransform } = element;
    const { width: naturalWidth } = naturalDimension;
    const { width } = dimension;
    const scale = getScaleValueFromElement(element);
    const { horizontalOffset = Math.round(width / 2 - (naturalWidth * scale) / 2) } = contentTransform || {};

    return horizontalOffset;
};

export const getVerticalOffsetValueFromElement = (element) => {
    const { naturalDimension, dimension, contentTransform } = element;
    const { height: naturalHeight } = naturalDimension;
    const { height } = dimension;
    const scale = getScaleValueFromElement(element);
    const { verticalOffset = Math.round(height / 2 - (naturalHeight * scale) / 2) } = contentTransform || {};

    return verticalOffset;
};

const DROP_SHADOW_DEFAULT_VALUE = {
    offset: 5,
    angle: 140,
    blurRadius: 5,
    spreadRadius: null,
    color: { red: 0, green: 0, blue: 0, opacity: 0.25, brandColorId: null },
    state: 'DISABLE',
};

export const getDropShadowValueFromElement = (element) => {
    const dropShadowValue = element.dropShadow || DROP_SHADOW_DEFAULT_VALUE;
    const { color } = dropShadowValue;

    return {
        ...dropShadowValue,
        color,
    };
};

export const MAX_ELEMENT_NAME_LENGTH = 128;
