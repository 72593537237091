import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IconCancel } from '@bynder/icons';
import { Tabs, Inspector, Button } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import CreativeTypes from '~/helpers/CreativeTypes';
import features from '~/configs/features';
import generateTestId from '~/helpers/testIdHelpers';
import useAccessRights from 'packages/hooks/useAccessRights';
import { useReviewStatus } from 'packages/pages/design/sidebar/shots/useReviewStatus';
import ShotAudio from 'packages/pages/design/sidebar/shots/ShotAudio';
import Variations from './variations/Variations';
import ResetPage from './variations/ResetPage';
import { DesignTab } from './types';

type Props = {
    children: React.ReactNode;
    designTab: DesignTab;
    setDesignTab: (tab: DesignTab) => void;
};

const Sidebar = ({ children, designTab, setDesignTab }: Props) => {
    const { translate } = useTranslate();
    const { isPlatformAllowed } = useAccessRights();
    const { isReviewer, isInReviewMode, isEditsRequestedMode, isCollaborator, isInApprovedMode } = useReviewStatus();
    const { creativeType } = useSelector((state) => ({
        creativeType: state.creatives.creative.creativeType,
    }));

    const isMultiVariationAvailable = useMemo(() => isPlatformAllowed([features.MULTI_VARIATION]), [isPlatformAllowed]);

    const showResetButton = useMemo(() => {
        if (designTab !== DesignTab.SCENE) {
            return false;
        }

        if (isReviewer) {
            return isCollaborator && !isInApprovedMode && !isInReviewMode;
        }

        return !isInApprovedMode;
    }, [designTab, isReviewer, isCollaborator, isEditsRequestedMode, isInApprovedMode, isInReviewMode]);

    return (
        <Inspector {...generateTestId('design_configuration_sidebar')}>
            {isMultiVariationAvailable && designTab !== DesignTab.AUDIO && (
                <Inspector.Tabs>
                    <Tabs>
                        <>
                            <Tabs.Tab
                                label={
                                    creativeType === CreativeTypes.Video
                                        ? translate('design.sidebar.scene')
                                        : translate('design.sidebar.image')
                                }
                                isActive={designTab === DesignTab.SCENE}
                                onClick={() => {
                                    setDesignTab(DesignTab.SCENE);
                                }}
                            />
                            <Tabs.Tab
                                label={translate('design.sidebar.variations')}
                                isActive={designTab === DesignTab.VARIATIONS}
                                onClick={() => {
                                    setDesignTab(DesignTab.VARIATIONS);
                                }}
                            />
                        </>
                    </Tabs>
                </Inspector.Tabs>
            )}

            {designTab === DesignTab.AUDIO && (
                <>
                    <Inspector.Header
                        title={translate('design.sidebar.audio')}
                        rightElements={
                            <Button
                                variant="clean"
                                icon={<IconCancel />}
                                onClick={() => {
                                    setDesignTab(DesignTab.SCENE);
                                }}
                            />
                        }
                    />
                    <Inspector.Divider />
                </>
            )}

            <Inspector.Content>
                {designTab === DesignTab.SCENE ? (
                    <>{children}</>
                ) : designTab === DesignTab.AUDIO ? (
                    <>
                        <ShotAudio track={1} />
                        <ShotAudio track={2} />
                    </>
                ) : (
                    <Variations />
                )}
            </Inspector.Content>
            {showResetButton && <ResetPage />}
        </Inspector>
    );
};

export default Sidebar;
