import { AssetType } from 'packages/pages/components/Uploader/types';

export enum AssetGroup {
    GLOBAL = 'GLOBAL',
    LOCAL = 'LOCAL',
}

export enum CONTENT_MODAL_STEPS {
    CHOICE = 'CHOICE',
    TRIM = 'TRIM',
}

export type ContentSelectorModalProps = {
    type: AssetType;
    confirmSelection: (chosenAsset: any) => void;
    show: boolean;
    toggleModal: (isOpen: boolean) => void;
    selectedObject: any;
    frameRate: number;
    collectionId?: number;
    isLoading?: boolean;
    isEditorPage?: boolean;
    resizableRange?: boolean;
    modalStep?: CONTENT_MODAL_STEPS;
};
