import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslate } from '@bynder/localization';
import { Form, List } from '@bynder/design-system';
import IdentitiesSearch from 'packages/pages/shareModal/components/IdentitiesSearch';
import { IdentityType } from 'packages/store/sharing/types';
import useAccessRights from 'packages/hooks/useAccessRights';
import generateTestId from '~/helpers/testIdHelpers';
import useEditor from '~/hooks/useEditor';
import { markAsUnsaved } from 'packages/store/creativeEditor/creativeEditor.actions';
import features from '~/configs/features';
import roles from '~/configs/roles';
import { ReviewersList } from './items';
import { FormSectionContent } from '../../index.styled';
import { StyledSwitch, StyledFormLabel, Box } from './index.styled';

const RequestApproval = ({ disabled }) => {
    const { reviewers, removeReviewer, addReviewer, setApprovalEnabled, approvalEnabled } = useEditor();
    const [, setReviewers] = useState(reviewers);
    const dispatch = useDispatch();
    const { translate } = useTranslate();
    const [isActive, setIsActive] = useState(approvalEnabled);

    const { isPlatformAllowed, isAccountRoleAssigned } = useAccessRights();
    const isTrialAccount =
        isPlatformAllowed([features.TRIAL_ENABLED]) && isAccountRoleAssigned(roles.video_brand_studio.video.manage);

    const onToggle = () => {
        setIsActive(!isActive);
        setApprovalEnabled(!isActive);
        dispatch(markAsUnsaved());
    };

    const onAddReviewer = (reviewer) => {
        addReviewer(reviewer);
        dispatch(markAsUnsaved());
    };

    const onRemoveReviewer = (reviewer) => {
        removeReviewer(reviewer);
        dispatch(markAsUnsaved());
    };

    const testId = generateTestId('globals_request_approval');
    const testIdSwitch = generateTestId('globals_request_approval_toggle');

    useEffect(() => {
        if (reviewers) {
            setReviewers(reviewers);
        }
    }, [reviewers]);

    return (
        <FormSectionContent onSubmit={(event) => event.preventDefault()}>
            <Form.Group {...testId}>
                <StyledFormLabel htmlFor="globals_request_approval">
                    {translate('editor.sidebar.globals.requestApproval.title')}
                </StyledFormLabel>
                <StyledSwitch
                    inputProps={{ id: 'globals_request_approval', ...testIdSwitch }}
                    onChange={onToggle}
                    isChecked={isActive}
                    isDisabled={disabled}
                >
                    {translate('editor.sidebar.globals.requestApproval.subtext')}
                </StyledSwitch>
                {isActive && (
                    <>
                        <Form.Group>
                            <IdentitiesSearch
                                existingIdentities={[
                                    ...reviewers.map((item) => {
                                        if (item.securityIdentityType === IdentityType.USER) {
                                            return item.bynderUserId;
                                        }

                                        if (item.securityIdentityType === IdentityType.GROUP) {
                                            return item.groupId;
                                        }

                                        if (item.securityIdentityType === IdentityType.PROFILE) {
                                            return item.profileId;
                                        }

                                        return null;
                                    }),
                                ]}
                                onIdentitySelect={onAddReviewer}
                                placeholder={translate('editor.sidebar.globals.requestApproval.search.placeholder')}
                                isDisabled={isTrialAccount}
                            />
                        </Form.Group>
                        <List>
                            {reviewers.length > 0 && (
                                <StyledFormLabel htmlFor="globals_request_approval_label">
                                    {translate('editor.sidebar.globals.requestApproval.reviewers.label')}
                                </StyledFormLabel>
                            )}
                            <Box>
                                <ReviewersList reviewers={reviewers} removeReviewer={onRemoveReviewer} />
                            </Box>
                        </List>
                    </>
                )}
            </Form.Group>
        </FormSectionContent>
    );
};

export default RequestApproval;
