import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Dropdown, InteractiveIcon, List, ListItem, Modal, Thumbnail } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import {
    AudioElement,
    ElementTypes,
    type MultiPageVideoModel,
    type UpdateGlobalPropertyOptions,
} from '@bynder-studio/render-core';
import modalContainer from 'packages/common/modalContainer';
import useAccessRights from 'packages/hooks/useAccessRights';
import features from '~/configs/features';
import { decimal } from 'packages/pages/utils';
import {
    IconCut,
    IconDelete,
    IconLockOpenSmall,
    IconLockSmall,
    IconMore,
    IconReplace,
    IconSound,
    IconSoundOff,
} from '@bynder/icons';
import ContentPickerModal from 'packages/pages/editor/ContentPickerModal';
import { CONTENT_MODAL_STEPS } from 'packages/pages/editor/ContentPickerModal/types';
import useGlobalAudio from 'packages/hooks/useGlobalAudio';
import generateTestId from '~/helpers/testIdHelpers';
import { ContentType } from '../../FormComponents/BrowseButton/types';
import { AudioFeature } from '../../FormComponents/AudioFeature';
import { FormSectionContent } from '../../index.styled';

type GlobalAudioProps = {
    creativeModel: MultiPageVideoModel;
    frameRate: number;
    disabled: boolean;
    track: 1 | 2;
    configuratorMode?: boolean;
    onAudioChange?: (params: Partial<AudioElement>, options: UpdateGlobalPropertyOptions) => void;
};

const formatTime = (time: number) => {
    // const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
};

const EMPTY_AUDIO_PROPS = {
    src: null,
    srcType: null,
    srcId: null,
    fileName: '',
    offsetTime: 0,
};

const GlobalAudio: FC<GlobalAudioProps> = ({
    creativeModel,
    frameRate,
    disabled,
    track,
    configuratorMode = false,
    onAudioChange = () => {},
}) => {
    const { translate } = useTranslate();
    const { isPlatformAllowed } = useAccessRights();
    const [showContentPickerModal, setShowContentPickerModal] = useState(false);
    const [contentPickerMode, setContentPickerMode] = useState<CONTENT_MODAL_STEPS>(CONTENT_MODAL_STEPS.CHOICE);
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const globalAudioTrack =
        track === 1 ? creativeModel?.getGlobalAudioTrack1() : creativeModel?.getGlobalAudioTrack2();
    const [isEnabled, setIsEnabled] = useState(configuratorMode || !!globalAudioTrack?.fileName);
    const updateOptions = useMemo<UpdateGlobalPropertyOptions>(() => ({ audioTrackNumber: track }), [track]);
    const lockTestId = useMemo(() => generateTestId(`global_audio_track_${track}__lock`), [track]);

    const duration = creativeModel?.getPlaybackDuration()?.getDuration();
    const audioFrameRate = creativeModel?.getPlaybackDuration()?.getFrameRate() || frameRate;

    const updateGlobalAudio = useCallback(
        (params: Partial<AudioElement>) => {
            onAudioChange(params, updateOptions);
            creativeModel.updateGlobalProperty(ElementTypes.GLOBAL_AUDIO, params, updateOptions);
        },
        [creativeModel, updateOptions, onAudioChange],
    );

    const { gain, locked, fadeIn, fileName, offsetTime, fadeOut, setLocked, setGain, setFadeInOut } = useGlobalAudio({
        audio: globalAudioTrack,
        onAudioChange: updateGlobalAudio,
    });

    useEffect(() => {
        setIsEnabled(configuratorMode || !!globalAudioTrack?.fileName);
    }, [contentPickerMode, globalAudioTrack]);

    const startAt = useMemo(() => formatTime(offsetTime / 1000), [offsetTime]);
    const endAt = useMemo(() => {
        const durationSeconds = decimal(duration / audioFrameRate);
        return formatTime(durationSeconds + offsetTime / 1000);
    }, [offsetTime, duration, audioFrameRate]);

    const handleSelectSource = useCallback(
        (data = {}) => {
            let params: Partial<AudioElement> = EMPTY_AUDIO_PROPS;
            const { source, offsetTime, url, name } = data;

            if (source) {
                params = {
                    src: url,
                    srcType: 'ASSET_MANAGER',
                    srcId: source,
                    fileName: name,
                    offsetTime,
                };
            }

            updateGlobalAudio(params);
            setShowContentPickerModal(false);
        },
        [updateOptions, creativeModel],
    );

    const toggleAudio = () => {
        if (locked) {
            return;
        }

        if (!isEnabled) {
            setIsEnabled(true);
        } else {
            setIsEnabled(false);
            updateGlobalAudio({
                ...EMPTY_AUDIO_PROPS,
                locked: false,
                gain: 0,
                fadeIn: 0,
                fadeOut: 0,
            });
        }
    };

    const confirmRemove = () => {
        handleSelectSource();
        setShowRemoveModal(false);
    };

    const openContentPickerModal = (mode: CONTENT_MODAL_STEPS) => () => {
        setContentPickerMode(mode);
        setShowContentPickerModal(true);
    };

    return (
        <FormSectionContent onSubmit={(event) => event.preventDefault()}>
            <Modal
                container={modalContainer}
                isOpen={showRemoveModal}
                title={translate('editor.sidebar.globals.audio.modal.title', { track })}
                onClose={() => setShowRemoveModal(false)}
                actionPrimary={
                    <Button variant="primary" onClick={confirmRemove}>
                        {translate('editor.sidebar.globals.audio.modal.footer')}
                    </Button>
                }
                actionSecondary={
                    <Button variant="secondary" onClick={() => setShowRemoveModal(false)}>
                        {translate('editor.sidebar.globals.audio.modal.cancel')}
                    </Button>
                }
            >
                {translate('editor.sidebar.globals.audio.modal.text')}
            </Modal>
            {showContentPickerModal && (
                <ContentPickerModal
                    type={ContentType.AUDIO}
                    show={showContentPickerModal}
                    modalStep={contentPickerMode}
                    frameRate={frameRate}
                    selectedObject={{ element: globalAudioTrack, duration }}
                    toggleModal={setShowContentPickerModal}
                    confirmSelection={handleSelectSource}
                    resizableRange={false}
                    isEditorPage
                />
            )}
            <ListItem
                checkedVariant={configuratorMode ? undefined : 'switch'}
                isChecked={configuratorMode ? undefined : isEnabled}
                onClick={configuratorMode ? undefined : toggleAudio}
                switchProps={configuratorMode ? undefined : { onChange: toggleAudio, isDisabled: locked }}
                rightElements={
                    !configuratorMode && (
                        <>
                            {
                                <InteractiveIcon
                                    onClick={() => {
                                        setLocked(!locked);
                                    }}
                                    icon={locked ? <IconLockSmall /> : <IconLockOpenSmall />}
                                    label={
                                        locked
                                            ? translate('editor.sidebar.globals.audio.unlock')
                                            : translate('editor.sidebar.globals.audio.lock')
                                    }
                                    {...lockTestId}
                                />
                            }
                        </>
                    )
                }
            >
                {translate('editor.sidebar.globals.audio.title', { track })}
            </ListItem>
            {isEnabled && (
                <>
                    <List.Item
                        thumbnail={<Thumbnail size="m" icon={fileName ? <IconSound /> : <IconSoundOff />} />}
                        subtext={fileName && `${startAt} — ${endAt}`}
                        rightElements={
                            <Dropdown
                                trigger={({ isOpen: isDropdownOpen, ...triggerProps }) => (
                                    <Button
                                        isPressed={isDropdownOpen}
                                        isDisabled={disabled || locked}
                                        variant="clean"
                                        icon={<IconMore />}
                                        aria-label="Actions"
                                        {...triggerProps}
                                    />
                                )}
                                position="bottom-right"
                            >
                                {fileName ? (
                                    <>
                                        <Dropdown.Item
                                            onClick={openContentPickerModal(CONTENT_MODAL_STEPS.CHOICE)}
                                            icon={<IconReplace />}
                                        >
                                            {translate('editor.sidebar.globals.audio.replace')}
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={openContentPickerModal(CONTENT_MODAL_STEPS.TRIM)}
                                            icon={<IconCut />}
                                        >
                                            {translate('editor.sidebar.globals.audio.trim')}
                                        </Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item
                                            onClick={() => {
                                                setShowRemoveModal(true);
                                            }}
                                            icon={<IconDelete />}
                                        >
                                            {translate('editor.sidebar.globals.audio.delete')}
                                        </Dropdown.Item>
                                    </>
                                ) : (
                                    <Dropdown.Item
                                        onClick={openContentPickerModal(CONTENT_MODAL_STEPS.CHOICE)}
                                        icon={<IconSound />}
                                    >
                                        {translate('editor.sidebar.globals.audio.add')}
                                    </Dropdown.Item>
                                )}
                            </Dropdown>
                        }
                    >
                        {fileName || translate('editor.sidebar.globals.audio.empty')}
                    </List.Item>
                    {isPlatformAllowed([features.MULTI_TRACK_AUDIO]) && !!fileName && (
                        <AudioFeature
                            isDisabled={disabled || locked}
                            gain={gain}
                            fadeIn={fadeIn}
                            fadeOut={fadeOut}
                            onFadeInChange={setFadeInOut('fadeIn')}
                            onFadeOutChange={setFadeInOut('fadeOut')}
                            onGainChange={setGain}
                        />
                    )}
                </>
            )}
        </FormSectionContent>
    );
};

export default GlobalAudio;
