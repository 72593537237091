import {
    CORNERS,
    HandlerInteractionStates,
    InteractionStates,
    Position,
    ROTATIONS,
    SIDES,
} from '@bynder-studio/render-core';
import TransformerBox from './TransformerBox';
import BaseManipulationCompositor from '../BaseManipulationCompositor';
import HoverBox from './HoverBox';
import BoundingBox from './BoundingBox';

const HOVER_HANDLER_SIZE = 12;
const ORDINARY_HANDLER_SIZE = 10;
// we need to DS provide us color values to make it flexible
// now DS provides only variables (var(--variable-name)) in tokens
const ORDINARY_STROKE_COLOR = '#126dfe';
const INVALID_STROKE_COLOR = '#d54035';
const PRESSED_STROKE_COLOR = '#0d51bc';
const CONTENT_PROPERTY_COLOR = '#008762';
const MASK_COLOR = '#132034';
const MASK_HANDLER_COLOR = '#031026';
const syncIconWidth = 12;
const syncIconHeight = 16;
const syncIconMargin = 4;

class BoundingBoxManipulationCompositor extends BaseManipulationCompositor {
    private getColor(box: BoundingBox, isHandler = false, isPressed = false) {
        if (box.isInvalid) {
            return INVALID_STROKE_COLOR;
        }

        if (box.isUsedAsMask) {
            return isHandler ? MASK_HANDLER_COLOR : MASK_COLOR;
        }

        if (box.isContentProperty) {
            return CONTENT_PROPERTY_COLOR;
        }

        if (isPressed) {
            return PRESSED_STROKE_COLOR;
        }

        return ORDINARY_STROKE_COLOR;
    }

    // This is helper fn only for testing and debugging
    private drawInvisibleHandlers(transformerBox: TransformerBox) {
        this.ctx.save();
        this.ctx.translate(0, 0);
        this.ctx.globalAlpha = 1;
        this.ctx.lineWidth = this.devicePixelRatio * 2;

        transformerBox.selectionHandles.forEach((handleBox, index) => {
            if (SIDES[index]) {
                this.ctx.beginPath();
                this.ctx.rect(
                    this.s(handleBox.position.getX()),
                    this.s(handleBox.position.getY()),
                    this.s(handleBox.dimension.getWidth()),
                    this.s(handleBox.dimension.getHeight()),
                );
                this.ctx.fillStyle = '#e3fc3f';
                this.ctx.fill();
                this.ctx.closePath();
            }

            if (ROTATIONS[index]) {
                this.ctx.beginPath();
                this.ctx.rect(
                    this.s(handleBox.position.getX()),
                    this.s(handleBox.position.getY()),
                    this.s(handleBox.dimension.getWidth()),
                    this.s(handleBox.dimension.getHeight()),
                );
                this.ctx.fillStyle = '#fcc0c04d';
                this.ctx.fill();
                this.ctx.closePath();
            }

            if (CORNERS[index]) {
                this.ctx.beginPath();
                this.ctx.rect(
                    this.s(handleBox.position.getX()),
                    this.s(handleBox.position.getY()),
                    this.s(handleBox.dimension.getWidth()),
                    this.s(handleBox.dimension.getHeight()),
                );
                this.ctx.fillStyle = '#634cfc';
                this.ctx.fill();
                this.ctx.closePath();
            }
        });

        this.ctx.restore();
    }

    drawHoverBox(hoverBox: HoverBox) {
        this.ctx.save();
        this.ctx.globalAlpha = 1; // make sure box is fully visible
        this.ctx.beginPath();

        this.ctx.lineWidth = this.devicePixelRatio * 2;
        this.ctx.strokeStyle = this.getColor(hoverBox);

        this.applyTranslation(hoverBox.position.getX(), hoverBox.position.getY(), this.ctx);
        this.applyRotation(
            hoverBox.dimension.getWidth() / 2,
            hoverBox.dimension.getHeight() / 2,
            hoverBox.rotation,
            this.ctx,
        );
        this.ctx.rect(0, 0, this.s(hoverBox.dimension.getWidth()), this.s(hoverBox.dimension.getHeight()));
        this.ctx.stroke();
        this.ctx.closePath();
        this.ctx.restore();
    }

    drawSizeLabel(transformerBox: TransformerBox) {
        this.ctx.save();
        this.ctx.globalAlpha = 1; // make sure box is fully visible
        this.ctx.beginPath();

        const { isContentProperty } = transformerBox;
        const color = this.getColor(transformerBox);

        this.ctx.lineWidth = this.devicePixelRatio * 2;
        this.ctx.strokeStyle = color;
        this.applyTranslation(transformerBox.position.getX(), transformerBox.position.getY(), this.ctx);

        this.applyRotation(
            transformerBox.dimension.getWidth() / 2,
            transformerBox.dimension.getHeight() / 2,
            transformerBox.rotation,
            this.ctx,
        );

        const trWidth = transformerBox.dimension.getWidth();
        const trHeight = transformerBox.dimension.getHeight();

        this.ctx.beginPath();

        this.ctx.fillStyle = color;
        const fontSize = 12 * this.devicePixelRatio;
        this.ctx.font = `${fontSize}px Source Sans Pro`;
        const text = `${Math.trunc(trWidth)} × ${Math.trunc(trHeight)}`;
        const textWidth = this.ctx.measureText(text).width;
        const rectHeight = 24 * this.devicePixelRatio;
        const rectPadding = 16 * this.devicePixelRatio;
        let rectWidth = textWidth + rectPadding;

        if (isContentProperty) {
            rectWidth += (syncIconWidth + syncIconMargin) * this.devicePixelRatio;
        }

        const marginFromBox = 8 * this.devicePixelRatio;

        const getRotatedDelta = () => {
            if (transformerBox.rotation > 45 && transformerBox.rotation <= 135) {
                // right side
                const deltaX = this.s(trWidth) + marginFromBox + rectHeight / 2;
                const deltaY = this.s(trHeight) / 2;

                return { deltaY, deltaX, sizeLabelAngle: -90 };
            }

            if (transformerBox.rotation > 135 && transformerBox.rotation <= 225) {
                // upper side
                const deltaX = this.s(trWidth) / 2;
                const deltaY = -marginFromBox - rectHeight / 2;

                return { deltaY, deltaX, sizeLabelAngle: 180 };
            }

            if (transformerBox.rotation > 225 && transformerBox.rotation <= 315) {
                // left side
                const deltaX = -marginFromBox - rectHeight / 2;
                const deltaY = this.s(trHeight) / 2;

                return { deltaY, deltaX, sizeLabelAngle: 90 };
            }

            const deltaX = this.s(trWidth) / 2;
            const deltaY = this.s(trHeight) + marginFromBox + rectHeight / 2;

            return { deltaY, deltaX, sizeLabelAngle: 0 };
        };

        const { deltaX, deltaY, sizeLabelAngle } = getRotatedDelta();
        const x = deltaX - rectWidth / 2;
        const y = deltaY - rectHeight / 2;
        let textX = x + (rectWidth - textWidth) / 2;

        if (isContentProperty) {
            textX += ((syncIconWidth + syncIconMargin) * this.devicePixelRatio) / 2;
        }

        const textY = deltaY + Number(this.devicePixelRatio);

        this.applyRotation(deltaX, deltaY, sizeLabelAngle, this.ctx, true);

        this.drawRoundedRect(x, y, rectWidth, rectHeight, 5);
        this.ctx.fill();
        this.ctx.stroke();

        if (isContentProperty) {
            this.ctx.save();
            const iconWidth = syncIconWidth * this.devicePixelRatio;
            const iconHeight = syncIconHeight * this.devicePixelRatio;
            const marginFromIcon = syncIconMargin * this.devicePixelRatio;
            const syncIconX = deltaX - iconWidth / 2 - textWidth / 2 - marginFromIcon;
            const syncIconY = deltaY - iconHeight / 2;
            const path = new Path2D(
                'M7.14131 2.27577L7.53202 0.0599555L4.05665 2.49343L6.49013 5.9688L6.88083 3.75298C9.32562 4.18407 10.9621 6.52125 10.5311 8.96604C10.3995 9.71203 10.0898 10.3885 9.64937 10.943L10.5376 12.2115C11.2739 11.4046 11.8038 10.3861 12.0083 9.22651C12.5839 5.96187 10.4059 2.85142 7.14131 2.27577ZM5.318 12.6163C2.87322 12.1852 1.2367 9.84799 1.66778 7.4032C1.79932 6.65721 2.109 5.98071 2.54947 5.42627L1.66125 4.15776C0.924945 4.96466 0.395042 5.98312 0.190571 7.14273C-0.385072 10.4074 1.79289 13.5178 5.05753 14.0935L4.66682 16.3093L8.14219 13.8758L5.70871 10.4004L5.318 12.6163Z',
            );

            this.ctx.translate(syncIconX, syncIconY);
            this.ctx.fillStyle = '#fff';
            this.ctx.scale(this.devicePixelRatio, this.devicePixelRatio);
            this.ctx.fill(path);
            this.ctx.scale(0, 0);
            this.ctx.restore();
        }

        this.ctx.textBaseline = 'middle';
        this.ctx.textBaseline = 'middle';
        this.ctx.fillStyle = '#fff';
        this.ctx.fillText(text, textX, textY);

        this.ctx.closePath();
        this.ctx.restore();
    }

    drawTransformerBox(transformerBox: TransformerBox, isLocked: boolean, handleIndex = null, state = null) {
        const isDragging = transformerBox.boxInteractionState === InteractionStates.DRAGGING;
        const isPressed = transformerBox.boxInteractionState === InteractionStates.PRESSED;

        this.ctx.save();
        this.ctx.globalAlpha = 1; // make sure box is fully visible
        this.ctx.beginPath();

        this.ctx.lineWidth = this.devicePixelRatio * 2;
        this.ctx.strokeStyle = this.getColor(transformerBox);

        this.applyTranslation(transformerBox.position.getX(), transformerBox.position.getY(), this.ctx);
        this.applyRotation(
            transformerBox.dimension.getWidth() / 2,
            transformerBox.dimension.getHeight() / 2,
            transformerBox.rotation,
            this.ctx,
        );

        if (isPressed || isDragging) {
            this.ctx.rect(
                Number(this.devicePixelRatio),
                Number(this.devicePixelRatio),
                this.s(transformerBox.dimension.getWidth()) - this.devicePixelRatio * 2,
                this.s(transformerBox.dimension.getHeight()) - this.devicePixelRatio * 2,
            );
            this.ctx.stroke();
        } else {
            this.ctx.rect(
                0,
                0,
                this.s(transformerBox.dimension.getWidth()),
                this.s(transformerBox.dimension.getHeight()),
            );

            this.ctx.stroke();
            this.ctx.closePath();
        }

        this.ctx.closePath();
        this.ctx.restore();

        this.drawSizeLabel(transformerBox);

        if (
            (transformerBox.boxInteractionState === InteractionStates.SELECTED || transformerBox.isMultiBox) &&
            !isLocked
        ) {
            this.ctx.save();
            this.ctx.translate(0, 0);

            this.ctx.globalAlpha = 1;
            this.ctx.lineWidth = this.devicePixelRatio * 2;

            this.applyTranslation(transformerBox.position.getX(), transformerBox.position.getY(), this.ctx);
            this.applyRotation(
                transformerBox.dimension.getWidth() / 2,
                transformerBox.dimension.getHeight() / 2,
                transformerBox.rotation,
                this.ctx,
            );
            this.applyTranslation(-transformerBox.position.getX(), -transformerBox.position.getY(), this.ctx);

            if (transformerBox.isMultiBox && !isDragging) {
                this.drawMultiSelectionBox(transformerBox);
            }

            if (transformerBox.isResizeable && !isDragging && !isPressed) {
                this.drawHandlers(transformerBox, handleIndex, state);
            }
        }

        this.ctx.restore();
    }

    drawMultiSelectionBox(transformerBox: TransformerBox) {
        transformerBox.multiElementOwnBoxes.forEach((ownBox) => {
            this.ctx.save();
            this.ctx.beginPath();
            this.ctx.strokeStyle =
                transformerBox.isUsedAsMask || ownBox.isUsedAsMask ? MASK_COLOR : ORDINARY_STROKE_COLOR;
            this.ctx.lineWidth = Number(this.devicePixelRatio);
            this.applyTranslation(ownBox.position.getX(), ownBox.position.getY(), this.ctx);
            this.applyRotation(
                ownBox.dimension.getWidth() / 2,
                ownBox.dimension.getHeight() / 2,
                ownBox.rotation,
                this.ctx,
            );
            this.ctx.rect(0, 0, this.s(ownBox.dimension.getWidth()), this.s(ownBox.dimension.getHeight()));
            this.ctx.stroke();
            this.ctx.closePath();
            this.ctx.restore();
        });
    }

    drawRotationLabel(transformerBox: TransformerBox, mousePosition: Position) {
        const fontSize = 12 * this.devicePixelRatio;
        this.ctx.font = `${fontSize}px Source Sans Pro`;
        const text = `${Math.round(transformerBox[transformerBox.isMultiBox ? 'virtualRotation' : 'rotation'])}°`;
        const textWidth = this.ctx.measureText(text).width;
        const maxRectWidth = 48 * this.devicePixelRatio;
        const realRectWidth = textWidth + 32 * this.devicePixelRatio;
        const rectWidth = realRectWidth < maxRectWidth ? realRectWidth : maxRectWidth;
        const rectHeight = 32 * this.devicePixelRatio;
        const marginFromTrBox = 22 * this.devicePixelRatio;
        const centerRectY = rectHeight / 2;
        const x = this.s(mousePosition.getX()) + marginFromTrBox;
        const y = this.s(mousePosition.getY()) - centerRectY;
        const centerRectX = rectWidth / 2;
        const centerTextX = textWidth / 2;

        this.ctx.beginPath();
        this.ctx.fillStyle = '#002233e6';
        this.drawRoundedRect(x, y, rectWidth, rectHeight, 5);
        this.ctx.fill();
        this.ctx.stroke();

        this.ctx.textBaseline = 'middle';
        this.ctx.fillStyle = '#fff';
        this.ctx.fillText(text, x + centerRectX - centerTextX, this.s(mousePosition.getY()));
        this.ctx.closePath();
    }

    drawHandlers(transformerBox: TransformerBox, handleIndex: CORNERS | SIDES, state: HandlerInteractionStates) {
        this.ctx.save();
        this.ctx.translate(0, 0);
        this.ctx.globalAlpha = 1;
        this.ctx.lineWidth = this.devicePixelRatio * 2;
        transformerBox.selectionHandles.forEach((handleBox, index) => {
            if (!CORNERS[index]) {
                return;
            }

            const isHover = index === handleIndex && state === HandlerInteractionStates.HOVER;
            const desiredHandleBox = (isHover ? HOVER_HANDLER_SIZE : ORDINARY_HANDLER_SIZE) * this.devicePixelRatio;
            const offset = isHover ? HOVER_HANDLER_SIZE - ORDINARY_HANDLER_SIZE : 0;

            this.ctx.beginPath();
            this.ctx.rect(
                this.s(handleBox.renderPosition.getX()) - offset,
                this.s(handleBox.renderPosition.getY()) - offset,
                desiredHandleBox,
                desiredHandleBox,
            );
            this.ctx.strokeStyle = this.getColor(
                transformerBox,
                true,
                index === handleIndex && state === HandlerInteractionStates.PRESSED,
            );
            this.ctx.fillStyle = '#fff';
            this.ctx.fill();
            this.ctx.stroke();
            this.ctx.closePath();
        });
        this.ctx.restore();
    }
}

export default BoundingBoxManipulationCompositor;
