import { AssetStatus as AssetStatusEnum } from 'packages/types/assets';

export type AssetStatus = AssetStatusEnum.UPLOAD_IN_PROGRESS | AssetStatusEnum.COMPLETED | AssetStatusEnum.CANCELLED;

export type UploaderStages = 'validating' | 'gettingUploadUrl' | 'uploading' | 'uploaded' | 'statusUpdate';
export type UploaderErrorType = 'generic' | 'mimeType' | 'size' | 'resolution' | 'cancelled';

export type UploaderError = {
    status: 'error';
    stage: UploaderStages;
    type: UploaderErrorType;
};

type UploaderProgressState<TStage extends UploaderStages, TFields extends object = {}> = {
    stage: TStage;
    status: 'inProgress';
    hideNotification?: boolean;
} & TFields;

export type UploaderProgress =
    | UploaderProgressState<'validating'>
    | UploaderProgressState<'gettingUploadUrl'>
    | UploaderProgressState<'statusUpdate', { value: AssetStatus; uploadData?: any }>
    | UploaderProgressState<'uploading', { total: number; loaded: number; percent: number }>
    | UploaderProgressState<'uploaded', { url: string }>;

export type Task<T> = UploaderError | { status: 'success'; data: T };

export type UploaderState =
    | { status: 'ready' }
    | UploaderProgress
    | { status: 'success'; hideNotification?: boolean }
    | UploaderError;

export const ACCEPT = {
    image: [
        'image/jpg',
        'image/png',
        'image/bmp',
        'image/jpeg',
        // 'image/tiff',
        'image/webp',
        // 'image/svg+xml',
        // 'application/postscript',
        // 'application/eps',
        // 'application/x-eps',
        // 'image/eps',
        // 'image/x-eps',
    ],
    video: ['video/avi', 'video/mp4', 'video/quicktime', 'video/mpeg', 'video/webm'],
    audio: ['audio/aac', 'audio/aiff', 'audio/mp3', 'audio/mpeg', 'audio/wav', 'audio/x-wav'],
    document: [
        '.sketch',
        'application/sketch',
        'image/vnd.adobe.photoshop',
        'image/psd',
        'application/psd',
        'application/photoshop',
        'application/x-photoshop',
    ],

    get all() {
        return [...this.document, ...this.image, ...this.video, ...this.audio];
    },
};

export type AssetType = keyof typeof ACCEPT;

export type UploadService = (props: {
    file: File;
    customerId: number;
    collectionId?: number;
    abortSignal: AbortSignal;
    assetTypes: AssetType[];
    hideNotification?: boolean;
}) => AsyncGenerator<UploaderState, void, void>;
