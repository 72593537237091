import React, { useState } from 'react';
import { Dropdown, Thumbnail } from '@bynder/design-system';
import { IconSharedFolder, IconArrowDown, IconArrowUp } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import { EntityGrantedPermissions, IdentityType } from 'packages/store/sharing/types';
import { useSelector } from 'react-redux';
import { getCollection } from 'packages/store/collections/collections.selectors';
import {
    renderAllUsersPermission,
    renderGroupPermission,
    renderProfilePermission,
    renderUserPermission,
} from '../../items';
import {
    DropdownButton,
    DropdownWrapper,
    PermissionGroup,
    TextWithSeparator,
} from './ProjectPermissionsDropdown.styled';

const ProjectPermissionsDropdown = ({
    projectPermissions,
    projectName,
    isPreview,
}: {
    projectPermissions: EntityGrantedPermissions[];
    projectName: string;
    isPreview?: boolean;
}) => {
    const [expanded, setExpanded] = useState(false);
    const project = useSelector(getCollection);
    const { translate } = useTranslate();

    const getSubText = () => {
        const permissionsCounter = projectPermissions.reduce(
            (acc, perm) => {
                if (perm.securityIdentity.securityIdentityType === IdentityType.GROUP) {
                    return { ...acc, groups: acc.groups + 1 };
                }
                if (perm.securityIdentity.securityIdentityType === IdentityType.USER) {
                    return { ...acc, users: acc.users + 1 };
                }
                if (perm.securityIdentity.securityIdentityType === IdentityType.PROFILE) {
                    return { ...acc, profiles: acc.profiles + 1 };
                }
                if (perm.securityIdentity.securityIdentityType === IdentityType.ALL_USERS) {
                    return { ...acc, usersall: acc.usersall + 1 };
                }

                return acc;
            },
            {
                groups: 0,
                users: 0,
                profiles: 0,
                usersall: 0,
            },
        );

        let subText = <></>;

        if (permissionsCounter.usersall > 0) {
            const allUsersTitle = translate('modal.sharing.ownership.group.title.all');

            if (permissionsCounter.groups > 0 || permissionsCounter.users > 0) {
                subText = <TextWithSeparator>{allUsersTitle}</TextWithSeparator>;
            } else {
                subText = <PermissionGroup>{allUsersTitle}</PermissionGroup>;
            }
        }
        if (permissionsCounter.groups > 0) {
            const groupsTitle = translate('modal.sharing.ownership.project.inherit.group', {
                counter: permissionsCounter.groups,
            });

            if (permissionsCounter.users > 0) {
                subText = (
                    <>
                        {subText}
                        <TextWithSeparator>{groupsTitle}</TextWithSeparator>
                    </>
                );
            } else {
                subText = (
                    <>
                        {subText}
                        <PermissionGroup>{groupsTitle}</PermissionGroup>
                    </>
                );
            }
        }
        if (permissionsCounter.users > 0) {
            const usersTitle = translate('modal.sharing.ownership.project.inherit.users', {
                counter: permissionsCounter.users,
            });

            subText = (
                <>
                    {subText}
                    <PermissionGroup>{usersTitle}</PermissionGroup>
                </>
            );
        }
        if (permissionsCounter.profiles > 0) {
            const profilesTitle = translate('modal.sharing.ownership.project.inherit.profiles', {
                counter: permissionsCounter.profiles,
            });

            subText = (
                <>
                    <TextWithSeparator>{subText}</TextWithSeparator>
                    <PermissionGroup>{profilesTitle}</PermissionGroup>
                </>
            );
        }

        return subText;
    };

    const renderSharePermissions = (items: EntityGrantedPermissions[]) =>
        items.map((item) => {
            if (item.securityIdentity.securityIdentityType === IdentityType.USER) {
                return renderUserPermission(item, items, true, translate, !isPreview && {});
            }

            if (item.securityIdentity.securityIdentityType === IdentityType.ALL_USERS) {
                return renderAllUsersPermission(item, translate, false, isPreview);
            }

            if (item.securityIdentity.securityIdentityType === IdentityType.GROUP) {
                return renderGroupPermission(item, items, true, translate, !isPreview && {});
            }

            if (item.securityIdentity.securityIdentityType === IdentityType.PROFILE) {
                return renderProfilePermission(item, items, true, translate, !isPreview && {});
            }

            return null;
        });

    return (
        <>
            <DropdownButton
                id="project_permissions_button"
                subtext={getSubText()}
                thumbnail={<Thumbnail icon={<IconSharedFolder />} shape="square" />}
                onClick={() => setExpanded(!expanded)}
                rightElements={expanded ? <IconArrowUp /> : <IconArrowDown />}
            >
                {translate('modal.sharing.ownership.project.inherit.title', {
                    projectName: project ? project.name : projectName,
                })}
            </DropdownButton>
            {expanded && <DropdownWrapper>{renderSharePermissions(projectPermissions)}</DropdownWrapper>}
            <Dropdown.Divider />
        </>
    );
};

export default ProjectPermissionsDropdown;
