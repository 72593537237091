import React, { useCallback, memo } from 'react';
import { Button, Tooltip } from '@bynder/design-system';
import { IconVolumeDown, IconVolumeOff, IconVolumeUp } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import { MultiPageVideoModel } from '@bynder-studio/render-core';
import useCurrentPage from 'packages/hooks/useCurrentPage';
import usePlaybackAudio from 'packages/hooks/usePlaybackAudio';
import { VolumeSlider } from './Component.styled';

const SoundIcon = ({ isMuted, volume }: { isMuted: boolean; volume: number }) => {
    if (isMuted || volume === 0) {
        return <IconVolumeOff />;
    }

    if (volume > 0.5) {
        return <IconVolumeUp />;
    }

    return <IconVolumeDown />;
};

type Props = {
    creativeModel: MultiPageVideoModel | null | undefined;
    size: 's' | 'm';
    iconRight?: React.ReactNode;
};

// Shared component for controlling playback audio
const VolumeControl = ({ creativeModel, size, iconRight }: Props) => {
    const { translate } = useTranslate();
    const { toggleMutedStatus, isMuted, volume, setVolume, setMutedStatus } = usePlaybackAudio({
        control: creativeModel?.getAudioControl(),
    });
    useCurrentPage(creativeModel); // rerender on page change

    const handleVolumeChange = useCallback(
        (val: number | [number, number]) => {
            if (Array.isArray(val)) {
                val = val[0];
            }

            setVolume(val / 100);
            setMutedStatus(val === 0);
        },
        [setVolume, setMutedStatus],
    );

    return (
        <VolumeSlider
            size={size}
            value={isMuted ? 0 : Math.round(volume * 100)}
            min={0}
            max={100}
            onChange={handleVolumeChange}
            iconLeft={
                <Tooltip
                    content={
                        isMuted
                            ? translate('editor.timeline.shots.audio.unmute')
                            : translate('editor.timeline.shots.audio.mute')
                    }
                    timing="instant"
                >
                    <Button
                        title={translate('editor.timeline.shots.audio.status')}
                        variant="clean"
                        onClick={toggleMutedStatus}
                        icon={<SoundIcon isMuted={isMuted} volume={volume} />}
                    />
                </Tooltip>
            }
            iconRight={iconRight}
            isInactive
        />
    );
};

export default memo<Props>(VolumeControl);
