import useAudio from 'packages/hooks/useAudio';

export default function useGlobalAudio({ audio, onAudioChange }) {
    const { subscribe, onChange, ...others } = useAudio({ audio, onAudioChange });

    return {
        fileName: audio?.fileName,
        offsetTime: audio?.offsetTime,
        ...others,
    };
}
