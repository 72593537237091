import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dropdown, EmptyState, Flex, Divider } from '@bynder/design-system';
import { IconCollection, IconSortOrder } from '@bynder/icons';
import { Translate, useTranslate } from '@bynder/localization';
import { IconGridView, IconListView } from '@bynder/icons';
import { assetsDataSelector } from '~/store/assets/assets.selectors';
import { fetchAssetsByOptions } from '~/store/assets/assets.actions';
import renderHeaders from 'packages/pages/components/list/headers';
import ListView from 'packages/pages/components/list';
import SkeletonGridView from 'packages/pages/designs/components/SkeletonGridView';
import useFluidWidth from 'packages/pages/components/grid/useFluidWidth';
import Grid from 'packages/pages/components/grid';
import AssetsGridCard from 'packages/pages/components/AssetsPickerModal/components/AssetsGridCard';
import UploadButton from 'packages/pages/components/Uploader/UploadButton';
import useFilters from 'packages/pages/components/AssetsPickerModal/components/useFilters';
import { columnWidths, renderItems } from 'packages/pages/components/AssetsPickerModal/components/AssetsListItem';
import { CardBox } from 'packages/pages/components/AssetsPickerModal/components/AssetsGridCard/index.styled';
import {
    AssetsWrapper,
    Header,
    OrderingButton,
} from 'packages/pages/components/AssetsPickerModal/components/AssetsPickerLocal/index.styled';
import Search from 'packages/pages/components/filters/components/Search';
import MaybeTooltip from 'packages/common/MaybeTooltip';
import generateTestId from '~/helpers/testIdHelpers';
import { AssetType } from 'packages/pages/components/Uploader/types';
import { RESET_ASSETS } from '~/store/types';
import { OrderFilterType, SortingFilterType } from 'packages/pages/components/filters/components/Ordering/types';
import { Views } from 'packages/pages/components/AssetsPickerModal/components/AssetsPickerLocal/types';
import useAssetStatusUpdates from 'packages/hooks/useAssetStatusUpdates';
import type { Asset } from 'packages/types/assets';
import { DividerWrapper } from './index.styled';

type Props = {
    type: AssetType;
    selectedObject: any;
    handleSelectRow: (asset) => void;
    collectionId: number | null;
    filterTypes: string[];
    uploadTypes: string[];
    isPersonalUploadsAllowed: boolean;
};

const minimalColumnWidth = 224 + 4 * 2; // 224 width card + 4 * 2 padding for outline
const gutterSize = 24 - 4 * 2;

export const headers = [
    { id: 'checkboxes', title: '', notSortable: true },
    { id: 'name', title: <Translate id="listview.uploads.column.name" /> },
    { id: 'date_created', title: <Translate id="listview.uploads.column.date_uploaded" /> },
    { id: 'resolution', title: <Translate id="listview.uploads.column.resolution" /> },
    { id: 'type', title: <Translate id="listview.uploads.column.type" /> },
    { id: 'menu', title: '', notSortable: true },
];

const SORT_NAMES = ['date_created', 'name', 'size', 'resolution'] as const;
const hints = { grid: 'listview.toggle.hint.grid', list: 'listview.toggle.hint.listview' };
const listViewTestId = generateTestId('assets_picker_header_listview_btn');

const compareAssets = (a: Asset, b: Asset) => a.id === b.id && a.fileName === b.fileName && a.status === b.status;

const AssetsPickerLocal = ({
    type,
    selectedObject,
    handleSelectRow,
    collectionId,
    filterTypes,
    uploadTypes,
    isPersonalUploadsAllowed,
}: Props) => {
    const { fetchOptions, totalCount, loadingData, assets } = useSelector(assetsDataSelector);

    useAssetStatusUpdates();

    const { translate } = useTranslate();
    const dispatch = useDispatch();
    const { onSort, onSearch } = useFilters();
    const [view, setView] = useState(Views.GRID);

    const loadMoreAssets = () => {
        if (!loadingData && assets.length < totalCount) {
            const options = {
                ...fetchOptions,
                page: (fetchOptions.page += 1),
            };

            dispatch(fetchAssetsByOptions(options, false));
        }
    };

    const handleScroll = (e: any) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        const t = scrollTop / (scrollHeight - clientHeight);

        if (t > 0.75 && !loadingData) {
            loadMoreAssets();
        }
    };

    useEffect(() => {
        dispatch(
            fetchAssetsByOptions(
                {
                    ...fetchOptions,
                    thumbnails: ['320x320', '1920x1920'],
                    type: filterTypes.length ? filterTypes : [type.toUpperCase()],
                    collectionId,
                },
                true,
            ),
        );

        return () => {
            dispatch({ type: RESET_ASSETS });
        };
    }, [collectionId]);

    const filterHeaders = () => {
        if (type === 'document') {
            return headers.filter((h) => h.id !== 'resolution');
        }

        return headers;
    };

    const filterSortNames = () => {
        if (type === 'document') {
            return SORT_NAMES.filter((n) => n !== 'resolution');
        }

        return SORT_NAMES;
    };

    const { gridContainer, getColumnWidth } = useFluidWidth();
    const columnWidth = gridContainer.current
        ? getColumnWidth(gridContainer?.current?.clientWidth - 20, minimalColumnWidth, gutterSize)
        : minimalColumnWidth;

    const isToolbarDisabled = loadingData || !assets.length;
    const [filterType, ordering] = fetchOptions.sort;

    const renderGridView = () => {
        if (gridContainer.current && gridContainer.current.clientWidth) {
            return (
                <Grid<Asset>
                    items={assets}
                    compareItems={compareAssets}
                    renderItem={(asset: Asset, defaultHeight) => (
                        <CardBox defaultHeight={defaultHeight}>
                            <AssetsGridCard
                                asset={asset}
                                isSelected={selectedObject?.id === asset.id}
                                onSelect={() => {
                                    handleSelectRow(asset);
                                }}
                                onClick={() => {
                                    handleSelectRow(asset);
                                }}
                            />
                        </CardBox>
                    )}
                    isLoading={loadingData}
                    onScroll={loadMoreAssets}
                    columnWidth={columnWidth}
                    gutterSize={gutterSize}
                />
            );
        }

        return null;
    };

    const renderListView = () => {
        const filteredHeaders = filterHeaders();

        return (
            <ListView
                columnWidths={columnWidths[filteredHeaders.length]}
                renderHeader={renderHeaders(filteredHeaders, loadingData, fetchOptions.sort, onSort, [])}
                renderItem={renderItems(
                    assets,
                    { onClick: handleSelectRow, onSelect: handleSelectRow },
                    selectedObject?.id,
                    filteredHeaders.map((h) => h.id),
                )}
                isLoading={loadingData}
                container={gridContainer}
                onScroll={() => {}}
            />
        );
    };

    const renderEmpty = () => (
        <EmptyState
            icon={<IconCollection />}
            title={translate('pages.uploads.empty.title')}
            text={translate('pages.uploads.empty.info')}
            actions={
                isPersonalUploadsAllowed ? (
                    <UploadButton
                        iconType="upload"
                        collectionId={collectionId}
                        assetTypes={uploadTypes.length ? uploadTypes : [type]}
                    />
                ) : (
                    <></>
                )
            }
        />
    );

    return (
        <>
            <Header>
                <Flex justifyContent="space-between" alignItems="center">
                    <Search onSearch={onSearch} />
                    <div>
                        <Flex alignItems="center">
                            {view === Views.GRID && (
                                <>
                                    <Dropdown
                                        minWidth="224px"
                                        position="bottom-right"
                                        trigger={({ isOpen, ...triggerProps }) => (
                                            <Button
                                                variant="clean"
                                                isPressed={isOpen}
                                                rightIcon={<Dropdown.Arrow />}
                                                isDisabled={isToolbarDisabled}
                                                {...triggerProps}
                                            >
                                                {translate(`modal.assetsPicker.sorting.${filterType}`)}
                                            </Button>
                                        )}
                                    >
                                        {filterSortNames().map((filterName) => (
                                            <Dropdown.Item
                                                key={filterName}
                                                isChecked={filterType === filterName}
                                                isDisabled={isToolbarDisabled}
                                                onClick={() => {
                                                    onSort(
                                                        filterName as SortingFilterType,
                                                        ordering as OrderFilterType,
                                                    );
                                                }}
                                            >
                                                {translate(`modal.assetsPicker.sorting.${filterName}`)}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown>
                                    <OrderingButton
                                        title=""
                                        variant="clean"
                                        icon={<IconSortOrder />}
                                        isReversed={ordering === OrderFilterType.ASCENDING}
                                        isDisabled={isToolbarDisabled}
                                        onClick={() => {
                                            onSort(
                                                filterType as SortingFilterType,
                                                ordering === OrderFilterType.ASCENDING
                                                    ? OrderFilterType.DESCENDING
                                                    : OrderFilterType.ASCENDING,
                                            );
                                        }}
                                    />
                                    <DividerWrapper>
                                        <Divider direction="vertical" />
                                    </DividerWrapper>
                                </>
                            )}
                            <MaybeTooltip
                                hasTooltip={!isToolbarDisabled}
                                content={view === Views.LIST ? translate(hints.grid) : translate(hints.list)}
                                position="top-right"
                            >
                                <Button
                                    title=""
                                    variant="clean"
                                    icon={view === Views.LIST ? <IconListView /> : <IconGridView />}
                                    onClick={() => {
                                        setView(view === Views.GRID ? Views.LIST : Views.GRID);
                                    }}
                                    isDisabled={isToolbarDisabled}
                                    {...listViewTestId}
                                />
                            </MaybeTooltip>
                        </Flex>
                    </div>
                </Flex>
            </Header>
            {!assets.length && loadingData && <SkeletonGridView />}
            <AssetsWrapper onScroll={handleScroll} isScrollable={view === Views.LIST} ref={gridContainer}>
                {!assets.length ? renderEmpty() : view === Views.GRID ? renderGridView() : renderListView()}
            </AssetsWrapper>
        </>
    );
};

export default AssetsPickerLocal;
