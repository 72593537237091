import { truthy } from '@bynder-studio/misc';
import type { IAssetsLoader } from '../../AssetLoader/IAssetsLoader';
import { ElementTypes } from '../../Enums/ElementTypes';
import {
    calculateTimelineDuration,
    getAllElementsRecursively,
    getElementDiffByAsset,
} from '../../Helpers/elementUtils';
import type { IAsset } from '../Assets/IAsset';
import {
    BaseModel,
    defaultCreateElementOptions,
    defaultUpdateElementOptions,
    defaultUpdateGlobalPropertyOptions,
} from '../BaseModel/BaseModel';
import type { CreateElementOptions, UpdateElementOptions, UpdateGlobalPropertyOptions } from '../BaseModel/IBaseModel';
import { CompModel } from '../CompModels/CompModel';
import type { ICompModel } from '../CompModels/ICompModel';
import { createElement } from '../Elements/Helpers/ElementHelpers';
import type { IElement } from '../Elements/IElement';
import { ElementUpdateOptions } from '../Elements/IElement';
import { GlobalAudio } from '../Properties/GlobalAudio';
import { PosterFrame } from '../Properties/PosterFrame';
import { AudioControl } from '../Shared/AudioControl';
import type { IAudioControl } from '../Shared/IAudioControl';
import { PlaybackDuration } from '../Shared/PlaybackDuration';
import type { IShot } from '../Shot/IShot';
import type { IVideoModel } from './IVideoModel';
import { BaseVisualElement } from '../Elements/BaseVisualElement';
import { Reason } from '../../types';
import { Event } from '../../event-types';
import { BaseCompElement } from '../CompModels/Elements/BaseCompElement';
import { GroupElement } from '../Elements/GroupElement';
import { Dimension } from '../Shared/Dimension';
import { BackgroundColor } from '../Properties/BackgroundColor';
import { BaseProperty } from '../Properties/BaseProperty';

export class VideoModel extends BaseModel implements IVideoModel {
    protected compModels!: ICompModel[];

    protected globalAudioTrack1!: GlobalAudio;

    protected globalAudioTrack2!: GlobalAudio;

    protected posterFrame!: PosterFrame;

    protected playbackDuration!: PlaybackDuration;

    protected shots!: IShot[];

    protected audioControl: IAudioControl | null = null;

    protected isCompModelCacheEnabled = true;

    protected assetLoadListener = ({ asset }: { asset: IAsset }) => this.handleAssetLoad(asset);

    getFrameRangeAffectedByEvents(events: Event[]) {
        // calculated frame range affected by changes
        const affectedFrameRange = events.reduce(
            (acc, event) => {
                const eventData = event[1] as any;
                const elStartFrame = eventData?.element?.startFrame ?? Number.POSITIVE_INFINITY;
                const elDuration = eventData?.element?.duration ?? Number.NEGATIVE_INFINITY;
                const startFrame = Math.min(
                    elStartFrame,
                    eventData?.newValues?.startFrame ?? Number.POSITIVE_INFINITY,
                    eventData?.oldValues?.startFrame ?? Number.POSITIVE_INFINITY,
                );
                // summary of Number.Infinity values returns Nan and break the logic
                const endFrame =
                    elStartFrame === Number.POSITIVE_INFINITY && elDuration === Number.NEGATIVE_INFINITY
                        ? Number.NEGATIVE_INFINITY
                        : Math.max(
                              elStartFrame + elDuration - 1,
                              (eventData?.newValues?.startFrame ?? elStartFrame) +
                                  (eventData?.newValues?.duration ?? elDuration) -
                                  1,
                              (eventData?.oldValues?.startFrame ?? elStartFrame) +
                                  (eventData?.oldValues?.duration ?? elDuration) -
                                  1,
                          );

                return [Math.min(startFrame, acc[0]), Math.max(endFrame, acc[1])];
            },
            [Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY],
        );

        if (affectedFrameRange[0] === Number.POSITIVE_INFINITY || affectedFrameRange[1] === Number.NEGATIVE_INFINITY) {
            return null;
        }

        return affectedFrameRange;
    }

    endAccumulation(reason: Reason = 'user'): void {
        const events = this.getEventAccumulator().mergeEvents();
        super.endAccumulation(reason);
        const level = this.getEventAccumulator().getLevel();

        if (level) {
            return;
        }

        // Make sure only affected frames are invalidated
        const affectedFrameRange = this.getFrameRangeAffectedByEvents(events);

        if (affectedFrameRange) {
            const [startFrame, endFrame] = affectedFrameRange;
            this.invalidateCompModels(startFrame, endFrame);
        }
    }

    setAssetLoader(assetLoader: IAssetsLoader) {
        super.setAssetLoader(assetLoader);
        this.getAssetLoader().eventEmitter.on('asset.load', this.assetLoadListener);

        return this;
    }

    setDimensions(dimension: Dimension) {
        super.setDimensions(dimension);

        return this;
    }

    setBackgroundColor(backgroundColor: BackgroundColor) {
        super.setBackgroundColor(backgroundColor);

        return this;
    }

    setup() {
        super.setup();
        this.setupElements();
        const frameRate = this.getPlaybackDuration().getFrameRate();

        if (this.globalAudioTrack1) {
            this.globalAudioTrack1.setAssetLoader(this.getAssetLoader());
            this.globalAudioTrack1.constructAsset(frameRate);
        }

        if (this.globalAudioTrack2) {
            this.globalAudioTrack2.setAssetLoader(this.getAssetLoader());
            this.globalAudioTrack2.constructAsset(frameRate);
        }

        this.compModels = new Array(this.getPlaybackDuration().getDuration());

        if (this.shots) {
            this.setupShots();
        }

        this.audioControl = new AudioControl();

        return this;
    }

    protected setupElements(elements: IElement[] = this.getAllElementsRecursively()) {
        super.setupElements(elements);
        const frameRate = this.getPlaybackDuration().getFrameRate();
        elements.forEach((el) => {
            el.constructAsset(frameRate);
        });
    }

    protected setupShots() {
        this.shots.forEach((shot: IShot) => {
            const elements = shot
                .getElementIds()
                .map((elId) => this.getElementById(elId))
                .filter(truthy);
            shot.setElements(elements);
        });
    }

    getCompModel(frameIndex: number): ICompModel {
        if (this.isCompModelCacheEnabled && this.compModels[frameIndex] && this.compModels[frameIndex].isUpToDate()) {
            return this.compModels[frameIndex];
        }

        const compModel = new CompModel()
            .setFrame(frameIndex)
            .setDimensions(this.getDimensions())
            .setBackgroundColor(this.getBackgroundColor().color)
            .setElements(this.getVisibleElements(frameIndex))
            .setMaskElementRanges(this.getAllMaskElementRanges());

        if (this.isCompModelCacheEnabled) {
            this.compModels[frameIndex] = compModel;
        }

        return compModel;
    }

    /**
     * Load compModel for a frame index and include transformation matrices.
     * @param {*} frameIndex
     */
    getCompModelWithTransitions(frameIndex: number, optimize = true): ICompModel {
        /*
        // TODO: optimize by only getting transforms for elements that have motion blur enable and experience movement.
        const getElementsWithMB = (elements) => {
            let elsWithMB = [];
            elements.forEach(el => {
                const elAnims = [...(el.animations || []), el.animationIn, el.animationOut].filter(x => x);
                if (elAnims.some(anim => anim.config.motionBlur === true)){
                    elsWithMB.push(el);
                }
                if(el instanceof GroupElement){
                    elsWithMB.push(...getElementsWithMB(el.children));
                }
            });
            return elsWithMB;
        };
        const elements = this.videoModel.getVisibleElements(frameIndex);
        const elementsWithMB = getElementsWithMB(elements);
        */

        // Full compModels
        const compModel = this.getCompModel(frameIndex);
        const compModelOpen = this.getCompModel(frameIndex - 0.25); // compModel at shutter open
        const compModelClose = this.getCompModel(frameIndex + 0.25); // compModel at shutter close

        const compElements = compModel.getAllCompElementsRecursively() as BaseCompElement[];
        const compElementsOpen = compModelOpen.getAllCompElementsRecursively() as BaseCompElement[];
        const compElementsClose = compModelClose.getAllCompElementsRecursively() as BaseCompElement[];

        // console.log(compElements);
        compElements.forEach((ce) => {
            if (ce.motionBlur) {
                const ceo = compElementsOpen.find((ceo) => ceo.id === ce.id);
                const cec = compElementsClose.find((cec) => cec.id === ce.id);

                ce.transformData = ce.getTransformData();
                ce.transformOpenData = ceo?.getTransformData() ?? null;
                ce.transformCloseData = cec?.getTransformData() ?? null;

                // since the position in the transformdata is absolute, we make it relative here
                const referencePos = ce.transformData.position.subtract(
                    ce.boundingBox.position.add(ce.contentBox.position),
                );

                ce.transformData.position = ce.transformData.position.subtract(referencePos);

                if (ce.transformOpenData) {
                    ce.transformOpenData.position = ce.transformOpenData.position.subtract(referencePos);
                }

                if (ce.transformCloseData) {
                    ce.transformCloseData.position = ce.transformCloseData.position.subtract(referencePos);
                }
            }
        });

        return compModel;
    }

    setCompModel(frameIndex: number, compModel: ICompModel) {
        this.compModels[frameIndex] = compModel;
    }

    createAllCompModels(startFrame = 0, endFrame = undefined): void {
        let correctedEndFrame = endFrame;

        if (!endFrame && endFrame !== 0) {
            correctedEndFrame = this.getPlaybackDuration().getDuration();
        }

        let frameIndex = startFrame;

        while (frameIndex <= correctedEndFrame) {
            this.getCompModel(frameIndex);
            frameIndex++;
        }
    }

    setGlobalAudioTrack1(globalAudioTrack1: GlobalAudio) {
        this.globalAudioTrack1 = globalAudioTrack1;

        return this;
    }

    getGlobalAudioTrack1(): GlobalAudio {
        return this.globalAudioTrack1;
    }

    setGlobalAudioTrack2(globalAudioTrack2: GlobalAudio) {
        this.globalAudioTrack2 = globalAudioTrack2;

        return this;
    }

    getGlobalAudioTrack2(): GlobalAudio {
        return this.globalAudioTrack2;
    }

    setPosterFrame(posterFrame: PosterFrame) {
        this.posterFrame = posterFrame;

        return this;
    }

    getPosterFrame(): PosterFrame {
        return this.posterFrame;
    }

    setPlaybackDuration(playbackDuration: PlaybackDuration) {
        this.playbackDuration = playbackDuration;

        return this;
    }

    getPlaybackDuration(): PlaybackDuration {
        return this.playbackDuration;
    }

    setShots(shots: IShot[]) {
        this.shots = shots;

        return this;
    }

    getShots(): IShot[] {
        return this.shots;
    }

    protected invalidateCompModels(startFrame: number, endFrame: number): void {
        for (let i = startFrame; i <= endFrame; i++) {
            const compModel = this.compModels[i];

            if (compModel) {
                compModel.invalidate();
            }
        }

        this.emit('requireFramesUpdate', {
            startFrame,
            endFrame,
        });
    }

    updateShot(
        shotId: number,
        params: {
            thumbnailFrame: number;
        },
    ): void {
        const shot = this.shots.find((s: IShot) => s.id === shotId);

        if (!shot) {
            return;
        }

        shot.update(params);
        this.emit('shotUpdated', {
            shot,
        });
    }

    replaceShots(parsedShots: IShot[]): void {
        const shotTuples = parsedShots.map((parsedNewShot) => {
            const prevShot = this.shots.find((prevShot: IShot) => prevShot.id === parsedNewShot.id);
            const newThumbnailFrame = Math.min(prevShot?.getThumbnailFrame() ?? 0, parsedNewShot.getDuration() - 1);
            parsedNewShot.update({
                thumbnailFrame: newThumbnailFrame,
            });

            // determine if shot has changed based on displayframe
            const isNew = !prevShot || prevShot.getDisplayFrame() != parsedNewShot.getDisplayFrame();

            return {
                shot: parsedNewShot,
                isNew,
            };
        });
        this.shots = shotTuples.map(({ shot }) => shot);
        this.setupShots();
        const changedShots = shotTuples.filter((tuple) => tuple.isNew).map(({ shot }) => shot);
        this.emit('shotsReplaced', {
            shots: changedShots,
        });
    }

    getUpdateElementOptions(): ElementUpdateOptions {
        const frameRate = this.getPlaybackDuration().getFrameRate();

        return {
            frameRate,
        };
    }

    updateElement(elId: number, rawElement: any, options: UpdateElementOptions = {}): void {
        this.beginAccumulation();
        const element = this.getElementById(elId);
        const { walkChildren, walkParent, reason } = { ...defaultUpdateElementOptions, ...options };

        if (!element) {
            this.endAccumulation(reason);

            return;
        }

        const oldStartFrame = element.startFrame;
        const oldDuration = element.duration;
        // const oldPosition = element.position;
        // const oldParent = element.parent;
        // const updateTypes: Set = element.update(rawElement, frameRate);
        // if (updateTypes.has(ElementUpdateTypes.CHILDREN)) {
        //     element.update(element.getDiffBasedOnChildren());
        // }
        // if ('parent' in rawElement) {
        //     // detach
        //     if (oldParent) {
        //         oldParent.update({ children: oldParent.children.filter((el) => el.id !== element.id) });
        //         oldParent.update(oldParent.getDiffBasedOnChildren());
        //     } else {
        //         this.setElements(this.getElements().filter((el) => el.id !== element.id));
        //     }
        //
        //     // attach
        //     if (element.parent) {
        //         element.parent.update({ children: [...element.parent.children, element] });
        //         element.parent.update(element.parent.getDiffBasedOnChildren());
        //     } else {
        //         this.setElements([...this.getElements(), element]);
        //     }
        // }
        super.updateElement(elId, rawElement, options);

        if (walkChildren && element instanceof GroupElement && element.children) {
            const diff: any = {};
            const diffKeys: string[] = [];

            if (oldDuration > element.duration) {
                diffKeys.push('duration');
            } else if (oldStartFrame !== element.startFrame) {
                diff.startFrame = element.startFrame - oldStartFrame;
                diffKeys.push('startFrame');
            }

            if (diffKeys.length) {
                const endFrame = element.startFrame + element.duration;
                element.children.map((child) => {
                    const diffData = diffKeys.reduce((acc: any, key) => {
                        if (key === 'startFrame') {
                            acc.startFrame = child.startFrame + diff.startFrame;
                        }

                        if (key === 'duration') {
                            let childStartFrame = child.startFrame;

                            if (child.startFrame < element.startFrame) {
                                childStartFrame = element.startFrame;
                                acc.startFrame = element.startFrame;
                            }

                            const childEndFrame = childStartFrame + child.duration;

                            if (childEndFrame > endFrame) {
                                acc.duration = endFrame - childStartFrame;
                            }
                        }

                        return acc;
                    }, {});
                    this.updateElement(child.id, diffData, {
                        walkParent: false,
                    });
                });
            }
        }

        this.endAccumulation(reason);
    }

    updateElements(rawElements: any[], options: UpdateElementOptions = {}): void {
        this.beginAccumulation();
        rawElements.forEach((rawElement) => this.updateElement(rawElement.id, rawElement, options));
        this.endAccumulation();
    }

    updateGlobalProperty(elType: string, rawElement: any, options: UpdateGlobalPropertyOptions = {}): void {
        this.beginAccumulation();
        const { reason } = { ...defaultUpdateGlobalPropertyOptions, ...options };
        const playbackDuration = this.getPlaybackDuration();

        switch (elType) {
            case ElementTypes.BACKGROUND_COLOR: {
                super.updateGlobalProperty(elType, rawElement, options);
                const timelineDuration = Math.max(
                    playbackDuration.getDuration(),
                    calculateTimelineDuration(this.getElements()),
                );
                this.invalidateCompModels(0, timelineDuration - 1);
                break;
            }

            case ElementTypes.POSTER_FRAME: {
                const property = this.getPosterFrame();
                const oldValues = property.getValuesByUpcomingUpdate(rawElement);
                property.update(rawElement);
                this.emit('posterFrameUpdated', {
                    property,
                    oldValues,
                    newValues: rawElement,
                });
                break;
            }

            case ElementTypes.GLOBAL_AUDIO: {
                const { audioTrackNumber: track = 1 } = options;
                const property = track === 1 ? this.getGlobalAudioTrack1() : this.getGlobalAudioTrack2();
                const oldValues = property.getValuesByUpcomingUpdate(rawElement);
                property.update(rawElement, { skipAssetDestroy: options.skipAssetDestroy });
                this.emit('globalAudioUpdated', {
                    property,
                    oldValues,
                    newValues: rawElement,
                    track,
                });
                const timelineDuration = Math.max(
                    playbackDuration.getDuration(),
                    calculateTimelineDuration(this.getElements()),
                );
                this.invalidateCompModels(0, timelineDuration - 1);
                break;
            }
        }

        this.endAccumulation(reason);
    }

    updatePlaybackDuration(duration: number, options: UpdateGlobalPropertyOptions = {}): void {
        this.beginAccumulation();
        const { reason } = { ...defaultUpdateGlobalPropertyOptions, ...options };
        const playbackDuration = this.getPlaybackDuration();
        const oldValue = playbackDuration.getDuration();
        playbackDuration.setDuration(duration);
        this.emit('durationUpdated', {
            playbackDuration,
            oldValue,
            newValue: duration,
        });
        this.endAccumulation(reason);
    }

    updateDimension(
        rawDimension: {
            width: number;
            height: number;
        },
        options: UpdateGlobalPropertyOptions = {},
    ): void {
        super.updateDimension(rawDimension, options);
        const playbackDuration = this.getPlaybackDuration();
        const timelineDuration = Math.max(
            playbackDuration.getDuration(),
            calculateTimelineDuration(this.getElements()),
        );
        this.invalidateCompModels(0, timelineDuration - 1);
    }

    // create element with basic props
    createElement(type: ElementTypes, rawParams: any, options: CreateElementOptions = {}): IElement {
        this.beginAccumulation();
        const { reason } = { ...defaultCreateElementOptions, ...options };
        const newElement = createElement(
            type,
            rawParams,
            this.getElements(),
            this.getDimensions(),
            this.getPlaybackDuration(),
        );

        if (!newElement) {
            throw new Error(`Can't create element by type ${type}`);
        }

        this.addElements([newElement] as BaseVisualElement[], newElement.startFrame, {
            reason,
        });
        this.endAccumulation(reason);

        return newElement;
    }

    addElements(elements: BaseVisualElement[], startFrame: number, options: CreateElementOptions = {}) {
        this.beginAccumulation();
        const flattenElementsArray = elements.map((element) => getAllElementsRecursively([element]));
        super.addElements(elements, startFrame, options, flattenElementsArray);
        elements.forEach((element, index) => this.setupElements(flattenElementsArray[index]));
        this.endAccumulation();
    }

    protected handleAssetLoad(asset: IAsset): void {
        if (this.globalAudioTrack1.srcId === asset.id) {
            const element = this.globalAudioTrack1;

            if (element) {
                element.setProperties(getElementDiffByAsset(element as unknown as IElement, asset));
            }

            // TODO: is this necessary? How do individual frames related to globalaudio?
            const timelineDuration = Math.max(
                this.getPlaybackDuration().getDuration(),
                calculateTimelineDuration(this.getElements()),
            );
            this.invalidateCompModels(0, timelineDuration - 1);
        } else {
            this.getAllElementsRecursively().forEach((element) => {
                if (element.isContainsAsset(asset)) {
                    element.setProperties(getElementDiffByAsset(element, asset));
                    this.invalidateCompModels(element.startFrame, element.startFrame + element.duration - 1);
                }
            });
        }
    }

    toObject() {
        return {
            ...super.toObject(),
            globalAudioTrack1: this.globalAudioTrack1?.toObject() ?? null,
            globalAudioTrack2: this.globalAudioTrack1?.toObject() ?? null,
            posterFrame: this.posterFrame ?? null,
            playbackDuration: this.playbackDuration?.toObject() ?? null,
            shots: this.shots?.map((shot) => shot.toObject()) ?? [],
        };
    }

    setElements(elements: BaseVisualElement[]) {
        super.setElements(elements);

        return this;
    }

    getAllGlobalProperties(): BaseProperty[] {
        return [this.backgroundColor, this.posterFrame, this.globalAudioTrack1].filter(truthy);
    }

    getCopy() {
        const elements = this.getElementsCopy();
        const globalAudioTrack1 = this.globalAudioTrack1?.getCopy();
        const globalAudioTrack2 = this.globalAudioTrack2?.getCopy();
        const posterFrame = this.posterFrame?.getCopy();
        const playbackDuration = this.playbackDuration?.getCopy();
        const plainElements = getAllElementsRecursively(elements);
        const shots = this.shots?.map((shot) => {
            const newShot = shot.getCopy();
            const elIds = new Set(newShot.getElementIds());
            newShot.setElements(plainElements.filter((el) => elIds.has(el.id)));

            return newShot;
        });

        return new VideoModel()
            .setElements(elements)
            .setGlobalAudioTrack1(globalAudioTrack1)
            .setGlobalAudioTrack2(globalAudioTrack2)
            .setBackgroundColor(this.backgroundColor.getCopy())
            .setPosterFrame(posterFrame)
            .setDimensions(this.dimension.getCopy())
            .setPlaybackDuration(playbackDuration)
            .setShots(shots);
    }

    getAudioControl(): IAudioControl {
        if (!this.audioControl) {
            throw new Error('The audio control is not set up.');
        }

        return this.audioControl;
    }

    disableCompModelCache() {
        this.isCompModelCacheEnabled = false;
    }
}
