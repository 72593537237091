import React, { useEffect, useState, useRef } from 'react';
import InputMask from 'react-input-mask';
import { useInputBlur } from 'packages/hooks/useInputBlur';
import { isCtrlKey } from '~/helpers/hotKeys';
import { framesToTime, timeToFrames } from '~/helpers/helpers';
import generateTestId from '~/helpers/testIdHelpers';
import { LinkableInput } from './FramePicker.styled';

type Props = {
    name: string;
    value: number;
    supportNegative?: boolean;
    frameRate: number;
    handleOnChange: (value: number) => void;
    autocorrect: (value: number) => number;
    disabled: boolean;
    tabIndex?: number;
    testId?: string;
    icon?: React.ReactNode;
    iconRight?: React.ReactNode;
    label?: string;
    id?: string;
    htmlFor?: string;
    describedby?: string;
    prefix?: string;
    linked?: boolean;
};

const FramePicker = (props: Props) => {
    const {
        name,
        value,
        supportNegative,
        frameRate,
        handleOnChange,
        autocorrect,
        disabled,
        tabIndex,
        testId,
        icon,
        iconRight,
        label,
        id,
        htmlFor,
        describedby,
        prefix,
        linked,
    } = props;
    const { onFocus, unsubscribeListener } = useInputBlur();
    const inputRef = useRef<HTMLInputElement>();
    const [mask, setMask] = useState(value < 0 ? '-99:99:99' : `${supportNegative ? '-' : '9'}9:99:99`);
    const [frameValue, setFrameValue] = useState(framesToTime(value, frameRate.frameRate));

    useEffect(() => {
        setFrameValue(framesToTime(value, frameRate.frameRate));
    }, [value]);

    const beforeMaskedValueChange = (newState, oldState) => {
        let { value, selection } = newState;

        if (supportNegative) {
            if (newState.value.startsWith('-') && !oldState.value.startsWith('-')) {
                value = `-${oldState.value}`;
                setMask('-99:99:99');
                setFrameValue(value);
            } else if (!newState.value.startsWith('-') && oldState.value.startsWith('-')) {
                setMask('-9:99:99');
                setFrameValue(value);
            }
        }

        return {
            selection,
            value,
        };
    };

    const handleTimeToFrames = (time) => timeToFrames(time, frameRate.frameRate);
    const submit = () => {
        const currentFrame = handleTimeToFrames(inputRef?.current?.value);
        const correctFrame = autocorrect(currentFrame);
        setFrameValue(framesToTime(correctFrame, frameRate.frameRate));
        handleOnChange(correctFrame);
    };

    const handleKeyDown = (e) => {
        const nEv = e.nativeEvent;

        if (isCtrlKey(nEv) && (nEv.code === 'KeyZ' || nEv.code === 'KeyY')) {
            nEv.preventDefault();
        }

        if (e.keyCode === 13) {
            submit();
        }
    };

    const onBlur = () => {
        unsubscribeListener();
        submit();
    };

    const inputTestId = generateTestId(testId);

    return (
        <InputMask
            mask={mask}
            maskChar="_"
            formatChars={{
                9: '[0-9]',
                '-': '[-0-9]',
            }}
            alwaysShowMask
            disabled={disabled}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={(newValue) => {
                if (inputRef?.current?.value) {
                    setFrameValue(inputRef?.current?.value);
                } else {
                    setFrameValue(newValue);
                }
            }}
            onKeyDown={handleKeyDown}
            beforeMaskedValueChange={beforeMaskedValueChange}
            value={frameValue}
            tabIndex={tabIndex}
            {...inputTestId}
        >
            {(inputProps) => (
                <LinkableInput
                    {...inputProps}
                    inputRef={inputRef}
                    id={id}
                    name={name}
                    icon={icon}
                    iconRight={iconRight}
                    prefix={prefix}
                    aria-label={label}
                    aria-labelledby={htmlFor}
                    aria-describedby={describedby}
                    isDisabled={disabled}
                    linked={linked}
                />
            )}
        </InputMask>
    );
};

export default FramePicker;
