import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Dropdown } from '@bynder/design-system';
import { IconAdd } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import useThunkDispatch from 'packages/hooks/useThunkDispatch';
import { useRedirectToNewDesign } from 'packages/pages/designs/useRedirectToNewDesign';
import { changePage } from '~/store/general/general.actions';
import { createCreative } from '~/store/creatives/creatives.actions';
import type { IsAvailable, Props } from './types';

export const isCreateDesignAvailable: IsAvailable = ({ hiddenActions }) => !hiddenActions.includes('createDesign');

export const CreateDesign = ({ template, menuActions }: Props) => {
    const { translate } = useTranslate();
    const dispatch = useThunkDispatch();
    const location = useLocation();
    const redirectToNewDesign = useRedirectToNewDesign();

    const onClick = useCallback(() => {
        dispatch(
            changePage({
                back: location.pathname + location.search,
            }),
        );

        redirectToNewDesign(dispatch(createCreative(template, '')));
    }, [template, dispatch]);

    return (
        <Dropdown.Item icon={<IconAdd />} onClick={onClick}>
            {translate('pages.categories.category.template.menu.create')}
        </Dropdown.Item>
    );
};
