export enum ElementUpdateTypes {
    ID = 'ID',
    NAME = 'NAME',
    VISIBILITY = 'VISIBILITY',
    LOCK = 'LOCK',
    CONTENT_PROPERTY = 'CONTENT_PROPERTY',
    RENDER_ORDER = 'RENDER_ORDER',
    TIMEFRAME = 'TIMEFRAME',
    OPACITY = 'OPACITY',
    ROTATION = 'ROTATION',
    SCALE = 'SCALE',
    DROP_SHADOW = 'DROP_SHADOW',
    MASK = 'MASK',
    BLEND_MODE = 'BLEND_MODE',
    POSITION = 'POSITION',
    DIMENSION = 'DIMENSION',
    ANIMATION_IN = 'ANIMATION_IN',
    ANIMATION_OUT = 'ANIMATION_OUT',
    ANIMATIONS = 'ANIMATIONS',
    CHILDREN = 'CHILDREN',
    TEXT = 'TEXT',
    TEXT_RUNS = 'TEXT_RUNS',
    TEXT_STYLES = 'TEXT_STYLES',
    TEXT_BRAND_COLORS = 'TEXT_BRAND_COLORS',
    TEXT_BACKGROUND_BRAND_COLORS = 'TEXT_BACKGROUND_BRAND_COLORS',
    TEXT_EDITOR_DATA = 'TEXT_EDITOR_DATA',
    TEXT_CONTROL = 'TEXT_CONTROL',
    TEXT_DIRECTION = 'TEXT_DIRECTION',
    FONT_FAMILY = 'FONT_FAMILY',
    FONT_COLOR = 'FONT_COLOR',
    FONT_SIZE = 'FONT_SIZE',
    LINE_HEIGHT = 'LINE_HEIGHT',
    CHAR_SPACING = 'CHAR_SPACING',
    CONTENT_TRANSFORM = 'CONTENT_TRANSFORM',
    SOURCE = 'SOURCE',
    NATURAL_DIMENSION = 'NATURAL_DIMENSION',
    DYNAMIC_LENGTH = 'DYNAMIC_LENGTH',
    TIMELINE_BEHAVIOR = 'TIMELINE_BEHAVIOR',
    SHAPE = 'SHAPE',
    FILL_COLOR = 'FILL_COLOR',
    BORDER_COLOR = 'BORDER_COLOR',
    BORDER_WIDTH = 'BORDER_WIDTH',
    BORDER_ALIGNMENT = 'BORDER_ALIGNMENT',
    BORDER_LINE_CAP = 'BORDER_LINE_CAP',
    BORDER_RADIUS = 'BORDER_RADIUS',
    LOCK_UNI_SCALING = 'LOCK_UNI_SCALING',
    MIN_FONT_SCALE = 'MIN_FONT_SCALE',
    FONT_SCALE = 'FONT_SCALE',
    ALLOW_VISIBILITY_TOGGLE = 'ALLOW_VISIBILITY_TOGGLE',
    LIMIT_TEXT_TO_BOUNDS = 'LIMIT_TEXT_TO_BOUNDS',
    SHOW_OVERSET_BOX = 'SHOW_OVERSET_BOX',
    TEXT_BACKGROUND = 'TEXT_BACKGROUND',
    AUDIO_GAIN = 'AUDIO_GAIN',
    AUDIO_FADE_IN = 'AUDIO_FADE_IN',
    AUDIO_FADE_OUT = 'AUDIO_FADE_OUT',
}
