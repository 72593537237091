import { useCallback, useEffect, useRef } from 'react';
import { isInteractiveElement } from '@bynder-studio/misc';
import { PlaybackManipulationRenderer, PlaybackRenderer } from '@bynder-studio/render-web';
import useForceUpdate from '~/hooks/useForceUpdate';
import { isCtrlKey } from '~/helpers/hotKeys';
import { isArrowKey } from 'packages/pages/utils';

export type ControlShortcutMode = 'element' | 'playhead' | '';

const MIN_TIME_FOR_PAN_MS = 500;

const usePlaybackShortcuts = ({
    renderer,
    editorType,
    duration,
    controlShortcutMode = 'playhead',
}: {
    renderer: PlaybackManipulationRenderer | PlaybackRenderer;
    duration: number;
    editorType: 'CC' | 'EDITOR';
    controlShortcutMode?: ControlShortcutMode;
}) => {
    const forceUpdate = useForceUpdate();
    const timestamp = useRef<number>(0);

    const incrementCurrentFrame = useCallback(
        (shiftKey: boolean): void => {
            if (!renderer) {
                return;
            }

            const currentFrame = renderer.getCurrentFrame();

            if (shiftKey) {
                const jumpTo10 = currentFrame + 10 <= duration - 1 ? currentFrame + 10 : duration;
                renderer.setCurrentFrame(jumpTo10);
            } else {
                const nextFrame = currentFrame < duration - 1 ? currentFrame + 1 : duration;
                renderer.setCurrentFrame(nextFrame);
            }
        },
        [renderer, duration],
    );

    const decrementCurrentFrame = useCallback(
        (shiftKey: boolean): void => {
            if (!renderer) {
                return;
            }

            const currentFrame = renderer.getCurrentFrame();

            if (shiftKey) {
                const jumpBackTo10 = currentFrame - 10 >= 0 ? currentFrame - 10 : 0;
                renderer.setCurrentFrame(jumpBackTo10);
            } else {
                const previousFrame = currentFrame > 0 ? currentFrame - 1 : 0;
                renderer.setCurrentFrame(previousFrame);
            }
        },
        [renderer],
    );

    const toFirstFrame = useCallback(() => {
        if (!renderer) {
            return;
        }

        renderer.setCurrentFrame(0);
    }, [renderer]);

    const toLastFrame = useCallback(() => {
        if (!renderer) {
            return;
        }

        renderer.setCurrentFrame(duration - 1);
    }, [renderer, duration]);

    const togglePlayback = useCallback(() => {
        if (!renderer) {
            return;
        }

        if (renderer.getIsPlaying()) {
            renderer.pausePlayback();
        } else {
            renderer.startPlayback();
        }

        forceUpdate();
    }, [renderer]);

    const handleKeyUp = useCallback(
        (e) => {
            if (e.code !== 'Space') {
                return;
            }

            const currentTimestamp = Date.now();
            const timeDiff = currentTimestamp - timestamp.current;

            if (timeDiff < MIN_TIME_FOR_PAN_MS) {
                e.preventDefault();
                togglePlayback();
            }
        },
        [togglePlayback],
    );

    const handleSpaceDown = useCallback((e) => {
        if (!e.repeat) {
            timestamp.current = Date.now();
        }
    }, []);

    const handlePlaybackAction = useCallback(
        (e: KeyboardEvent): void => {
            switch (e.code) {
                case 'ArrowLeft':
                    if (isCtrlKey(e) && e.shiftKey) {
                        toFirstFrame();
                    } else if (!isCtrlKey(e)) {
                        decrementCurrentFrame(e.shiftKey);
                    }
                    break;
                case 'ArrowRight':
                    if (isCtrlKey(e) && e.shiftKey) {
                        toLastFrame();
                    } else if (!isCtrlKey(e)) {
                        incrementCurrentFrame(e.shiftKey);
                    }
                    break;
                default:
                    break;
            }
        },
        [incrementCurrentFrame, decrementCurrentFrame, toLastFrame, toFirstFrame],
    );

    const handleKeyDown = useCallback(
        (event: KeyboardEvent) => {
            if (isInteractiveElement(event)) {
                return;
            }

            if (event.code === 'Space') {
                event.preventDefault();
                handleSpaceDown(event);
                return;
            }

            if (editorType === 'CC' || controlShortcutMode === 'playhead') {
                handlePlaybackAction(event);
            } else if (controlShortcutMode === 'element' && isArrowKey(event)) {
                // Element positioning in image design is implemented in separate hook
                // "useElementPositionShortcuts" because it doesn't require controlShortcutMode logic
                (renderer as PlaybackManipulationRenderer).canvasManipulation.moveElementWithKeys(event);
            }
        },
        [editorType, controlShortcutMode, handlePlaybackAction, renderer],
    );

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('keyup', handleKeyUp);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('keyup', handleKeyUp);
        };
    }, [handleKeyDown, handleKeyUp]);
};

export default usePlaybackShortcuts;
