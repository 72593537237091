import { useCallback, useSyncExternalStore } from 'react';
import useAudio from 'packages/hooks/useAudio';

export default function useElementAudio({ audio, onAudioChange }) {
    const { subscribe, onChange, ...others } = useAudio({ audio, onAudioChange });

    const getUseAudio = useCallback(() => audio?.useAudio ?? false, [audio]);
    const setUseAudio = useCallback(
        (value) => {
            onAudioChange({ useAudio: value });
            onChange();
        },
        [onAudioChange, onChange],
    );

    return {
        useAudio: useSyncExternalStore(subscribe, getUseAudio),
        setUseAudio,
        ...others,
    };
}
