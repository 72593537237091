import React, { useCallback, useEffect, useMemo } from 'react';
import { IconPause, IconSkipPrevious, IconSkipNext, IconPlay } from '@bynder/icons';
import usePlaybackShortcuts from 'packages/hooks/usePlaybackShortcuts';
import useEditor from 'packages/web/src/hooks/useEditor';
import VolumeControl from 'packages/pages/components/VolumeControl';
import { addZeroIfNeeded, convertFrameToSecMinFrame } from '../helpers/frame-to-time-converter';
import useForceUpdate from '../../../../hooks/useForceUpdate';
import {
    TimelineButton,
    TimelineHeaderBtns,
    TimelineHeaderContainer,
    TimelineHeaderTracker,
} from './PlaybackControls.styled';

const convertFrameToTime = (frame, frameRate) => {
    const convertedTime = convertFrameToSecMinFrame(frame, frameRate);

    return `${addZeroIfNeeded(convertedTime.minutes)}:${addZeroIfNeeded(convertedTime.seconds)}:${addZeroIfNeeded(
        convertedTime.frame,
    )}`;
};

const PlaybackControls = ({ controlShortcutMode }) => {
    const forceUpdate = useForceUpdate();
    const { creativeModel, manipulationRenderer, frameRate } = useEditor();
    const duration = creativeModel?.getPlaybackDuration()?.getDuration() || 0;
    const currentFrame = manipulationRenderer?.getCurrentFrame() || 0;
    const isPlaying = manipulationRenderer?.getIsPlaying() || false;

    usePlaybackShortcuts({
        renderer: manipulationRenderer,
        editorType: 'EDITOR',
        controlShortcutMode: controlShortcutMode,
        duration,
    });
    const convertedDuration = useMemo(() => convertFrameToTime(duration, frameRate), [duration, frameRate]);

    const togglePlayback = useCallback(() => {
        if (!manipulationRenderer) {
            return;
        }

        if (isPlaying) {
            manipulationRenderer.pausePlayback();
        } else {
            manipulationRenderer.startPlayback();
        }

        forceUpdate();
    }, [manipulationRenderer, isPlaying, forceUpdate]);

    const toFirstFrame = useCallback(() => {
        if (!manipulationRenderer || manipulationRenderer.getCurrentFrame() <= 0) {
            return;
        }

        manipulationRenderer.setCurrentFrame(0);
    }, [manipulationRenderer]);

    const toLastFrame = useCallback(() => {
        if (!manipulationRenderer || manipulationRenderer.getCurrentFrame() >= duration) {
            return;
        }

        manipulationRenderer.setCurrentFrame(duration - 1);
    }, [manipulationRenderer, duration]);

    const stopPlaybackOnFocus = useCallback(
        (e) => {
            if (!e.target.closest('.right--previewerSidebar') || !manipulationRenderer.getIsPlaying()) {
                return;
            }

            manipulationRenderer.pausePlayback();
        },
        [manipulationRenderer],
    );

    useEffect(() => {
        if (!manipulationRenderer) {
            return;
        }

        manipulationRenderer.eventEmitter.on('frameUpdate', forceUpdate);

        return () => {
            manipulationRenderer.canvasManipulation.emitter.off('frameUpdate', forceUpdate);
        };
    }, [manipulationRenderer, forceUpdate]);

    useEffect(() => {
        if (!manipulationRenderer) {
            return;
        }

        window.addEventListener('mousedown', stopPlaybackOnFocus);

        return () => {
            window.removeEventListener('mousedown', stopPlaybackOnFocus);
        };
    }, [manipulationRenderer]);

    return (
        <TimelineHeaderContainer>
            <TimelineHeaderTracker>
                <span>{convertFrameToTime(currentFrame, frameRate)}</span>
                {' / '}
                {convertedDuration}
            </TimelineHeaderTracker>
            <TimelineHeaderBtns>
                <TimelineButton
                    name="previousButton"
                    disabled={currentFrame === 0}
                    onClick={toFirstFrame}
                    onMouseDown={(e) => e.preventDefault()}
                >
                    <IconSkipPrevious />
                </TimelineButton>
                <TimelineButton name="playButton" onClick={togglePlayback} onMouseDown={(e) => e.preventDefault()}>
                    {isPlaying ? <IconPause /> : <IconPlay />}
                </TimelineButton>
                <TimelineButton
                    name="nextButton"
                    disabled={currentFrame >= duration - 1}
                    onClick={toLastFrame}
                    onMouseDown={(e) => e.preventDefault()}
                >
                    <IconSkipNext />
                </TimelineButton>
            </TimelineHeaderBtns>
            <VolumeControl creativeModel={creativeModel} size="s" />
        </TimelineHeaderContainer>
    );
};

export default PlaybackControls;
