import { useCallback, useRef, useSyncExternalStore } from 'react';
import { AudioElement, VideoElement } from '@bynder-studio/render-core';

type Props = {
    audio?: AudioElement | VideoElement;
    onAudioChange: (params: any) => void;
};

export default function useAudio({ audio, onAudioChange }: Props) {
    const listenersRef = useRef(new Set<() => void>());

    const subscribe = useCallback(
        (listener: () => void) => {
            listenersRef.current.add(listener);

            return () => {
                listenersRef.current.delete(listener);
            };
        },
        [listenersRef],
    );

    const onChange = useCallback(() => listenersRef.current.forEach((listener) => listener()), [listenersRef]);

    const getGain = useCallback(() => audio?.gain || 0, [audio]);
    const setGain = useCallback(
        (gain: number) => {
            onAudioChange({ gain });
            onChange();
        },
        [onAudioChange, onChange],
    );

    const getFadeIn = useCallback(() => audio?.fadeIn || 0, [audio]);
    const getFadeOut = useCallback(() => audio?.fadeOut || 0, [audio]);
    const setFadeInOut = useCallback(
        (fadeType: 'fadeIn' | 'fadeOut') => (value: number) => {
            onAudioChange({ [fadeType]: value });
            onChange();
        },
        [onAudioChange, onChange],
    );

    const getLocked = useCallback(() => audio?.locked ?? false, [audio]);
    const setLocked = useCallback(
        (value) => {
            onAudioChange({ locked: value });
            onChange();
        },
        [onAudioChange, onChange],
    );

    return {
        gain: useSyncExternalStore(subscribe, getGain),
        fadeIn: useSyncExternalStore(subscribe, getFadeIn),
        fadeOut: useSyncExternalStore(subscribe, getFadeOut),
        locked: useSyncExternalStore(subscribe, getLocked),
        setLocked,
        setGain,
        setFadeInOut,
        subscribe,
        onChange,
    };
}
