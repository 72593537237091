import { equals, Scrollbars } from '@bynder-studio/render-core';
import { getRandomHex, rgbToHex } from '../../Helpers/colors';

export type DrawElementParams = {
    id: number | string;
    x: number;
    y: number;
    width: number;
    height: number;
    boxX: number;
    boxY: number;
    boxWidth: number;
    boxHeight: number;
    rotation: number;
    scale: number;
    children?: DrawElementParams[];
};

export type DrawScrollbarParams = {
    horizontalScaleRatio: number;
    horizontalScrollbarPosition: number;
    verticalScaleRatio: number;
    verticalScrollbarPosition: number;
};

export class ElementSelectionHelper {
    private colorToElementId: Map<string, number | string> = new Map();

    private scrollbarParams?: DrawScrollbarParams;

    private devicePixelRatio = 1;

    private origin: [number, number] = [0, 0];

    private elements: DrawElementParams[] = [];

    private isDirty = true;

    private canvas: HTMLCanvasElement = null;

    private ctx = null;

    constructor() {
        this.canvas = document.createElement('canvas');
        this.ctx = this.canvas.getContext('2d', { willReadFrequently: true });
    }

    setSize(width: number, height: number) {
        if (this.canvas.width === width && this.canvas.height === height) {
            return;
        }

        this.canvas.width = width;
        this.canvas.height = height;
        this.isDirty = true;
    }

    setScrollbarParams(scrollbarParams: DrawScrollbarParams) {
        if (this.scrollbarParams && equals(this.scrollbarParams, scrollbarParams)) {
            return;
        }

        this.scrollbarParams = scrollbarParams;
        this.isDirty = true;
    }

    setDevicePixelRatio(ratio: number) {
        if (this.devicePixelRatio === ratio) {
            return;
        }

        this.devicePixelRatio = ratio;
        this.isDirty = true;
    }

    setOrigin(x: number, y: number) {
        const origin = [x, y];

        if (equals(origin, this.origin)) {
            return;
        }

        this.origin = [x, y];
        this.isDirty = true;
    }

    setElements(elements: DrawElementParams[]) {
        if (equals(this.elements, elements)) {
            return;
        }

        this.elements = elements;
        this.isDirty = true;
    }

    draw() {
        if (!this.isDirty) {
            return;
        }

        this.isDirty = false;
        this.colorToElementId.clear();
        this.ctx.save();
        this.ctx.translate(this.origin[0], this.origin[1]);
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.elements.forEach((el) => {
            this.drawElement(el);
        });
        this.ctx.restore();
        this.drawScrollbars();
    }

    getElementId(x: number, y: number): number | string | undefined {
        const colorRGB = this.ctx.getImageData(x, y, 1, 1).data;
        const colorHEX = '#' + ('000000' + rgbToHex(colorRGB[0], colorRGB[1], colorRGB[2])).slice(-6);

        return this.colorToElementId.get(colorHEX);
    }

    private generateColor(): string {
        let color = getRandomHex();

        while (this.colorToElementId.has(color)) {
            color = getRandomHex();
        }

        return color;
    }

    private drawElement(el: DrawElementParams) {
        this.ctx.save();
        this.ctx.translate(el.x + el.boxX, el.y + el.boxY);

        if (el.rotation % 360 !== 0) {
            this.ctx.translate(el.width / 2, el.height / 2);
            this.ctx.rotate((Math.PI / 180) * el.rotation);
            this.ctx.translate(-el.width / 2, -el.height / 2);
        }

        if (el.scale !== 1) {
            this.ctx.translate((el.width / 2) * (1 - el.scale), (el.height / 2) * (1 - el.scale));
            this.ctx.scale(el.scale, el.scale);
        }

        const color = this.generateColor();
        this.colorToElementId.set(color, el.id);

        this.ctx.fillStyle = color;

        this.ctx.fillRect(0, 0, el.boxWidth, el.boxHeight);

        if (el.children) {
            el.children.forEach((childEl) => {
                this.drawElement(childEl);
            });
        }

        this.ctx.restore();
    }

    private drawScrollbars() {
        if (!this.scrollbarParams) {
            return;
        }

        const thickness = this.devicePixelRatio * 20;
        const workspaceW = this.ctx.canvas.width;
        const workspaceH = this.ctx.canvas.height;
        const padding = this.devicePixelRatio * 4;
        const offset = padding + this.devicePixelRatio * 8;

        const { horizontalScaleRatio, horizontalScrollbarPosition, verticalScaleRatio, verticalScrollbarPosition } =
            this.scrollbarParams;

        this.ctx.save();
        this.ctx.resetTransform();

        if (horizontalScaleRatio !== 1) {
            const color = this.generateColor();
            this.ctx.fillStyle = color;
            this.colorToElementId.set(color, Scrollbars.HORIZONTAL);
            const w = workspaceW - (padding + offset);
            this.ctx.fillRect(
                padding + horizontalScrollbarPosition * w,
                workspaceH - thickness,
                horizontalScaleRatio * w,
                thickness,
            );
        }

        if (verticalScaleRatio !== 1) {
            const color = this.generateColor();
            this.ctx.fillStyle = color;
            this.colorToElementId.set(color, Scrollbars.VERTICAL);
            const h = workspaceH - (padding + offset);
            this.ctx.fillRect(
                workspaceW - thickness,
                padding + verticalScrollbarPosition * h,
                thickness,
                verticalScaleRatio * h,
            );
        }

        this.ctx.restore();
    }
}
