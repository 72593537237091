import {
    AudioAsset,
    Dimension,
    DynamicEventEmitter,
    equals,
    FontAsset,
    type FontAssetParams,
    type IAsset,
    type IAssetsLoader,
    ImageAsset,
    loadFont,
    sleep,
    VideoAsset,
} from '@bynder-studio/render-core';
import createBrowserMediaController, { connectMediaNodes, updateMediaController } from './createBrowserMediaController';
import getImageFrame from './getImageFrame';

const BASE_RETRY_TIMEOUT = 3_000;

export class BrowserAssetsLoader implements IAssetsLoader {
    eventEmitter: DynamicEventEmitter = new DynamicEventEmitter();

    private assets: Map<string, IAsset>;

    private waiting: {
        [key: string]: Function[];
    };

    private glyphPath: {
        [fontId: number | string]: {
            [glyphId: number]: Path2D;
        };
    } = {};

    constructor() {
        this.assets = new Map();
        this.waiting = {};
    }

    setAsset(asset: IAsset) {
        // console.log('setAsset', asset);
        const oldAsset = this.assets.get(asset.id);

        if (oldAsset && oldAsset.src === asset.src) {
            const v1 = oldAsset as VideoAsset;
            const v2 = asset as VideoAsset;

            if ((oldAsset instanceof VideoAsset || oldAsset instanceof AudioAsset) && oldAsset.object) {
                if (
                    v1.startFrame !== v2.startFrame ||
                    v1.offsetTime !== v2.offsetTime ||
                    v1.frameRate !== v2.frameRate ||
                    v1.duration !== v2.duration
                ) {
                    if (oldAsset.object.mediaController) {
                        oldAsset.object.mediaController.destroy();
                    }

                    oldAsset.object.mediaController = createBrowserMediaController.call(oldAsset.object, asset);
                    connectMediaNodes(oldAsset.object);
                }
            }

            Object.keys(asset).forEach((key) => {
                if (key === 'frameBuffer') {
                    if (
                        v1.startFrame === v2.startFrame &&
                        v1.offsetTime === v2.offsetTime &&
                        v1.frameRate === v2.frameRate &&
                        v1.duration === v2.duration &&
                        v1.isAlpha === v2.isAlpha &&
                        v1.naturalDimension.equals(v2.naturalDimension)
                    ) {
                        oldAsset[key] = asset[key];
                    }
                    return;
                }

                if (['object', 'objectClone', 'accessible', 'loading', 'width', 'height', 'buffering'].includes(key)) {
                    return;
                }

                if (asset[key] !== undefined) {
                    oldAsset[key] = asset[key];
                }
            });

            return;
        }

        this.destroyAsset(asset.id);
        this.assets.set(asset.id, asset);
    }

    clearAssets() {
        // console.log('clearAssets');
        this.assets = new Map();
    }

    getFonts() {
        const fonts: (FontAssetParams & { object: ArrayBuffer })[] = [];
        this.assets.forEach((asset) => {
            if (asset instanceof FontAsset && asset.object) {
                fonts.push({
                    id: asset.id,
                    src: asset.src,
                    fontId: asset.fontId,
                    object: asset.object,
                });
            }
        });

        return fonts;
    }

    setFonts(fonts: (FontAssetParams & { object: ArrayBuffer })[]) {
        fonts.forEach((font) => {
            const fontAsset = new FontAsset({
                id: font.id,
                src: font.src,
                fontId: font.fontId,
            });
            fontAsset.object = font.object;
            this.setAsset(fontAsset);
        });
    }

    getAsset<T extends IAsset>(assetId: string): T {
        return this.assets.get(assetId) as T;
    }

    destroyAsset(assetId: string): void {
        // console.log('destroyAsset', assetId);

        if (!this.assets.has(assetId)) {
            return;
        }

        const asset = this.assets.get(assetId);
        this.assets.delete(assetId);

        if (asset instanceof VideoAsset || asset instanceof AudioAsset) {
            asset.destroy();
        }
    }

    getAllAssets(): Map<string, IAsset> {
        return this.assets;
    }

    updateAsset(id: string, data: any): void {
        // console.log('updateAsset', id, data);
        const isAssetChanged = (asset) =>
            Object.entries(data).some(
                ([key, value]) =>
                    !equals(
                        value,
                        typeof asset[key] === 'object' && 'toObject' in asset[key] ? asset[key].toObject() : asset[key],
                    ),
            );

        this.assets.forEach((asset) => {
            // @ts-ignore
            if (asset.id === id && isAssetChanged(asset)) {
                const dataToUpdate = { ...data };

                if (data.naturalDimension) {
                    dataToUpdate.naturalDimension = new Dimension(
                        data.naturalDimension.width,
                        data.naturalDimension.height,
                    );
                }

                if (asset instanceof VideoAsset || asset instanceof AudioAsset) {
                    asset.destroy();
                }

                asset.update(dataToUpdate);
                this.loadAsset(asset);
            }
        });
    }

    private loadAsset(asset: IAsset) {
        // todo: add type property to asset and use switch
        if (asset instanceof ImageAsset) {
            return this.loadImage(asset);
        }

        if (asset instanceof VideoAsset) {
            return this.loadVideo(asset);
        }

        if (asset instanceof AudioAsset) {
            return this.loadAudio(asset);
        }

        if (asset instanceof FontAsset) {
            return this.loadFont(asset);
        }
    }

    private dispatchLoadEvent(asset: IAsset, type: 'image' | 'video' | 'audio' | 'font') {
        queueMicrotask(() =>
            this.eventEmitter.emit('asset.load', {
                asset,
                type,
            }),
        );
    }

    loadImage(asset: ImageAsset): Promise<ImageAsset> {
        // console.log('load image', asset);
        const imageAsset = this.getAsset(asset.id) as ImageAsset;

        if (imageAsset.isAccessible()) {
            // console.log('image is accessible');

            return Promise.resolve(imageAsset);
        }

        if (this.waiting[imageAsset.id]) {
            // console.log('waiting for image');

            return new Promise((resolve) => {
                this.waiting[imageAsset.id].push(resolve);
            });
        }

        this.waiting[imageAsset.id] = [];
        imageAsset.setLoading(true);

        const loadedAsset = loadImageWithRetry(imageAsset);

        loadedAsset.then(() => {
            this.waiting[imageAsset.id].forEach((cb) => cb(imageAsset));
            delete this.waiting[imageAsset.id];

            this.dispatchLoadEvent(imageAsset, 'image');
        });

        return loadedAsset;
    }

    loadVideo(asset: VideoAsset): Promise<VideoAsset> {
        // console.log('load video', asset);
        const videoAsset = this.getAsset(asset.id) as VideoAsset;

        if (videoAsset.isAccessible()) {
            // console.log('video is accessible');

            return Promise.resolve(videoAsset);
        }

        if (this.waiting[videoAsset.id]) {
            // console.log('waiting for video');

            return new Promise((resolve) => {
                this.waiting[videoAsset.id].push(resolve);
            });
        }

        this.waiting[videoAsset.id] = [];

        videoAsset.setLoading(true);
        videoAsset.objectClone = null;

        const loadingAsset = loadMediaElementWithRetry(videoAsset, 'video');

        loadingAsset.then(() => {
            this.waiting[videoAsset.id].forEach((cb) => cb(videoAsset));
            delete this.waiting[videoAsset.id];

            this.dispatchLoadEvent(videoAsset, 'video');
        });

        return loadingAsset;
    }

    loadAudio(asset: AudioAsset): Promise<AudioAsset> {
        // console.log('load audio', asset);
        const audioAsset = this.getAsset(asset.id) as AudioAsset;

        if (audioAsset.isAccessible()) {
            // console.log('audio is accessible');

            updateMediaController(audioAsset);

            return Promise.resolve(audioAsset);
        }

        if (this.waiting[audioAsset.id]) {
            // console.log('waiting for audio');

            return new Promise((resolve) => {
                this.waiting[audioAsset.id].push(resolve);
            });
        }

        this.waiting[audioAsset.id] = [];
        audioAsset.setLoading(true);

        const loadingAsset = loadMediaElementWithRetry(audioAsset, 'audio');

        loadingAsset.then(() => {
            this.waiting[audioAsset.id]?.forEach((cb) => cb(audioAsset));
            delete this.waiting[audioAsset.id];

            this.dispatchLoadEvent(audioAsset, 'audio');
        });

        return loadingAsset;
    }

    loadFont(fontAsset: FontAsset): Promise<FontAsset> {
        // console.log('load font', fontAsset);

        if (this.assets.has(fontAsset.id)) {
            // console.log('font is already loaded');
            const asset = this.assets.get(fontAsset.id);

            // @ts-ignore
            if (asset.object) {
                return new Promise((resolve) => {
                    // @ts-ignore
                    resolve(this.assets.get(asset.id));
                });
            }
        }

        if (this.waiting[fontAsset.id]) {
            // console.log('waiting for font');

            return new Promise((resolve) => {
                this.waiting[fontAsset.id].push(resolve);
            });
        }

        this.waiting[fontAsset.id] = [];
        fontAsset.setLoading(true);

        const loadedAsset = loadFontWithRetry(fontAsset)
            .then((arrayBuffer) => {
                fontAsset.object = arrayBuffer;
                fontAsset.setAccessible(true);
                fontAsset.setLoading(false);
                this.assets.set(fontAsset.id, fontAsset);

                return fontAsset;
            })
            .catch((err) => {
                fontAsset.setAccessible(false);
                fontAsset.setLoading(false);

                return err;
            });

        loadedAsset.then(() => {
            this.waiting[fontAsset.id].forEach((cb) => cb(fontAsset));
            delete this.waiting[fontAsset.id];

            this.dispatchLoadEvent(fontAsset, 'font');
        });

        return loadedAsset;
    }

    async loadAll() {
        let promises = [];
        this.assets.forEach((asset) => {
            if (asset instanceof FontAsset) {
                promises.push(this.loadFont(asset));
            } else if (asset instanceof ImageAsset) {
                promises.push(this.loadImage(asset));
            } else if (asset instanceof VideoAsset) {
                promises.push(this.loadVideo(asset));
            } else if (asset instanceof AudioAsset) {
                promises.push(this.loadAudio(asset));
            }
        });
        const loadedAssets = await Promise.all(promises.filter((p) => p));
        loadedAssets.forEach((loadedAsset) => {
            this.assets.set(loadedAsset.id, loadedAsset);
        });
    }

    makePath(glyph): Path2D {
        const { fontId, glyphId, path } = glyph;

        if (!this.glyphPath[fontId]) {
            this.glyphPath[fontId] = {};
        }

        if (!this.glyphPath[fontId][glyphId]) {
            this.glyphPath[fontId][glyphId] = new Path2D(path);
        }

        return this.glyphPath[fontId][glyphId];
    }
}

async function loadFontWithRetry(asset: FontAsset, attempts = 1) {
    let arrayBuffer: ArrayBuffer;

    try {
        arrayBuffer = await loadFont(asset.src);
    } catch (err) {
        console.warn('Failed to load font', asset.src, err);
    }

    if (arrayBuffer) {
        return arrayBuffer;
    }

    if (attempts > 5) {
        throw new Error('Failed to load font');
    }

    await sleep(getTimeoutFromAttempt(attempts));

    return loadFontWithRetry(asset, attempts + 1);
}

async function loadImageWithRetry(asset: ImageAsset, attempt = 1): Promise<ImageAsset> {
    const image = createImageElement(asset);

    try {
        await image.decode();

        asset.setMediaData(image);
        asset.setAccessible(true);
        asset.setLoading(false);

        return asset;
    } catch (err) {
        if (attempt >= 5) {
            asset.setAccessible(false);
            asset.setLoading(false);
            throw new Error('Failed to load image asset');
        }

        await sleep(getTimeoutFromAttempt(attempt));

        return loadImageWithRetry(asset, attempt + 1);
    }
}

function loadMediaElementWithRetry<T extends VideoAsset | AudioAsset>(asset: T, type: 'video' | 'audio'): Promise<T> {
    return new Promise((res, rej) => {
        let element: HTMLMediaElement;

        if (type === 'video') {
            element = createVideoElement(asset as VideoAsset);
        } else if (type === 'audio') {
            element = createAudioElement(asset as AudioAsset);
        } else {
            rej('unknown asset type');
        }

        let attempt = 1;

        const retryWithTimeout = () => {
            setTimeout(() => {
                element.load();
            }, getTimeoutFromAttempt(attempt));
        };

        const failWithError = () => {
            asset.setAccessible(false);
            rej('failed to load video asset');
            element.load();
        };

        const onError = () => {
            if (++attempt < 5) {
                failWithError();
            } else {
                retryWithTimeout();
            }
        };

        const onLoadedmetadata = () => {
            element.removeEventListener('error', onError);

            if (type === 'video') {
                const elementClone = element.cloneNode(true);
                // @ts-ignore
                element.mediaController = createBrowserMediaController.call(element, asset);
                connectMediaNodes(element);
                // @ts-ignore
                asset.setMediaData(element, elementClone, getImageFrame);
            } else if (type === 'audio') {
                // @ts-ignore
                element.mediaController = createBrowserMediaController.call(element, asset);
                connectMediaNodes(element);
                // @ts-ignore
                asset.setMediaData(element);
            }

            asset.setAccessible(true);
            asset.setLoading(false);

            res(asset);
        };

        element.addEventListener('loadedmetadata', onLoadedmetadata, {
            once: true,
        });

        element.addEventListener('error', onError);
    });
}

function createImageElement(asset: ImageAsset) {
    const image = new Image();
    image.crossOrigin = 'anonymous';
    image.src = asset.src;

    // increase priority for images to load faster
    // doesn't work in all browsers
    if ('fetchPriority' in image) {
        image.fetchPriority = 'high';
    }

    return image;
}

function createVideoElement(asset: VideoAsset) {
    const video = document.createElement('video');
    video.id = asset.id;
    video.muted = !asset.useAudio;
    video.autoplay = false;
    video.crossOrigin = 'anonymous';
    video.preload = 'auto';
    video.volume = 0.2;
    video.src = asset.src;

    return video;
}

function createAudioElement(asset: AudioAsset) {
    const audio = document.createElement('audio');
    audio.id = asset.id;
    audio.muted = false;
    audio.autoplay = false;
    audio.crossOrigin = 'anonymous';
    audio.preload = 'auto';
    audio.volume = 0.2;
    audio.src = asset.src;

    return audio;
}

function getTimeoutFromAttempt(attempt: number) {
    return BASE_RETRY_TIMEOUT * attempt;
}
