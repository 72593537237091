import { useEffect } from 'react';
import { Creative, CreativeStatus } from 'packages/store/creatives/types';

export const CATEGORIES_PAGE_SIZE = 40;
export const TEMPLATES_PAGE_SIZE = 40;
export const UPLOADS_PAGE_SIZE = 40;
export const GALLERY_PAGE_SIZE = 40;

export const filterDesignObjForCopy = (design: Creative) => {
    return {
        ...design,
        id: undefined,
        name: undefined,
        firstPublishDate: undefined,
        lastPublishDate: undefined,
        status: CreativeStatus.PUBLISHED,
        updated: undefined,
        created: undefined,
        thumbnailImage: undefined,
        hasPublishedVideo: undefined,
        grantedPermissions: undefined,
        approvalStatus: undefined,
    };
};

export function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
        return '0 Bytes';
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function removeEmptyProps(obj) {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
}

export const toPercent = (v) => Math.round(Number(v) * 100);
export const fromPercent = (v) => Number((v / 100).toFixed(2));
export const toValue = (v) => Number(v) / 100;

export const isArrowKey = (event: KeyboardEvent) =>
    event.code === 'ArrowLeft' || event.code === 'ArrowUp' || event.code === 'ArrowRight' || event.code === 'ArrowDown';

export const useScrollImproved = (entries, gridContainer, scrollContainerRef = '') => {
    useEffect(() => {
        const scrollContainer = document.querySelectorAll(
            scrollContainerRef.length ? scrollContainerRef : '.ReactVirtualized__Masonry',
        );
        const gridCoordinates = gridContainer.current?.getBoundingClientRect();
        let gridY = gridCoordinates?.y;
        let gridX = gridCoordinates?.x;

        if (scrollContainerRef.length) {
            gridY = scrollContainer[0].getBoundingClientRect().y;
            gridX = scrollContainer[0].getBoundingClientRect().x;
        }

        function onWheel(e) {
            const target = e.target as HTMLElement;

            // Prevent scrolling to fire up when modal is open
            if (target.closest('.react-responsive-modal-root')) {
                return;
            }

            if (e.clientY < gridY && e.clientX >= gridX) {
                e.preventDefault();
                const scrollOffsetY = e.deltaY;

                if (scrollContainer.length > 0) {
                    scrollContainer[0].scrollBy(0, scrollOffsetY);
                }
            }
        }

        if (entries.length > 0) {
            if (gridX && gridY) {
                document?.getElementById('scroll-wrapper')?.addEventListener('wheel', onWheel, { passive: false });
            }
        }

        return () => {
            document?.getElementById('scroll-wrapper')?.removeEventListener('wheel', onWheel);
        };
    }, [entries, gridContainer]);
};

export const decimal = (num: number, degree = 3) => Math.round(num * Math.pow(10, degree)) / Math.pow(10, degree);
