import styled from 'styled-components';
import { Input, token } from '@bynder/design-system';

// mimic placeholder style for masked input
export const LinkableInput = styled(Input)<{ linked?: boolean; iconRight?: never }>`
    padding-right: ${(props) => !props.iconRight && '0'};

    & input:not(:focus) {
        color: ${(props) => props.linked && `${token.colorTextDisabled} !important`};
    }
`;
