import React, { useCallback, useEffect, useState } from 'react';
import { type CreativeModelFactory } from '@bynder-studio/render-web';
import { ignoreAbortErrors, makeAbortable } from 'packages/helpers/helpers';
import useCurrentPage from './useCurrentPage';

type Renderer = ReturnType<CreativeModelFactory['getCreativeRenderer']>;

export function useCreativeRenderer(
    factory: CreativeModelFactory | null,
    canvasWrapperRef: React.RefObject<HTMLElement>,
) {
    const [renderer, setRenderer] = useState<Renderer | null>(null);

    const handleSizeChange = useCallback(() => {
        if (!renderer) {
            return;
        }

        renderer.setScale(renderer.calculateScaleRatio());
        renderer.redraw();
    }, [renderer]);

    useCurrentPage(factory?.getCreativeModel(), handleSizeChange);

    useEffect(() => {
        const abortController = new AbortController();
        const cleanup = () => {
            abortController.abort();
        };

        if (!canvasWrapperRef.current || !factory) {
            return cleanup;
        }

        canvasWrapperRef.current.innerHTML = '';
        setRenderer(null);

        const newRenderer = factory.getCreativeRenderer(canvasWrapperRef.current);

        makeAbortable(newRenderer.init(), abortController)
            .then(() => setRenderer(newRenderer))
            .catch(ignoreAbortErrors)
            .catch(console.error);

        return cleanup;
    }, [canvasWrapperRef, factory]);

    useEffect(() => {
        if (!canvasWrapperRef.current) {
            return;
        }

        const resizeObserver = new ResizeObserver(() => {
            if (!canvasWrapperRef.current) {
                return;
            }

            handleSizeChange();
        });

        resizeObserver.observe(canvasWrapperRef.current);

        return () => {
            resizeObserver.disconnect();
        };
    }, [canvasWrapperRef, handleSizeChange]);

    return renderer;
}
