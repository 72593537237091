import React, { useCallback } from 'react';
import { useTranslate } from '@bynder/localization';
import { List, Thumbnail } from '@bynder/design-system';
import { IconVolumeOff, IconVolumeUp } from '@bynder/icons';
import generateTestId from '~/helpers/testIdHelpers';
import features from '~/configs/features';
import useAccessRights from 'packages/hooks/useAccessRights';
import useAudioHook from 'packages/hooks/useElementAudio';
import { AudioFeature } from '../../../FormComponents/AudioFeature';

const listItemId = generateTestId('shots_video_use_audio');

const Audio = ({ creativeModel, selectedElement, disabled = false }) => {
    const { translate } = useTranslate();
    const { isPlatformAllowed } = useAccessRights();

    const onAudioChange = useCallback(
        (params) => {
            creativeModel.updateElement(selectedElement.id, params);
        },
        [creativeModel, selectedElement?.id],
    );

    const { useAudio, gain, locked, fadeIn, fadeOut, setUseAudio, setGain, setFadeInOut } = useAudioHook({
        audio: selectedElement,
        onAudioChange,
    });

    return (
        <>
            <List.Item
                thumbnail={<Thumbnail size="m" icon={useAudio ? <IconVolumeUp /> : <IconVolumeOff />} />}
                checkedVariant="switch"
                onClick={() => {
                    setUseAudio(!useAudio);
                }}
                isChecked={useAudio}
                isDisabled={locked || disabled}
                {...listItemId}
            >
                {translate('editor.sidebar.shots.video.use-audio.label')}
            </List.Item>
            {isPlatformAllowed([features.MULTI_TRACK_AUDIO]) && useAudio && (
                <AudioFeature
                    isDisabled={locked || disabled}
                    gain={gain}
                    fadeIn={fadeIn}
                    fadeOut={fadeOut}
                    onGainChange={setGain}
                    onFadeInChange={setFadeInOut('fadeIn')}
                    onFadeOutChange={setFadeInOut('fadeOut')}
                />
            )}
        </>
    );
};

export default Audio;
