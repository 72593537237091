export enum AssetType {
    VIDEO = 'VIDEO',
    AUDIO = 'AUDIO',
    IMAGE = 'IMAGE',
    PSD = 'PSD',
    SKETCH = 'SKETCH',
}

export enum AssetStatus {
    CREATED = 'CREATED',
    UPLOAD_IN_PROGRESS = 'UPLOAD_IN_PROGRESS',
    CANCELLED = 'CANCELLED',
    COMPLETED = 'COMPLETED',
    PENDING_PROCESSING = 'PENDING_PROCESSING',
    RETRY_PROCESSING = 'RETRY_PROCESSING',
    PROCESSED = 'PROCESSED',
    PROCESSING_IN_PROGRESS = 'PROCESSING_IN_PROGRESS',
    FAILED = 'FAILED',
}

export type Asset = {
    checked: boolean;
    collectionIds: (string | number)[];
    created: string;
    customer: {
        accountType: string;
        bynderAccountId: string;
        bynderBrandId: string;
        bynderPortalUrl: string;
        id: number;
        name: string;
        type: string;
    };
    derivativeType: string;
    extension: string;
    fileName: string;
    fileSize: number;
    fileUrl: string;
    gallery: boolean;
    height: number;
    id: string;
    isAlpha: boolean;
    sourceType: string;
    status: AssetStatus;
    thumbnailStatus: string;
    thumbnails: {
        [key: string]: string;
    };
    transformations: any[];
    type: AssetType;
    updated: string;
    width: number;
};
