import { useCallback, useContext } from 'react';
import { ElementTypes } from '@bynder-studio/render-core';
import useDesign from './useDesign';
import { VariationsContext } from '../VariationsContext/VariationsContext';
import { VariationsThumbnailsContext } from 'packages/pages/design/VariationsContext/VariationsThumbnailsProvider';

type GlobalPropertyType = ElementTypes.BACKGROUND_COLOR | ElementTypes.GLOBAL_AUDIO | ElementTypes.POSTER_FRAME;

export default function useVariations() {
    const {
        page,
        isLoading,
        isLastPage,
        currentVariation,
        updateVariationElement: updateVariationElementOriginal,
        updateVariationGlobalProperty: updateVariationGlobalPropertyOriginal,
        ...ctx
    } = useContext(VariationsContext);

    const { creativeModel } = useDesign();
    const { variationThumbnailRenderer, swapVariationThumbnailModel } = useContext(VariationsThumbnailsContext);

    const updateVariationElement = useCallback(
        (pageId, elementId, newValues) => {
            updateVariationElementOriginal(pageId, elementId, newValues);
            updateThumbnailRenderModel(elementId, newValues, pageId);
        },
        [updateVariationElementOriginal, currentVariation, creativeModel, variationThumbnailRenderer],
    );

    const updateVariationGlobalProperty = useCallback(
        (elementType: GlobalPropertyType, elementId, newValues) => {
            updateVariationGlobalPropertyOriginal(elementId, newValues);
            updateThumbnailRenderModel(elementType, newValues);
        },
        [updateVariationGlobalPropertyOriginal, currentVariation, creativeModel, variationThumbnailRenderer],
    );

    const updateThumbnailRenderModel = useCallback(
        (elementId: GlobalPropertyType | number | string, newValues: any, pageId = '') => {
            if (!creativeModel) {
                return;
            }

            const model = variationThumbnailRenderer.getModel(
                currentVariation,
                pageId || creativeModel.getCurrentPageId(),
            );
            const parsedElementId = Number(elementId);
            const isGlobalProperty = isNaN(parsedElementId);

            if (model) {
                if (isGlobalProperty) {
                    if ((elementId as GlobalPropertyType) !== ElementTypes.GLOBAL_AUDIO) {
                        model.updateGlobalProperty(elementId as GlobalPropertyType, newValues);
                    }
                } else {
                    model.updateElement(parsedElementId, newValues);
                }
            }
        },
        [creativeModel, variationThumbnailRenderer, currentVariation],
    );

    return {
        ...ctx,
        currentVariation,
        updateVariationElement,
        updateVariationGlobalProperty,
        isLoadingVariations: isLoading,
        variationsPage: page,
        isLastVariationsPage: isLastPage,
        updateThumbnailRenderModel,
        swapVariationThumbnailModel,
    };
}
