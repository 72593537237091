import styled from 'styled-components';
import { token } from '@bynder/design-system';

export const SliderWrapper = styled.div`
    width: 100%;
    margin-right: ${token.spacing3};
`;

export const InputWrapper = styled.div`
    width: 98px;
`;
