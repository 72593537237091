import React, { type FC } from 'react';
import { Slider } from '@bynder/design-system';
import { decimal } from 'packages/pages/utils';

const gainToSliderValue = (gain: number, min: number, max: number) => {
    if (gain < 0) {
        return decimal((gain / min) * -100, 1);
    }

    if (gain > 0) {
        return decimal((gain / max) * 100, 1);
    }

    return 0;
};

const sliderValueToGain = (value: number, min: number, max: number) => {
    if (value < 0) {
        return decimal((value / -100) * min, 1);
    }

    if (value > 0) {
        return decimal((value / 100) * max, 1);
    }

    return 0;
};

type GainSliderProps = {
    min: number;
    max: number;
    gain: number;
    onGainChange: (gain: number) => void;
    isDisabled: boolean;
};

export const GainSlider: FC<GainSliderProps> = ({ min, max, gain, onGainChange, isDisabled }) => {
    const value = gainToSliderValue(gain, min, max);

    const handleChange = (newValue: number) => {
        onGainChange(sliderValueToGain(newValue, min, max));
    };

    return (
        <Slider
            min={-100}
            max={100}
            step="0.1"
            value={value}
            onChange={handleChange}
            isDisabled={isDisabled}
            isFromCenter
            isTooltipHidden
            aria-labelledby="audio-volume-label"
        />
    );
};
