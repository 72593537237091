import React, { useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button, EmptyState, SkeletonText } from '@bynder/design-system';
import { IconAdd, IconDelete, IconUserGroup, IconMasonry, IconFileMove } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import useThunkDispatch from 'packages/hooks/useThunkDispatch';
import type { onDesignClick } from 'packages/pages/designs/components/DesignCard/types';
import { Creative, CreativeStatus } from 'packages/store/creatives/types';
import {
    getCreatives,
    getCreativesSelection,
    isCreativesFirstLoaded,
    getPendingCreatives,
} from 'packages/store/creatives/creatives.selectors';
import useCreativeThumbnailsUpdates from 'packages/hooks/useCreativeThumbnailsUpdates';
import useAccessRights from 'packages/hooks/useAccessRights';
import AuthorizationHelper from '~/helpers/AuthorizationHelper';
import AuthorizationAllowBlock from '~/common/AuthorizationBlock/AuthorizationAllowBlock';
import permissions from '~/configs/permissions';
import features from '~/configs/features';
import { fetchCreativesByOptions, handleDeselectAll, init, createCreative } from '~/store/creatives/creatives.actions';
import { initialState } from '~/store/creatives/creatives.reducer';
import useQueryParams from 'packages/hooks/useQueryParams';
import filtersStorage from 'packages/hooks/filtersStorage';
import { setPage, blankDesignQueried } from 'packages/store/newDesign/newDesign.actions';
import { getNewDesignPageHistory } from 'packages/store/newDesign/newDesign.selectors';
import { getTemplate } from '~/store/templates/templates.actions';
import { SkeletonContext } from 'packages/skeleton/SkeletonContext';
import roles from '~/configs/roles';
import { changePage } from '~/store/general/general.actions';
import Grid from '../components/grid';
import Header from '../components/header';
import Filters from '../components/filters';
import DesignCard from './components/DesignCard';
import { PAGE_SIZE } from './utils';
import useFilters from './useFilters';
import { CardBox } from './Designs.styled';
import useDesignRenameModal from './useDesignRenameModal';
import useDesignDeleteModal from './useDesignDeleteModal';
import useDesignMoveModal from './useDesignMoveModal';
import useFluidWidth from '../components/grid/useFluidWidth';
import useNewDesignModal from './useNewDesignModal';
import useDesignShareModal from './useDesignShareModal';
import ListViewToggle from '../components/list/toggle';
import ListView from '../components/list';
import { columnWidths, headers, renderItems } from './components/DesignListItem';
import { StyledPageBody } from '../Page.styled';
import renderHeaders from '../components/list/headers';
import { Props } from './types';
import { OwnershipFilterType } from '../components/filters/components/Ownership/types';
import { DesignSubPages } from '../designCreateModal/components/Sidebar/types';
import { MediaFilterType } from '../components/filters/components/MediaType/types';
import { removeEmptyProps, useScrollImproved } from '../utils';
import EmptyFilteredState, { isFilteredList } from '../components/empty/EmptyFilteredState';
import useDesignEditRestrictionModal from './useDesignEditRestrictionModal';
import { StatusFilterType } from '../components/filters/components/Status/types';
import { ButtonSkeleton, DesignCreateSkeletonButton, DropdownSkeleton } from './DesignsSkeleton';
import SkeletonGridView from './components/SkeletonGridView';
import SkeletonListView from './components/SkeletonListView';
import { useRedirectToNewDesign } from './useRedirectToNewDesign';

export const SELECTED_CORRUPTED_DESIGN = 'selectedCorruptedDesign';

const minimalColumnWidth = 224 + 4 * 2; // 224 width card + 4 * 2 padding for outline
const gutterSize = 24 - 4 * 2;

const checkAccessToPage = (checkArray: [boolean, { title: string; description: string }][]) => {
    const noAccess = checkArray.find((item) => !item[0]);

    if (noAccess) {
        return noAccess[1];
    }
};

const compareDesigns = (a: Creative, b: Creative) => a.id === b.id && a.name === b.name && a.status === b.status;

const Designs = ({ isSharedWithMe }: Props) => {
    const { translate } = useTranslate();
    const { skeletonShow } = useContext(SkeletonContext);
    const isFirstLoaded = useSelector(isCreativesFirstLoaded);
    const dispatch = useThunkDispatch();
    const { getQueryParam, getAllQueryParams, getAllQueryParamsRaw, restoreQueryParams, setQueryParam } =
        useQueryParams();
    const { getFiltersFromStorage, setFiltersToStorage } = filtersStorage();
    const { creatives: designs, loadingData, options, totalCount } = useSelector(getCreatives);
    const { selectedCreatives } = useSelector(getCreativesSelection);
    const pendingDesignThumbnails = useSelector(getPendingCreatives);
    const history = useSelector(getNewDesignPageHistory);

    const location = useLocation();

    const [listViewToggle, toggleListView] = useState(false);
    const [exportModalShow, setExportModalShow] = useState(false);
    const { isPlatformAllowed, isAccountRoleAssigned } = useAccessRights();

    const { setRenameModalDesign, renderModalDesignRename } = useDesignRenameModal();
    const { setDeleteModalDesign, renderModalDesignDelete } = useDesignDeleteModal();
    const { setMoveModalDesign, renderModalDesignMove } = useDesignMoveModal();
    const { setNewDesignModal, renderNewDesignModal } = useNewDesignModal();
    const { setShareModalDesign, renderModalDesignShare } = useDesignShareModal();
    const { setTextData, setRestrictionModalOpen, renderRestrictionModal } = useDesignEditRestrictionModal();

    useCreativeThumbnailsUpdates(pendingDesignThumbnails);

    const selectedCorruptedDesignId = getQueryParam(SELECTED_CORRUPTED_DESIGN);
    const isBatchActionDisabled =
        isAccountRoleAssigned(roles.video_brand_studio.handler.admin) &&
        getQueryParam('ownership')?.toUpperCase() === OwnershipFilterType.NOT_SHARED_WITH_ME;

    const redirectToNewDesign = useRedirectToNewDesign();

    useLayoutEffect(() => {
        dispatch(
            changePage({
                back: isSharedWithMe ? '/shared' : '/designs',
            }),
        );

        // create query params could be true, design, image and video
        const queryCreate = getQueryParam('create');

        if (isSharedWithMe) {
            const storedQueries = getFiltersFromStorage('shared');
            restoreQueryParams(storedQueries);
        } else if (!queryCreate) {
            const storedQueries = getFiltersFromStorage('designs');
            restoreQueryParams(storedQueries);
        }

        const querySearch = getQueryParam('search');
        const queriedMediaType = getQueryParam('typeDesign')?.toUpperCase();
        const queriedSorting = getQueryParam('sortedBy');
        const queriedOrder = getQueryParam('sortOrder');
        const queriedOwnership = getQueryParam('ownership')?.toUpperCase();
        const queriedApprovalStatus = getQueryParam('approvalStatus')?.toUpperCase();

        const queriedParams = removeEmptyProps({
            search: !queryCreate ? querySearch : '',
            creativeType: queriedMediaType?.toUpperCase() === MediaFilterType.ANY ? undefined : queriedMediaType,
            sort: queriedSorting && queriedOrder && [queriedSorting, queriedOrder],
            ownershipSource: queriedOwnership,
            approvalStatus:
                queriedApprovalStatus?.toUpperCase() === StatusFilterType.ANY_STATUS
                    ? undefined
                    : queriedApprovalStatus,
        });

        if (!skeletonShow) {
            dispatch(
                init(
                    isSharedWithMe
                        ? { ownershipSource: OwnershipFilterType.OWNED_BY_OTHERS, ...queriedParams }
                        : { ownershipSource: OwnershipFilterType.OWNED_BY_ME, ...queriedParams },
                ),
            );
        }

        const templateID = getQueryParam('template');

        if (queryCreate === 'true') {
            setNewDesignModal(true);
            const modalSubPage = getQueryParam('page')?.toUpperCase();

            if (modalSubPage && Object.keys(DesignSubPages).includes(modalSubPage) && !templateID) {
                dispatch(setPage(DesignSubPages[modalSubPage]));
            }

            if (
                templateID &&
                history[0] !== DesignSubPages.CATEGORIES &&
                history[0] !== DesignSubPages.CATEGORY_DETAILS
            ) {
                dispatch(getTemplate(templateID));
                dispatch(setPage(DesignSubPages.TEMPLATE_PREVIEW));
            }
        }

        const querySize = getQueryParam('size');
        const queryProjectID = getQueryParam('projectID') || '';

        if (
            !skeletonShow &&
            queryCreate &&
            querySize &&
            (queryCreate.toUpperCase() === 'IMAGE' || queryCreate.toUpperCase() === 'VIDEO')
        ) {
            redirectToNewDesign(dispatch(blankDesignQueried(queryCreate.toUpperCase(), querySize, queryProjectID)));
        }
    }, [skeletonShow]);

    // todo: fix or remove
    // It doesn't work at this moment,
    // query params are always null here
    useEffect(() => {
        const queryCreate = getQueryParam('create');
        const queryProjectID = getQueryParam('projectID') || '';
        const templateID = getQueryParam('template');

        if (queryCreate === 'design' && templateID) {
            if (!skeletonShow) {
                redirectToNewDesign(dispatch(createCreative({ id: templateID }, queryProjectID)));
            }
        }
    }, [skeletonShow]);

    const checkAccessError = (design: Creative) =>
        checkAccessToPage([
            [
                design.status !== CreativeStatus.DRAFT,
                {
                    title: translate('modal.design.not_published.title'),
                    description: translate('modal.design.not_published.description'),
                },
            ],
            [
                AuthorizationHelper.hasPermission(permissions.VIDEOS.CREATE),
                {
                    title: translate('modal.design.access_denied.title'),
                    description: translate('modal.design.access_denied.description'),
                },
            ],
            [
                !design.corruptionReason,
                {
                    title: translate('modal.design.missing_assets.title'),
                    description: translate('modal.design.missing_assets.description'),
                },
            ],
        ]);

    const openModal = useCallback(
        (errorTextData) => {
            setTextData(errorTextData);
            setRestrictionModalOpen(true);
        },
        [setTextData, setRestrictionModalOpen],
    );

    useEffect(() => {
        if (selectedCorruptedDesignId) {
            let id;

            try {
                id = parseInt(selectedCorruptedDesignId, 10);
            } catch (error) {
                console.error(error);
            }

            const design = designs.find((item) => item.id === id);

            if (design) {
                const error = checkAccessError(design);
                openModal(error);
                setQueryParam(SELECTED_CORRUPTED_DESIGN, null);
            }
        }
    }, [selectedCorruptedDesignId, designs]);

    useEffect(() => {
        if (
            !isSharedWithMe &&
            location.pathname.includes('designs') &&
            !location.pathname.includes('designs/') &&
            !location.search.includes('create=')
        ) {
            setFiltersToStorage('designs', getAllQueryParams());
        } else if (isSharedWithMe && location.pathname.includes('shared')) {
            setFiltersToStorage('shared', getAllQueryParams());
        }
    }, [getAllQueryParams()]);

    const handleExportClick = (design: Creative): boolean => {
        const errorTextData = checkAccessError(design);

        if (errorTextData) {
            openModal(errorTextData);
        }

        return !!errorTextData;
    };

    const handleCardClick: onDesignClick = (design) => (e) => {
        const errorTextData = checkAccessError(design);

        if (errorTextData) {
            e.preventDefault();
            openModal(errorTextData);
        }
    };

    const onScroll = () => {
        const notLoading = !loadingData;
        const notLastPage = designs.length < totalCount;

        if (notLoading && notLastPage) {
            const newOptions = { ...options };
            newOptions.page += 1;
            newOptions.size = PAGE_SIZE;
            dispatch(fetchCreativesByOptions(newOptions, false));
        }
    };

    const { onSearch, onMediaTypeSelect, onSort, onOwnershipSelect, onStatusSelect } = useFilters();
    const { gridContainer, getColumnWidth } = useFluidWidth();
    const columnWidth = gridContainer.current
        ? getColumnWidth(gridContainer?.current?.clientWidth - 24, minimalColumnWidth, gutterSize)
        : minimalColumnWidth;

    const renderEmpty = () => {
        const isFiltered = isFilteredList(getAllQueryParamsRaw());
        const emptyStateFirstLoad = isSharedWithMe ? (
            <EmptyState
                icon={<IconUserGroup />}
                title={translate('pages.sharedWithMe.empty.title')}
                text={translate('pages.sharedWithMe.empty.info')}
            />
        ) : (
            <EmptyState
                icon={<IconMasonry />}
                title={translate('pages.designs.empty.title')}
                text={translate('pages.designs.empty.info')}
                actions={
                    <AuthorizationAllowBlock permissions={[permissions.CREATIVES.WRITE]}>
                        <Button
                            variant="primary"
                            icon={<IconAdd />}
                            onClick={() => setNewDesignModal(true)}
                            title={translate('pages.designs.actionButton')}
                        >
                            {translate('pages.designs.actionButton')}
                        </Button>
                    </AuthorizationAllowBlock>
                }
            />
        );

        return isFiltered ? (
            <EmptyFilteredState
                storageKey={isSharedWithMe ? 'shared' : 'designs'}
                onResetFilters={() => {
                    if (isSharedWithMe) {
                        dispatch(
                            init({
                                ...options,
                                search: initialState.options.search,
                                ownershipSource: OwnershipFilterType.OWNED_BY_OTHERS,
                                creativeType: undefined,
                                approvalStatus: undefined,
                            }),
                        );
                    } else {
                        dispatch(
                            init({
                                ...options,
                                search: initialState.options.search,
                                creativeType: undefined,
                                approvalStatus: undefined,
                            }),
                        );
                    }
                }}
            />
        ) : (
            emptyStateFirstLoad
        );
    };

    const renderList = () => {
        if (gridContainer.current && gridContainer.current.clientWidth) {
            if (skeletonShow || isFirstLoaded) {
                return <SkeletonListView columnWidths={columnWidths} />;
            }

            return (
                <ListView
                    columnWidths={columnWidths}
                    renderHeader={renderHeaders(headers, loadingData, options.sort, onSort, designs)}
                    renderItem={renderItems(designs, options.sort, {
                        setMoveModalDesign,
                        setRenameModalDesign,
                        setDeleteModalDesign,
                        setExportModalShow,
                        setShareModalDesign,
                        handleExportClick,
                    })}
                    isLoading={loadingData}
                    container={gridContainer}
                    onScroll={onScroll}
                />
            );
        }

        return null;
    };

    const renderGrid = () => {
        if (gridContainer.current && gridContainer.current.clientWidth) {
            if (skeletonShow || isFirstLoaded) {
                return <SkeletonGridView />;
            }

            return (
                <Grid<Creative>
                    items={designs}
                    compareItems={compareDesigns}
                    renderItem={(design: Creative, defaultHeight) => (
                        <CardBox defaultHeight={defaultHeight}>
                            <DesignCard
                                {...design}
                                onClick={handleCardClick}
                                menuActions={{
                                    setMoveModalDesign,
                                    setRenameModalDesign,
                                    setDeleteModalDesign,
                                    setExportModalShow,
                                    setShareModalDesign,
                                    handleExportClick,
                                }}
                            />
                        </CardBox>
                    )}
                    isLoading={loadingData}
                    onScroll={onScroll}
                    // 20px padding (StyledPageBody), only left one, the right one already covered
                    columnWidth={columnWidth}
                    gutterSize={gutterSize}
                />
            );
        }

        return null;
    };

    useScrollImproved(designs, gridContainer);

    return (
        <div id="scroll-wrapper">
            <Header>
                {!selectedCreatives.length && (
                    <>
                        <Header.LeftBlock>
                            {skeletonShow ? (
                                <SkeletonText />
                            ) : (
                                <Header.Title
                                    id={
                                        isSharedWithMe
                                            ? translate('pages.sharedWithMe.title')
                                            : translate('pages.designs.title')
                                    }
                                    data-testid="design overview title"
                                >
                                    {isSharedWithMe
                                        ? translate('pages.sharedWithMe.title')
                                        : translate('pages.designs.title')}
                                </Header.Title>
                            )}
                        </Header.LeftBlock>
                        <Header.RightBlock>
                            {skeletonShow ? (
                                <DesignCreateSkeletonButton />
                            ) : (
                                <AuthorizationAllowBlock permissions={[permissions.CREATIVES.WRITE]}>
                                    <Button
                                        icon={<IconAdd />}
                                        variant="primary"
                                        onClick={() => setNewDesignModal(true)}
                                        title={translate('pages.designs.actionButton')}
                                    >
                                        {translate('pages.designs.actionButton')}
                                    </Button>
                                </AuthorizationAllowBlock>
                            )}
                        </Header.RightBlock>
                    </>
                )}
                {selectedCreatives.length > 0 && (
                    <>
                        <Header.LeftBlock>
                            <Header.Title id="design_overview_title" data-testid="design overview title">
                                {translate('pages.designs.title.selection', { count: selectedCreatives.length })}
                            </Header.Title>
                            <Button
                                data-testid="design overview deselect"
                                variant="clean"
                                aria-label="Deselect all"
                                onClick={() => {
                                    dispatch(handleDeselectAll());
                                }}
                            >
                                {translate('pages.designs.deselect')}
                            </Button>
                        </Header.LeftBlock>
                        <Header.RightBlock>
                            <Button
                                data-testid="design overview bulk move"
                                variant="clean"
                                icon={<IconFileMove />}
                                aria-label="Move"
                                onClick={() => setMoveModalDesign(selectedCreatives)}
                                isDisabled={isBatchActionDisabled}
                            />
                            <Button
                                data-testid="design overview bulk delete"
                                variant="clean"
                                icon={<IconDelete />}
                                aria-label="Delete"
                                onClick={() => setDeleteModalDesign(selectedCreatives)}
                                isDisabled={isBatchActionDisabled}
                            />
                        </Header.RightBlock>
                    </>
                )}
            </Header>
            <Filters>
                <Filters.FiltersLeftBlock>
                    <Filters.Search onSearch={onSearch} isDisabled={skeletonShow} />
                    {skeletonShow ? (
                        <DropdownSkeleton />
                    ) : (
                        <>
                            {(isPlatformAllowed([features.APPROVALS_ENABLED]) ||
                                (AuthorizationHelper.isFeatureAvailable(features.IMAGES_ENABLED) &&
                                    AuthorizationHelper.isFeatureAvailable(features.VIDEOS_ENABLED))) && (
                                <Filters.FiltersDivider />
                            )}
                            {!isSharedWithMe && (
                                <Filters.Ownership
                                    sortBy={options.ownershipSource}
                                    onSort={onOwnershipSelect}
                                    isDesigns
                                />
                            )}
                            {isPlatformAllowed([features.APPROVALS_ENABLED]) && (
                                <Filters.Status value={options.approvalStatus} onSelect={onStatusSelect} />
                            )}
                            {AuthorizationHelper.isFeatureAvailable(features.IMAGES_ENABLED) &&
                                AuthorizationHelper.isFeatureAvailable(features.VIDEOS_ENABLED) && (
                                    <Filters.MediaType value={options.creativeType} onSelect={onMediaTypeSelect} />
                                )}
                        </>
                    )}
                </Filters.FiltersLeftBlock>
                <Filters.FiltersRightBlock>
                    {skeletonShow ? (
                        <>
                            <DropdownSkeleton />
                            <ButtonSkeleton />
                            <ButtonSkeleton />
                        </>
                    ) : (
                        <>
                            {!listViewToggle && (
                                <Filters.Ordering
                                    onSort={onSort}
                                    isDisabled={!designs.length && !loadingData}
                                    isDesignType
                                />
                            )}
                            <ListViewToggle
                                isActive={listViewToggle}
                                setListViewActive={toggleListView}
                                isDisabled={!designs.length && !loadingData}
                            />
                        </>
                    )}
                </Filters.FiltersRightBlock>
            </Filters>
            <StyledPageBody
                id="design_overview_body"
                ref={gridContainer}
                data-testid="designs overview body"
                isListView={listViewToggle}
            >
                {!designs.length && !loadingData && !skeletonShow ? (
                    renderEmpty()
                ) : (
                    <>{listViewToggle ? renderList() : renderGrid()}</>
                )}
            </StyledPageBody>
            {renderModalDesignMove()}
            {renderModalDesignRename()}
            {renderModalDesignDelete()}
            {renderNewDesignModal()}
            {renderModalDesignShare()}
            {renderRestrictionModal()}
        </div>
    );
};

export default Designs;
