import { CreativeTypes } from '../Enums/CreativeTypes';
import { PreviewTypes } from '../Enums/PreviewTypes';
import type { IBaseModel } from '../Models/BaseModel/IBaseModel';

import { BaseVisualElement } from '../Models/Elements/BaseVisualElement';
import type { IElement } from '../Models/Elements/IElement';
import type { IVideoModel } from '../Models/VideoModel/IVideoModel';
import { DynamicEventEmitter } from './DynamicEventEmitter';
import { getAllElementsRecursively } from './elementUtils';
import { equals } from './utils';
import { validate } from './validate';
import { ElementRemovedEventData, ElementUpdatedEventData } from '../event-types';
import { TextElement } from '../Models/Elements/TextElement';
import { GroupElement } from '../Models/Elements/GroupElement';
import type { IAsset } from '../Models/Assets/IAsset';

type ValidationMessages = Record<string, any>;

export type ValidationState = {
    isValid: boolean;
    isChildrenValid: boolean;
    validationMessages: ValidationMessages;
};

declare const CREATIVE_MAX_DURATION_SECONDS: number | undefined;
declare const VIDEO_MAX_DIMENSIONS: number | undefined;
declare const IMAGE_MAX_DIMENSIONS: number | undefined;

export class ValidationManager extends DynamicEventEmitter {
    private validationState: Map<number | string, ValidationState> = new Map();
    private globalPropertiesValidationState: ValidationState = {
        isValid: false,
        validationMessages: {},
        isChildrenValid: false,
    };
    private creativeModel: IBaseModel;
    private creativeType: CreativeTypes;
    private previewType: PreviewTypes;
    private _isValid: boolean = true;
    private elementChangeListener = ({ element }: ElementUpdatedEventData) => this.validateElement(element.id);
    private elementRemoveListener = ({ element }: ElementRemovedEventData) => this.removeElement(element);
    private backgroundColorUpdateListener = () => this.validateGlobalProperties();
    private posterFrameUpdateListener = () => this.validateGlobalProperties();
    private globalAudioUpdateListener = () => this.validateGlobalProperties();
    private durationUpdateListener = () => this.validate();
    private dimensionUpdateListener = () => this.validateGlobalProperties();
    private invalidateCompModelListener = () => this.validate();
    private assetLoadListener = ({ asset, type }: { asset: IAsset; type: string }) => {
        if (type !== 'font') {
            return;
        }
        this.creativeModel.getAllElementsRecursively().forEach((element) => {
            if (element.isContainsAsset(asset)) {
                this.validateElement(element.id);
            }
        });
    };

    constructor(
        creativeModel: IBaseModel,
        creativeType: CreativeTypes = CreativeTypes.VIDEO,
        previewType: PreviewTypes,
    ) {
        super();
        this.creativeModel = creativeModel;
        this.creativeType = creativeType;
        this.previewType = previewType;
        this.subscribe();
    }

    validate(): void {
        this.creativeModel.getElements().forEach((element) => {
            this.validateElement(element.id);
        });
        if (this.previewType === PreviewTypes.EDITOR) {
            this.validateGlobalProperties();
        }
    }

    private subscribe() {
        this.creativeModel.eventEmitter.on('requireCreativeUpdate', this.invalidateCompModelListener);
        this.creativeModel.eventEmitter.on('requireFramesUpdate', this.invalidateCompModelListener);
        this.creativeModel.eventEmitter.on('elementUpdated', this.elementChangeListener);
        this.creativeModel.eventEmitter.on('elementRemoved', this.elementRemoveListener);
        this.creativeModel.eventEmitter.on('backgroundColorUpdated', this.backgroundColorUpdateListener);
        this.creativeModel.eventEmitter.on('posterFrameUpdated', this.posterFrameUpdateListener);
        this.creativeModel.eventEmitter.on('globalAudioUpdated', this.globalAudioUpdateListener);
        this.creativeModel.eventEmitter.on('durationUpdated', this.durationUpdateListener);
        this.creativeModel.eventEmitter.on('dimensionUpdated', this.dimensionUpdateListener);
        this.creativeModel.getAssetLoader().eventEmitter.on('asset.load', this.assetLoadListener);
    }

    unsubscribe() {
        this.creativeModel.eventEmitter.off('requireCreativeUpdate', this.invalidateCompModelListener);
        this.creativeModel.eventEmitter.off('requireFramesUpdate', this.invalidateCompModelListener);
        this.creativeModel.eventEmitter.off('elementUpdated', this.elementChangeListener);
        this.creativeModel.eventEmitter.off('elementRemoved', this.elementRemoveListener);
        this.creativeModel.eventEmitter.off('backgroundColorUpdated', this.backgroundColorUpdateListener);
        this.creativeModel.eventEmitter.off('posterFrameUpdated', this.posterFrameUpdateListener);
        this.creativeModel.eventEmitter.off('globalAudioUpdated', this.globalAudioUpdateListener);
        this.creativeModel.eventEmitter.off('durationUpdated', this.durationUpdateListener);
        this.creativeModel.eventEmitter.off('dimensionUpdated', this.dimensionUpdateListener);
        this.creativeModel.getAssetLoader().eventEmitter.off('asset.load', this.assetLoadListener);
    }

    private static getPropertyValue(obj: any, propertyName: string): any {
        if (obj instanceof BaseVisualElement) {
            if (propertyName === 'width') {
                return obj.dimension.getWidth();
            }

            if (propertyName === 'height') {
                return obj.dimension.getHeight();
            }

            if (propertyName === 'horizontalPosition') {
                return obj.position.getX();
            }

            if (propertyName === 'verticalPosition') {
                return obj.position.getY();
            }
            if (propertyName === 'text') {
                return (obj as TextElement).getTextProps().value;
            }
            if (propertyName === 'limitTextToBoundsHeight') {
                return (obj as TextElement).boundedText.getShapedText().getContentHeight();
            }
            if (propertyName === 'limitTextToBoundsWidth') {
                return (obj as TextElement).boundedText.getShapedText().getContentWidth();
            }
        }

        return obj[propertyName];
    }

    private removeElement(element: IElement): void {
        getAllElementsRecursively([element as any]).forEach((element) => {
            this.validationState.delete(element.id);
        });
        this.checkValidations();
    }

    private getFrameRate() {
        if (this.creativeType === CreativeTypes.VIDEO) {
            return (this.creativeModel as IVideoModel).getPlaybackDuration().getFrameRate();
        }

        return 1;
    }

    private validateObjectByRules(
        obj: any,
        rules: { [name: string]: any },
        validationMessages: ValidationMessages,
    ): boolean {
        const frameRate = this.getFrameRate();
        return Object.keys(rules).reduce((acc: boolean, propertyName: string) => {
            const rule = rules[propertyName];
            const value = ValidationManager.getPropertyValue(obj, propertyName);

            if (value === undefined) {
                return acc;
            }

            const { isValid, messages } = validate(value, rule, frameRate);

            if (!isValid) {
                validationMessages[propertyName] = messages;
            }

            return acc && isValid;
        }, true);
    }

    //NOTE: Which validation messages should be?
    private validateElementProperties(element: IElement, validationMessages: ValidationMessages): boolean {
        const rules = element.getValidationRules(this.creativeType, this.previewType);
        return this.validateObjectByRules(element, rules, validationMessages);
    }

    private validateElementAnimations(element: IElement, validationMessages: ValidationMessages): boolean {
        const animations = element.animations || [];

        if (!animations.length) {
            return true;
        }

        const frameRate = this.getFrameRate();
        return animations.reduce((acc: boolean, animation, index) => {
            const animationValidationMessages = {};
            const rules = animation.getValidationRules(frameRate);
            const isValid = this.validateObjectByRules(animation.config, rules, animationValidationMessages);

            if (!isValid) {
                if (!validationMessages.animations) {
                    validationMessages.animations = [];
                }

                validationMessages.animations.push({
                    validationMessages: animationValidationMessages,
                    index,
                });
            }

            return acc && isValid;
        }, true);
    }

    private validateElementAnimationIn(element: IElement, validationMessages: ValidationMessages): boolean {
        if (!element.animationIn) {
            return true;
        }

        const messages = {};
        const frameRate = this.getFrameRate();
        const rules = element.animationIn.getValidationRules(frameRate);
        const isValid = this.validateObjectByRules(element.animationIn.config, rules, messages);

        if (!isValid) {
            validationMessages.animationIn = messages;
        }

        return isValid;
    }

    private validateElementAnimationOut(element: IElement, validationMessages: ValidationMessages): boolean {
        if (!element.animationOut) {
            return true;
        }

        const messages = {};
        const frameRate = this.getFrameRate();
        const rules = element.animationOut.getValidationRules(frameRate);
        const isValid = this.validateObjectByRules(element.animationOut.config, rules, messages);

        if (!isValid) {
            validationMessages.animationOut = messages;
        }

        return isValid;
    }

    private getGlobalPropertiesRules(): any {
        // prettier-ignore
        const maxDimensions = this.creativeType === CreativeTypes.VIDEO ?
            VIDEO_MAX_DIMENSIONS ? VIDEO_MAX_DIMENSIONS : 4096 :
            IMAGE_MAX_DIMENSIONS ? IMAGE_MAX_DIMENSIONS : 4096;
        const isVideoCreative = this.creativeType === CreativeTypes.VIDEO;
        const rules: Record<string, Record<string, any>> = {
            width: {
                LESS_THAN: maxDimensions,
                GREATER_THAN: 10,
                EVEN: isVideoCreative,
            },
            height: {
                LESS_THAN: maxDimensions,
                GREATER_THAN: 10,
                EVEN: isVideoCreative,
            },
        };

        if (isVideoCreative) {
            const duration = (this.creativeModel as IVideoModel).getPlaybackDuration().getDuration();
            const frameRate = (this.creativeModel as IVideoModel).getPlaybackDuration().getFrameRate();
            const maxDuration = CREATIVE_MAX_DURATION_SECONDS ? CREATIVE_MAX_DURATION_SECONDS : 3 * 60;
            rules.posterFrame = {
                LESS_THAN: duration - 1,
                GREATER_THAN: 0,
                FRAMES_TO_TIME: true,
            };
            rules.duration = {
                LESS_THAN: maxDuration * frameRate,
                GREATER_THAN: 1,
                FRAMES_TO_TIME: true,
            };
        }

        return rules;
    }

    private validateGlobalProperties(): void {
        const validationMessages = {};
        const dimension = this.creativeModel.getDimensions();
        const data: Record<string, any> = {
            backgroundColor: this.creativeModel.getBackgroundColor().color,
            width: dimension.getWidth(),
            height: dimension.getHeight(),
        };

        if (this.creativeType === CreativeTypes.VIDEO) {
            data.duration = (this.creativeModel as IVideoModel).getPlaybackDuration().getDuration();
            data.posterFrame = (this.creativeModel as IVideoModel).getPosterFrame().frame;
            data.globalAudio = (this.creativeModel as IVideoModel).getGlobalAudioTrack1().toObject();
        }

        const rules = this.getGlobalPropertiesRules();
        const isValid = this.validateObjectByRules(data, rules, validationMessages);
        this.globalPropertiesValidationState = {
            isValid,
            validationMessages,
            isChildrenValid: false,
        };
        this.emit('globalPropertiesValidated', {
            isValid,
            validationMessages,
        });
        this.checkValidations();
    }

    private checkElementChildren(element: IElement): boolean {
        if (!(element instanceof GroupElement) || element.children.length < 0) {
            return true;
        }

        return element.children.reduce((acc: boolean, element) => {
            const { isValid, isChildrenValid } = this.getElementValidationState(element.id);
            return acc && isValid && isChildrenValid;
        }, true);
    }

    private checkElementParent(element: IElement): void {
        if (!element.parent) {
            return;
        }

        const elementId = element.parent.id;
        const state = this.getElementValidationState(elementId);
        const isChildrenValid = this.checkElementChildren(element.parent);

        if (state && state.isChildrenValid !== isChildrenValid) {
            const { isValid, validationMessages } = state;
            this.validationState.set(elementId, {
                isValid,
                isChildrenValid,
                validationMessages,
            });
            this.emit('elementValidated', {
                elementId,
                element: element.parent,
                isValid,
                isChildrenValid,
                validationMessages,
            });
            this.checkElementParent(element.parent);
        }
    }

    private checkValidations(): void {
        let isElementsValid = true;
        this.validationState.forEach((value) => {
            isElementsValid = isElementsValid && value.isValid;
        });
        const isGlobalPropertiesValid = this.globalPropertiesValidationState.isValid;
        const isValid = isElementsValid && (this.previewType === PreviewTypes.CONTENT || isGlobalPropertiesValid);

        if (this._isValid !== isValid) {
            this._isValid = isValid;
            this.emit(`validated`, {
                isValid: this._isValid,
            });
        }
    }

    validateDimension(width: number, height: number): [boolean, Record<string, string[]>] {
        const rules = this.getGlobalPropertiesRules();
        const validationMessages = {};
        const data = {
            width,
            height,
        };
        const isValid = this.validateObjectByRules(data, rules, validationMessages);
        return [isValid, validationMessages];
    }

    isValid(): boolean {
        return this._isValid;
    }

    getElementValidationState(elementId: number | string): ValidationState | undefined {
        if (!this.validationState.has(elementId)) {
            this.validateElement(elementId);
        }

        return this.validationState.get(elementId);
    }

    getGlobalPropertiesValidationState(): ValidationState {
        if (!this.globalPropertiesValidationState) {
            this.validateGlobalProperties();
        }

        return this.globalPropertiesValidationState;
    }

    validateElement(elementId: number | string): void {
        const element = this.creativeModel.getElementById(elementId);

        if (!element) {
            return;
        }

        const validationMessages = {};
        const validations = [];
        validations.push(this.validateElementProperties(element, validationMessages));

        if (this.creativeType === CreativeTypes.VIDEO && this.previewType === PreviewTypes.EDITOR) {
            const isAnimationsValid = this.validateElementAnimations(element, validationMessages);
            const isAnimationInValid = this.validateElementAnimationIn(element, validationMessages);
            const isAnimationOutValid = this.validateElementAnimationOut(element, validationMessages);
            validations.push(isAnimationsValid, isAnimationInValid, isAnimationOutValid);
        }

        const isValid = validations.every(Boolean);
        const isChildrenValid = this.checkElementChildren(element);
        const oldState = this.validationState.get(elementId);
        this.validationState.set(elementId, {
            isValid,
            isChildrenValid,
            validationMessages,
        });

        if (
            !oldState ||
            oldState.isValid !== isValid ||
            oldState.isChildrenValid !== isChildrenValid ||
            !equals(oldState.validationMessages, validationMessages)
        ) {
            this.emit('elementValidated', {
                elementId,
                element,
                isValid,
                isChildrenValid,
                validationMessages,
            });
            this.checkElementParent(element);
            this.checkValidations();
        }
    }

    onElementValidationChange(elementId: number, callbackFn: (data: ElementUpdatedEventData) => void): () => void {
        const listener = (data: ElementUpdatedEventData) => {
            if (data.element.id === elementId) {
                callbackFn(data);
            }
        };

        this.on('elementValidated', listener);
        return () => this.off('elementValidated', listener);
    }

    onGlobalPropertiesValidationChange(listener: () => void): () => void {
        const eventName = 'globalPropertiesValidated';
        this.on(eventName, listener);
        return () => this.off(eventName, listener);
    }

    onValidationChange(listener: () => void): () => void {
        const eventName = 'validated';
        this.on(eventName, listener);
        return () => this.off(eventName, listener);
    }
}
