import React, { useEffect } from 'react';
import { GroupElement } from '@bynder-studio/render-core';
import { isCtrlKey } from '~/helpers/hotKeys';
import useEditor from '~/hooks/useEditor';

type Props = {
    id: number;
    callback: () => void;
};

export default function useElementRename({ id, callback }: Props) {
    const { manipulationRenderer } = useEditor();

    useEffect(() => {
        const handleKeyDown = (event: React.MouseEvent) => {
            if (!manipulationRenderer) {
                return;
            }

            if (isCtrlKey(event) && event.shiftKey && event.code === 'KeyR') {
                event.preventDefault();

                const selectedElements = manipulationRenderer.getSelectedElements();
                const selectionContainsGroup = selectedElements.some((el) => el instanceof GroupElement);

                if (
                    selectedElements.length === 0 ||
                    (selectedElements.length > 1 && !selectionContainsGroup) ||
                    selectedElements[0].id !== id
                ) {
                    return;
                }

                callback();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [manipulationRenderer]);
}
