import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DndContext, DragOverlay, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { ElementUpdateTypes } from '@bynder-studio/render-core';
import { Divider } from '@bynder/design-system';
import { checkMaskConflict } from 'packages/pages/editor/RightSideMenu/Shots/Masking/utils';
import { useShortcutsBlocker } from 'packages/hooks/useShortcutsBlocker';
import LayerHeader from '~/common/editor/layers/layerHeader/LayerHeader';
import LayerItem from '~/common/editor/layers/layerItem/LayerItem';
import { collectTopLevelGroups } from '~/common/editor/timeline/timeline-actions/components/utils';
import useEditor from '~/hooks/useEditor';
import { useArrangeShortcuts } from 'packages/hooks/useArrangeShortcuts';
import { useToggleExpand } from '~/common/editor/helpers/groups';
import useForceUpdate from '~/hooks/useForceUpdate';
import { Draggable } from './layerItem/Draggable';
import { Droppable } from './layerItem/Droppable';
import LayersEmpty from './LayersEmpty';
import { LayersContainer } from './LayerTree.styled';

const getNestedLevel = (element, level = 0) => (element.parent ? getNestedLevel(element.parent, level + 1) : level);
const isParent = (element, id) => (element.parent ? element.parent.id === id || isParent(element.parent, id) : false);

const checkIsElementAllParentsExpanded = (element, expandedGroupIds) => {
    if (element.parent) {
        if (expandedGroupIds.includes(element.parent.id)) {
            return checkIsElementAllParentsExpanded(element.parent, expandedGroupIds);
        }

        return false;
    }

    return true;
};

const allowAddPlaceholder = (element, nextElement, draggingId, elementLevel, nextElementLevel) => {
    const nextId = nextElement?.id;

    if (element.id === draggingId) {
        return false;
    }

    if (isParent(element, draggingId)) {
        return false;
    }

    if (nextElementLevel > elementLevel && nextId === draggingId) {
        return false;
    }

    if (elementLevel === nextElementLevel && nextId === draggingId) {
        return false;
    }

    if (checkMaskConflict(element, draggingId)) {
        return false;
    }

    return true;
};

const dropAnimation = {
    duration: 250,
    easing: 'ease',
};

const LayerTree = () => {
    const { creativeModel, manipulationRenderer } = useEditor();
    const forceUpdate = useForceUpdate();
    const data = useRef({ elements: [], dropTimer: null, draggingElementId: null });
    const { elements, draggingElementId } = data.current;
    const [expandedGroupIds, toggleGroupExpand] = useToggleExpand([]);
    const draggingElement = useMemo(
        () => elements.find((el) => el.id === draggingElementId),
        [elements, draggingElementId],
    );
    const currentPageIndex = creativeModel.getCurrentPageIndex();
    const selectedElements = manipulationRenderer?.getSelectedElements();

    const [selectedGroups, setSelectedGroups] = useState(() =>
        collectTopLevelGroups(manipulationRenderer?.getSelectedElements() || []),
    );

    const handleDragCancel = useCallback(() => {
        data.current.draggingElementId = null;
        forceUpdate();
    }, []);

    const { toggleShortcutsBlocker } = useShortcutsBlocker(handleDragCancel);

    const filterCollapsedElement = useCallback(
        (element) => checkIsElementAllParentsExpanded(element, expandedGroupIds),
        [expandedGroupIds],
    );

    const elementsUpdateListener = useCallback(() => {
        data.current.elements = creativeModel.getAllElementsRecursively();

        forceUpdate();
    }, [creativeModel, forceUpdate, currentPageIndex]);

    const handleDragStart = useCallback(
        (event) => {
            toggleShortcutsBlocker(true);
            data.current.draggingElementId = event.active.id;
            forceUpdate();
        },
        [toggleShortcutsBlocker, forceUpdate],
    );

    const handleDragEnd = useCallback(
        ({ over }) => {
            toggleShortcutsBlocker(false);

            const { draggingElementId: elementId, elements } = data.current;
            const element = elements.find((el) => el.id === elementId);

            if (over && elementId) {
                const { type, id, parentId, renderOrder } = over.data.current;

                if (type === 'group') {
                    creativeModel.beginAccumulation();
                    creativeModel.updateElement(elementId, { renderOrder });
                    creativeModel.addExistingElementToGroup(elementId, id);
                    creativeModel.endAccumulation();
                    manipulationRenderer.selectElement(elementId);
                } else if (type === 'element') {
                    creativeModel.beginAccumulation();
                    creativeModel.updateElement(elementId, { renderOrder });
                    creativeModel.updateElement(id, { renderOrder: renderOrder - 1 });
                    const newGroupEl = creativeModel.wrapElementsIntoGroup([id, elementId]);
                    creativeModel.endAccumulation();
                    manipulationRenderer.selectElement(newGroupEl.id);
                } else {
                    const deprecatedGroupSelected = element.children?.length
                        ? element.isContainsElement(parentId)
                        : false;

                    if (!deprecatedGroupSelected && parentId !== elementId) {
                        creativeModel.beginAccumulation();

                        if (element.parent && element.parent.id !== parentId) {
                            creativeModel.unwrapElementFromGroup(elementId);
                        }

                        creativeModel.updateElement(elementId, { renderOrder });

                        if (parentId && (!element.parent || element.parent.id !== parentId)) {
                            creativeModel.addExistingElementToGroup(elementId, parentId);
                        }

                        creativeModel.endAccumulation();
                        manipulationRenderer.selectElement(elementId);
                    }
                }
            }

            if (data.current.dropTimer) {
                clearTimeout(data.current.dropTimer);
            }
            data.current.dropTimer = setTimeout(() => {
                data.current.draggingElementId = null;
                data.current.dropTimer = null;
                forceUpdate();
            }, dropAnimation.duration);

            forceUpdate();
        },
        [forceUpdate, creativeModel, manipulationRenderer, toggleShortcutsBlocker],
    );

    const mouseSensor = useSensor(MouseSensor, {
        // Press delay of 200ms, with tolerance of 5px of movement
        activationConstraint: {
            // delay: 100,
            // tolerance: 5,
            distance: 5,
        },
    });
    const touchSensor = useSensor(TouchSensor, {
        // Press delay of 250ms, with tolerance of 5px of movement
        activationConstraint: {
            delay: 250,
            tolerance: 5,
        },
    });
    const sensors = useSensors(mouseSensor, touchSensor);

    useArrangeShortcuts({
        creativeModel,
        elements,
        selectedElements,
    });

    useEffect(() => {
        // Get all elements on initial load
        data.current.elements = creativeModel.getAllElementsRecursively();
    }, []);

    useEffect(() => {
        if (!creativeModel || !manipulationRenderer) {
            return;
        }

        const listener = () => {
            setSelectedGroups(collectTopLevelGroups(manipulationRenderer.getSelectedElements()));
            forceUpdate();
        };

        const unsubscribeContentProperty = creativeModel.onElementsPartialUpdate(
            ElementUpdateTypes.CONTENT_PROPERTY,
            elementsUpdateListener,
        );
        creativeModel.on('elementsTreeUpdated', elementsUpdateListener);
        creativeModel.on('currentPageChange', elementsUpdateListener);

        manipulationRenderer.eventEmitter.on('elementSelected', listener);

        return () => {
            unsubscribeContentProperty();
            creativeModel.off('elementsTreeUpdated', elementsUpdateListener);
            creativeModel.off('currentPageChange', elementsUpdateListener);
            manipulationRenderer.eventEmitter.off('elementSelected', listener);
        };
    }, [creativeModel, elementsUpdateListener, manipulationRenderer, currentPageIndex]);

    const treeElements = useMemo(() => {
        const list = [];
        const addPrevLevelDraggable = (nextLevel, level, el) => {
            if (nextLevel >= level) {
                return;
            }

            if (checkMaskConflict(el, draggingElementId)) {
                return;
            }

            const { parent } = el;
            const renderOrder = parent.renderOrder - 0.5;

            list.push(
                <Droppable
                    level={level - 1}
                    key={`${el.id}-${renderOrder}`}
                    renderOrder={renderOrder}
                    parentId={parent.parent?.id || null}
                />,
            );
            parent && addPrevLevelDraggable(nextLevel, level - 1, parent);
        };
        const filteredElements = elements.filter(filterCollapsedElement);
        filteredElements.forEach((element, index) => {
            if (draggingElementId && !index && draggingElementId !== element.id) {
                const renderOrder = element.renderOrder + 0.5;
                list.push(<Droppable key={renderOrder} renderOrder={renderOrder} />);
            }

            const level = getNestedLevel(element);
            list.push(
                <Draggable
                    key={element.id}
                    level={level}
                    element={element}
                    expandedGroupIds={expandedGroupIds}
                    toggleGroupExpand={toggleGroupExpand}
                    draggingElementId={draggingElementId}
                />,
            );

            const nextElement = filteredElements[index + 1];
            const nextLevel = nextElement ? getNestedLevel(nextElement) : level;

            if (draggingElementId && allowAddPlaceholder(element, nextElement, draggingElementId, level, nextLevel)) {
                const l = nextLevel > level ? nextLevel : level;
                const el = nextLevel > level ? nextElement : element;
                const renderOrder = element.renderOrder - 0.5;

                list.push(
                    <Droppable
                        level={l}
                        key={renderOrder}
                        renderOrder={renderOrder}
                        parentId={el.parent?.id || null}
                    />,
                );
            }

            if (draggingElementId) {
                addPrevLevelDraggable(nextLevel, level, element);
            }
        });

        if (draggingElementId) {
            list.push(<Droppable key="latest" renderOrder={0.5} parentId={null} fillHeight />);
        }

        return list;
    }, [filterCollapsedElement, toggleGroupExpand, expandedGroupIds, draggingElementId, elements]);

    return (
        <LayersContainer>
            <LayerHeader selectedGroups={selectedGroups} setSelectedGroups={setSelectedGroups} />
            <Divider />
            <DndContext
                sensors={sensors}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                onDragCancel={handleDragCancel}
            >
                <div className="layers--wrapper">
                    {!elements.length && <LayersEmpty />}
                    {treeElements}
                    <DragOverlay dropAnimation={dropAnimation}>
                        {draggingElementId && draggingElement && (
                            <LayerItem
                                element={draggingElement}
                                childrenLength={(draggingElement.children || []).length}
                                onGroupExpand={toggleGroupExpand}
                                level={getNestedLevel(draggingElement)}
                                isGroupExpand={!!~expandedGroupIds.indexOf(draggingElementId)}
                                isDragOverlay
                            />
                        )}
                    </DragOverlay>
                </div>
            </DndContext>
        </LayersContainer>
    );
};

export default LayerTree;
