import React, { useCallback, useEffect, useState, memo } from 'react';
import { useSelector } from 'react-redux';
import { getUnixTime } from 'date-fns';
import { Button, Inspector, Modal } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { IconReplay } from '@bynder/icons';
import { type ImageModel, type VideoModel } from '@bynder-studio/render-core';
import useVariations from 'packages/pages/design/hooks/useVariations';
import useForceUpdate from '~/hooks/useForceUpdate';
import { StatusFilterType } from 'packages/pages/components/filters/components/Status/types';
import { getReviewState } from 'packages/store/review/review.selectors';
import { getDiffBetweenTemplateAndVariation } from './utils';
import useDesign from '../../hooks/useDesign';
import modalContainer from 'packages/common/modalContainer';

const ResetPage = () => {
    const { creativeModel, template, recalculateShots } = useDesign();
    const reviewState = useSelector(getReviewState);
    const { saveVariation, variations, currentVariation, addNewUnsavedValue, updateThumbnailRenderModel } =
        useVariations();
    const { translate } = useTranslate();
    const forceUpdate = useForceUpdate();
    const [isOpen, setOpen] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const open = useCallback(() => {
        setOpen(true);
    }, []);

    const close = useCallback(() => {
        setOpen(false);
        setIsSaving(false);
    }, []);

    const { hasChanges } = getDiffBetweenTemplateAndVariation(template, creativeModel);

    const handleResetPageData = useCallback(() => {
        if (!creativeModel) {
            return;
        }

        setIsSaving(true);

        const { globalProperties, pages } = getDiffBetweenTemplateAndVariation(template, creativeModel);
        const variation = variations.find((v) => v.id === currentVariation);
        const currentPageIndex = creativeModel.getCurrentPageIndex();

        const allModelsMetadata = creativeModel.getModelsMetaData();
        const allModels = creativeModel.getModels() as (ImageModel | VideoModel)[];
        const model = allModels[currentPageIndex];

        const page = pages.find((page) => page.index === currentPageIndex);
        const { pageId } = page;

        model.beginAccumulation();

        Object.entries(globalProperties).forEach(([key, value]) => {
            const { id, ...newValues } = value as any;

            const sizeElement = variation.sizes[pageId].elements[id];
            variation.sizes[pageId].elements[id] = {
                ...sizeElement,
                ...newValues,
            };
            addNewUnsavedValue({
                elementId: id,
                variation,
                pageId,
                newValues,
            });

            model.updateGlobalProperty(key, newValues);
            updateThumbnailRenderModel(key, newValues);
        });

        const elements = page?.elements || [];

        if (elements.length) {
            model.updateElements(elements);

            elements.forEach((elem) => {
                const { id, ...newValues } = elem;
                const { contentPropertyId } = model.getElementById(id);

                if (contentPropertyId) {
                    allModels.forEach((currentModel, idx) => {
                        const affectedElements = currentModel
                            .getAllElementsRecursively()
                            .filter((el) => el.contentPropertyId === contentPropertyId);
                        const modelData = allModelsMetadata[idx];

                        affectedElements.forEach((element) => {
                            const sizeElement = variation.sizes[modelData.id].elements[element.id];
                            variation.sizes[modelData.id].elements[element.id] = {
                                ...sizeElement,
                                ...newValues,
                            };
                            addNewUnsavedValue({
                                pageId: modelData.id,
                                elementId: element.id,
                                variation,
                                newValues,
                            });
                        });
                    });
                } else {
                    const sizeElement = variation.sizes[pageId].elements[id];
                    variation.sizes[pageId].elements[id] = {
                        ...sizeElement,
                        ...newValues,
                    };
                    addNewUnsavedValue({
                        elementId: id,
                        variation,
                        pageId,
                        newValues,
                    });
                }

                updateThumbnailRenderModel(id, newValues);
            });
        }

        model.endAccumulation();

        if (creativeModel.getAllElementsRecursively().find((el) => el.useDynamicLength)) {
            recalculateShots();
        }

        variation.hasUnsavedChanges = true;
        variation.hasUnsavedChangesTimestamp = getUnixTime(new Date());

        saveVariation(currentVariation, close, [pageId]);
    }, [template, creativeModel, recalculateShots, saveVariation, currentVariation, variations, close]);

    useEffect(() => {
        const update = () => {
            if (!isSaving) {
                forceUpdate();
            }
        };

        creativeModel.eventEmitter.on('elementUpdated', update);
        creativeModel.eventEmitter.on('globalAudioUpdated', update);
        creativeModel.eventEmitter.on('posterFrameUpdated', update);
        creativeModel.eventEmitter.on('backgroundColorUpdated', update);

        return () => {
            creativeModel.eventEmitter.off('elementUpdated', update);
            creativeModel.eventEmitter.off('globalAudioUpdated', update);
            creativeModel.eventEmitter.off('posterFrameUpdated', update);
            creativeModel.eventEmitter.off('backgroundColorUpdated', update);
        };
    }, [creativeModel, forceUpdate, isSaving]);

    const isDisabled =
        template.approvalEnabled &&
        [StatusFilterType.APPROVED, StatusFilterType.IN_REVIEW].includes(
            reviewState.status || template.approvalState?.status,
        );

    return (
        <>
            {hasChanges && (
                <Inspector.Footer>
                    <Button
                        variant="secondary"
                        onClick={open}
                        icon={<IconReplay />}
                        isFullWidth
                        isDisabled={isDisabled}
                    >
                        {translate('design.variations.resetPage')}
                    </Button>
                </Inspector.Footer>
            )}
            <Modal
                container={modalContainer}
                title={translate('design.variations.resetPage')}
                isOpen={isOpen}
                onClose={close}
                actionPrimary={
                    <Button variant="primary" onClick={handleResetPageData} isLoading={isSaving}>
                        {translate('design.variations.resetPage')}
                    </Button>
                }
                actionSecondary={
                    <Button variant="secondary" onClick={close} isDisabled={isSaving}>
                        {translate('modal.cancel.title')}
                    </Button>
                }
            >
                {translate('design.variations.resetPage.description')}
            </Modal>
        </>
    );
};

export default memo(ResetPage);
