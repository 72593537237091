import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useElementRename from 'packages/hooks/useElementRename';
import MaybeTooltip from 'packages/common/MaybeTooltip';
import Inline from './Inline';

// Width of the icon with margin
const LEFT_MARGIN = 45;

type Props = {
    id: number;
    elementWidth: number;
    name: string;
    duration: number;
    onNameChange: (name: string) => void;
    icon: JSX.Element;
    isGroup: boolean;
    childrenLength: number;
    onAnimationExpand: () => void;
    hasAnimations: boolean;
    isHidden: boolean;
    isParentHidden: boolean;
    elementNameNodeRef: React.RefObject<HTMLDivElement>;
    scrollContainerRef: React.RefObject<HTMLDivElement>;
    isLocked: boolean;
};

const ElementName = (props: Props) => {
    const { id, name, elementWidth, isGroup, childrenLength } = props;
    const [hasTooltip, setHasTooltip] = useState(false);
    const [editMode, setEditMode] = useState(false);
    useElementRename({ id, callback: () => setEditMode(true) });

    const setTooltipState = useCallback(() => {
        const text = props.elementNameNodeRef.current;

        if (!text) {
            return false;
        }

        setHasTooltip(text.getBoundingClientRect().width + LEFT_MARGIN >= elementWidth);
    }, [elementWidth]);

    const displayName = useMemo(
        () =>
            isGroup ? (
                <span>
                    <strong>{name}: </strong> {childrenLength} items
                </span>
            ) : (
                name
            ),
        [name, childrenLength, isGroup],
    );

    useEffect(() => {
        setTooltipState();
    }, [elementWidth]);

    return (
        <MaybeTooltip hasTooltip={hasTooltip} content={displayName}>
            <Inline
                {...props}
                displayName={displayName}
                editable={editMode}
                setEditable={setEditMode}
                onMountCb={setTooltipState}
            />
        </MaybeTooltip>
    );
};

export default ElementName;
