import type { IAssetsLoader } from '../../AssetLoader/IAssetsLoader';
import { ElementUpdateTypes } from '../../Enums/ElementUpdateTypes';
import { AudioAsset } from '../Assets/AudioAsset';
import type { IAsset } from '../Assets/IAsset';
import type { BaseElementParams } from './BaseElement';
import { BaseElement } from './BaseElement';
import { ElementUpdateOptions } from './IElement';

type AudioElementUpdateOptions = ElementUpdateOptions & {
    skipAssetDestroy?: boolean;
};

export type AudioElementParams = BaseElementParams & {
    startFrame: number;
    duration?: number;
    src?: string;
    srcId?: string;
    srcType?: string;
    fileName?: string;
    offsetTime?: number;
    gain: number;
    fadeIn: number;
    fadeOut: number;
};

export class AudioElement extends BaseElement {
    isAssetLoading: boolean | undefined;

    startFrame = 0;

    duration = 1;

    src: string | null | undefined = null;

    srcId: string | null | undefined = null;

    srcType: string | null | undefined = null;

    offsetTime = 0;

    fileName: string | null = null;

    assetLoader!: IAssetsLoader;

    gain = 0;

    fadeIn = 0;

    fadeOut = 0;

    constructor(params: AudioElementParams) {
        super();
        this.setProperties(params);
    }

    update(params: AudioElementParams, options?: AudioElementUpdateOptions): Set<ElementUpdateTypes> {
        const { frameRate = 25, skipAssetDestroy = false } = options || {};
        const oldAssetId = this.getAssetId();
        const oldSrc = this.src;
        const oldOffsetTime = this.offsetTime;
        const oldDuration = this.duration;
        const oldStartFrame = this.startFrame;
        const updateTypes: Set<ElementUpdateTypes> = this.setProperties(params);

        if (
            oldSrc !== this.src ||
            this.offsetTime !== oldOffsetTime ||
            this.duration !== oldDuration ||
            this.startFrame !== oldStartFrame
        ) {
            if (oldAssetId !== this.getAssetId() && !skipAssetDestroy) {
                this.assetLoader.destroyAsset(oldAssetId);
            }

            this.constructAsset(frameRate);
        }

        return updateTypes;
    }

    setProperties(params: Partial<AudioElementParams>): Set<ElementUpdateTypes> {
        const updateTypes: Set<ElementUpdateTypes> = super.setProperties(params);

        if (params.startFrame !== undefined) {
            this.startFrame = params.startFrame;
            updateTypes.add(ElementUpdateTypes.TIMEFRAME);
        }

        if (params.duration !== undefined) {
            this.duration = params.duration;
            updateTypes.add(ElementUpdateTypes.TIMEFRAME);
        }

        if (params.src !== undefined) {
            this.src = params.src;
            updateTypes.add(ElementUpdateTypes.SOURCE);
        }

        if (params.srcId !== undefined) {
            this.srcId = params.srcId;
            updateTypes.add(ElementUpdateTypes.SOURCE);
        }

        if (params.srcType !== undefined) {
            this.srcType = params.srcType;
            updateTypes.add(ElementUpdateTypes.SOURCE);
        }

        if (params.offsetTime !== undefined) {
            this.offsetTime = params.offsetTime;
            updateTypes.add(ElementUpdateTypes.SOURCE);
        }

        if (params.fileName !== undefined) {
            this.fileName = params.fileName;
            updateTypes.add(ElementUpdateTypes.SOURCE);
        }

        if (params.gain !== undefined) {
            this.gain = params.gain;
            updateTypes.add(ElementUpdateTypes.AUDIO_GAIN);
        }

        if (params.fadeIn !== undefined) {
            this.fadeIn = params.fadeIn;
            updateTypes.add(ElementUpdateTypes.AUDIO_FADE_IN);
        }

        if (params.fadeOut !== undefined) {
            this.fadeOut = params.fadeOut;
            updateTypes.add(ElementUpdateTypes.AUDIO_FADE_OUT);
        }

        return updateTypes;
    }

    setAssetLoader(loader: IAssetsLoader): void {
        this.assetLoader = loader;
    }

    getAssetId(): string {
        return `${this.srcId}-${this.startFrame}-${this.offsetTime}-${this.duration}`;
    }

    constructAsset(frameRate: number): void {
        if (!this.src) {
            return;
        }

        const asset = new AudioAsset({
            id: this.getAssetId(),
            src: this.src,
            startFrame: this.startFrame,
            offsetTime: this.offsetTime,
            duration: this.duration,
            frameRate,
        });
        this.isAssetLoading = true;
        this.assetLoader.setAsset(asset);
        this.assetLoader
            .loadAudio(asset)
            .catch((err) => {
                console.warn('Audio asset loading error. Url:', this.src, err);
            })
            .finally(() => {
                this.isAssetLoading = false;
            });
    }

    isContainsAsset(asset: IAsset): boolean {
        return asset instanceof AudioAsset && asset.id === this.srcId && asset.src === this.src;
    }

    getValuesByUpcomingUpdate(data: Partial<AudioElementParams>): Partial<AudioElementParams> {
        return super.getValuesByUpcomingUpdate(data);
    }

    getGainValue(): number {
        return Math.pow(10, this.gain / 20);
    }

    getFadeScale(time: number, duration: number): number {
        if (time >= duration) {
            return 0;
        }

        const fadeInScale = time >= this.fadeIn ? 1 : time / this.fadeIn;
        const fadeOutScale = time <= duration - this.fadeOut ? 1 : (duration - time) / this.fadeOut;

        return fadeInScale * fadeOutScale;
    }

    toObject() {
        const baseObject = super.toObject();

        return {
            ...baseObject,
            startFrame: this.startFrame,
            duration: this.duration,
            src: this.src,
            srcId: this.srcId,
            srcType: this.srcType,
            offsetTime: this.offsetTime,
            fileName: this.fileName,
            gain: this.gain,
            fadeIn: this.fadeIn,
            fadeOut: this.fadeOut,
        };
    }
}
