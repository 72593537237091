import React from 'react';
import { useNavigate } from 'react-router-dom';
import useAccessRights from 'packages/hooks/useAccessRights';
import roles from '~/configs/roles';

// redirects to the correct place after design creation
export const useRedirectToNewDesign = () => {
    const navigate = useNavigate();
    const { isAccountRoleAssigned } = useAccessRights();

    const redirectToNewDesign = async (path: Promise<string | void>) => {
        const currentPath = location.pathname;

        const newDesignPath = await path;

        if (!newDesignPath) {
            navigate(currentPath, { replace: true });

            return;
        }

        const redirectSuffix = isAccountRoleAssigned(roles.video_brand_studio.video.manage) ? '' : 'edit';
        navigate(`${newDesignPath}${redirectSuffix}`);
    };

    return redirectToNewDesign;
};
