import React, { type FC } from 'react';
import { Flex, Form } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { FadeInput } from './FadeInput';
import { GainSlider } from './GainSlider';
import { InputWrapper, SliderWrapper } from './index.styled';
import { AudioSliderInput } from './AudioSliderInput';

const MIN_GAIN_VOLUME = -60;
const MAX_GAIN_VOLUME = 20;

type AudioFeatureProps = {
    isDisabled?: boolean;
    gain: number;
    fadeIn: number;
    fadeOut: number;
    onFadeInChange: (fadeIn: number) => void;
    onFadeOutChange: (fadeOut: number) => void;
    onGainChange: (gain: number) => void;
};

export const AudioFeature: FC<AudioFeatureProps> = ({
    isDisabled = false,
    gain,
    fadeIn,
    fadeOut,
    onFadeInChange,
    onFadeOutChange,
    onGainChange,
}) => {
    const { translate } = useTranslate();

    return (
        <>
            <Form.Group>
                <Form.Label id="audio-volume-label" htmlFor="audio-volume">
                    {translate('editor.sidebar.audio.volume.label')}
                </Form.Label>

                <Flex alignItems="center" justifyContent="space-between">
                    <SliderWrapper>
                        <GainSlider
                            min={MIN_GAIN_VOLUME}
                            max={MAX_GAIN_VOLUME}
                            gain={gain}
                            onGainChange={onGainChange}
                            isDisabled={isDisabled}
                        />
                    </SliderWrapper>
                    <InputWrapper>
                        <AudioSliderInput
                            id="audio-volume-input"
                            value={gain}
                            min={MIN_GAIN_VOLUME}
                            max={MAX_GAIN_VOLUME}
                            isDisabled={isDisabled}
                            onChange={onGainChange}
                        />
                    </InputWrapper>
                </Flex>
            </Form.Group>
            <Form.Row>
                <Form.Group>
                    <Form.Label htmlFor="audio-fade-in">{translate('editor.sidebar.audio.fade.in.label')}</Form.Label>
                    <FadeInput id="audio-fade-in" value={fadeIn} isDisabled={isDisabled} onChange={onFadeInChange} />
                </Form.Group>
                <Form.Group>
                    <Form.Label htmlFor="audio-fade-out">{translate('editor.sidebar.audio.fade.out.label')}</Form.Label>
                    <FadeInput id="audio-fade-out" value={fadeOut} isDisabled={isDisabled} onChange={onFadeOutChange} />
                </Form.Group>
            </Form.Row>
        </>
    );
};
